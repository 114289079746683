import styled from 'styled-components';

const StyleBase = styled.div``

const StyleContainer =  styled(StyleBase)<{isMobile?: boolean }>`
    padding: 14px;
    button {
        border-radius: ${({isMobile}) => isMobile ?  '24px' : '16px' };
    }
`
const GYTN =  styled(StyleBase)<{isMobile?: boolean }>`
    margin-top: ${({isMobile}) => isMobile ?  '100px' : '25px' };
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .title {
        font-size: ${({isMobile}) => !isMobile ?  '40px' : '20px' };
        font-family: Nirmala UI-Bold;
        font-weight: 400;
        color: #FFFFFF;
    }
    .timer-box {
        margin-top: 28px;
        display: flex;
    }
    .timer {
        font-size: ${({isMobile}) => !isMobile ?  '45px' : '20px' };
        font-family: Nirmala UI-Bold;
        font-weight: 400;
        line-height: 40px;
        color: #3772ff;
    }
    .nd {
        margin-left: 12px;
        line-height: ${({isMobile}) => !isMobile ?  '68px' : '46px' };
        font-size: ${({isMobile}) => !isMobile ?  '20px' : '20px' };
        font-weight: 400;
        color: #FFFFFF;
    }
`
// eslint-disable-next-line import/prefer-default-export
export { StyleContainer, GYTN }