import { async } from 'q';
/* eslint-disable object-shorthand */
import { InjectedConnector } from '@web3-react/injected-connector'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
import { BscConnector } from '@binance-chain/bsc-connector'
import { Web3Provider } from '@ethersproject/providers'
import Web3 from 'web3'
import { ethers } from 'ethers'
import { Provider } from 'ethers-multicall'
import getNodeUrl from './getRpcUrl'

enum ConnectorNames {
  Injected = "injected",
  WalletConnect = "walletconnect",
  BSC = "bsc"
}

const POLLING_INTERVAL = 12000
const rpcUrl = getNodeUrl()
const chainId = parseInt(process && process.env.REACT_APP_CHAIN_ID, 10)


const injected = new InjectedConnector({ supportedChainIds: [chainId] })

// const injected = new InjectedConnector({  })

const walletconnect = new WalletConnectConnector({
  rpc: { [chainId]: rpcUrl },
  bridge: 'https://bridge.walletconnect.org/',
  qrcode: true,
  // pollingInterval: POLLING_INTERVAL,
})

const bscConnector = new BscConnector({ supportedChainIds: [chainId] })
// const bscConnector = new BscConnector({  })

export const connectorsByName: { [connectorName in ConnectorNames]: any } = {
  [ConnectorNames.Injected]: injected,
  [ConnectorNames.WalletConnect]: walletconnect,
  [ConnectorNames.BSC]: bscConnector,
}

export function getLibrary(provider: any) {
  const library = new Web3Provider(provider)
  library.pollingInterval = 15000
  return library
}

// const web3_ = new Web3(new Web3('https://bsc-dataseed.binance.org')); // BSC主网
// const web3_ = new Web3(new Web3('https://data-seed-prebsc-2-s2.binance.org:8545/')); // BSC测试网

export const getLibraryB = (provider) => {
  const newProvider = new ethers.providers.JsonRpcProvider(rpcUrl);
  const library = new Web3Provider(provider)
  library.pollingInterval = 15000
  const ethcallProvider = new Provider(newProvider);
  
  return {
    Web3: provider,
    Web3Provider: library,
    ethcallProvider: ethcallProvider
  }
}
