import styled from 'styled-components';

const StyleBase = styled.div``

const StyledWrapContainer = styled(StyleBase)<{isMobile?: boolean }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: ${({isMobile}) => isMobile ?  '0' : '100px' };
`

const StyledCardContainer = styled(StyleBase)<{isMobile?: boolean }>`
    width: ${({isMobile}) => isMobile ?  '100%' : '580px' };
    margin: 30px 0 50px;
    border-radius: ${({isMobile}) => isMobile ?  '0.8rem' : '25px' };
    background: #232323;
`
const StyledBox = styled.div`
`
const StyledCardHeader = styled(StyleBase)<{isMobile?: boolean }>`
    padding: ${({isMobile}) => isMobile ?  '0.8rem 0.5rem' : '20px 40px' };
    height: ${({isMobile}) => isMobile ?  '4.6rem ' : '86px' };
    font-size: ${({isMobile}) => isMobile ?  '1.2rem ' : '22px' };
    color: white;
    text-align: center;
    border-bottom: 1px solid #707070;
    .date{
        width: 100%;
        margin-top: 10px;
        font-size: 16px;
        text-align: left;
    }
    .rounds-header{
        display: flex;
        justify-content: space-between;
        width: 100%;
        .rounds{
            color: white;
            .title{
                display:flex;
                align-items: center;
                font-size: ${({isMobile}) => isMobile ?  '1.2rem' : '22px' };
                font-weight: bold;
                font-family: Nirmala UI-Bold,Nirmala UI;
                input {
                    display: inline-block;
                    width: 50px;
                    height: 20px;
                    margin-left: 12px;
                    background: #555;
                    border-radius: 22px;
                    text-align: center;
                    font-size: 12px;
                    line-height: 20px;
                    outline: none;
                    font-weight: bold;
                    color: white;
                    border: 0px;
                }
                .label{
                    display: inline-block;
                    width: 38px;
                    height: 20px;
                    margin-left: 12px;
                    background: #232323;
                    border-radius: 22px;
                    text-align: center;
                    font-size: 12px;
                    line-height: 20px;
                }
            }
            
        }
        .narrow{
            img{
                cursor: pointer;
            }
            img:nth-of-type(1) {
                transform: rotate(180deg)
            }
            img:nth-of-type(2) {
                margin: 0 12px;
                transform: rotate(180deg)
            }
            img:nth-of-type(4) {
                margin-left:12px;
            }
        }
    }
    .your-history-go-back{
        display: flex;
        align-items: center;
        .back-arrow{
            height: 12px;
            width: 14px;
            transform: rotate(180deg);
        }
        .rounds{
            text-align: left;
            margin-left: 6%;
        }
    }
`
const StyledNodata = styled(StyleBase)<{isMobile?: boolean }>`
    display: flex;
    flex-direction: ${({isMobile}) => isMobile ?  'column' : 'column' };
    width: ${({isMobile}) => isMobile ?  '100%' : '580px' };
    height: 120px;
    color: white;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    span{
        margin-bottom: 8px;
        margin-right: 12px;
    }
`

const StyleButton = styled(StyleBase)<{isMobile?: boolean }>`
    button {
        border-radius: ${({isMobile}) => isMobile ?  '24px' : '16px' };
    }
`

export { StyledWrapContainer, StyledCardContainer, StyledCardHeader, StyledNodata, StyledBox, StyleButton }