/* eslint-disable import/no-named-as-default */
/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { ButtonMenu, ButtonMenuItem, useMatchBreakpoints, Flex } from '@dreamweb/uikit'
import AllHistory from './AllHistory/AllHistory';
import YourHistory from './YourHistory/YourHistory';
import { StyledWrapContainer, StyleButton } from './HistoryPanelStyle'
 
 
const LotteryHistory  = (props: any) => {
    const { t } = useTranslation()
    const [activeIndex, setActiveIndex] = useState(0);
    const { isSm, isXs, isLg, isMd,isXl } = useMatchBreakpoints();
    const isMobile = isXs || isSm || isLg || isMd;
    const handleClick = (index: number) => {
        setActiveIndex(index)
    }
    return (
        <StyledWrapContainer isMobile={isMobile} style={{paddingTop: 0}}>
            <Flex style={{width: "100%", paddingTop: 0}} justifyContent={isMobile ? 'center' : 'center'}>
                <StyleButton>
                    <ButtonMenu style={{ width: '100%', backgroundColor: '#232323', borderRadius: '9px' }} activeIndex={activeIndex} onItemClick={handleClick} scale="sm" >
                        <ButtonMenuItem  style={{ backgroundColor: activeIndex !== 1 ? '#fff': '', borderRadius: '8px', color: activeIndex !== 1 ? '#000': '#8A8A8A'}}>{t('All History')}</ButtonMenuItem>
                        <ButtonMenuItem  style={{ backgroundColor: activeIndex === 1 ? '#fff': '', borderRadius: '8px', color: activeIndex === 1 ? '#000': '#8A8A8A'}}>{t('Your History')}</ButtonMenuItem>
                    </ButtonMenu>
                </StyleButton>
            </Flex>
            {activeIndex === 0 ? <AllHistory update={props.update} chainid={props.chainid}/> : <YourHistory setUpdate={props?.setUpdate} update={props.update}/>}
        </StyledWrapContainer>
    )
}

export default React.memo(LotteryHistory);