import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { ButtonMenu, ButtonMenuItem } from '@dreamweb/uikit'

const StyledNav = styled.div`
  margin-bottom: 40px;
`

function Nav({ activeIndex = 0 }: { activeIndex?: number }) {
  return (
    <StyledNav style={{ maxWidth: '400px', width: '100%' }}>
      <ButtonMenu
        activeIndex={activeIndex}
        scale="sm"
        variant="tabButton"
        style={{ width: '100%', backgroundColor: '#232323', borderRadius: '13px' }}
      >
        <ButtonMenuItem
          id="swap-nav-link"
          to="/swap"
          as={Link}
          style={{ fontSize: '24px', padding: '30px', backgroundColor: activeIndex === 0 ? '#fff': '', borderRadius: '12px', width: '50%', color: activeIndex === 0 ? '#000': '' }}
        >
          Swap
        </ButtonMenuItem>
        <ButtonMenuItem
          id="Liquidity-nav-link"
          to="/Liquidity"
          as={Link}
          style={{ fontSize: '24px', padding: '30px', backgroundColor: activeIndex === 1 ? '#fff': '', borderRadius: '12px', width: '50%', color: activeIndex === 1 ? '#000': ''}}
        >
          Liquidity
        </ButtonMenuItem>
      </ButtonMenu>
    </StyledNav>
  )
}

export default Nav
