/* eslint-disable import/no-cycle */
/* eslint-disable import/order */
/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect, useRef } from 'react'
import { useMatchBreakpoints, useModal, Button, Flex } from '@dreamweb/uikit'
import moment from 'moment';
import { useWeb3React } from '@web3-react/core'
import Login from 'components/Login'
import EventBus from 'utils/eventBus'
import { useTranslation } from "react-i18next";
import { Modal } from 'antd'
import { checkMyLotteryHistory, checkTotalPlayers, getViewLottery } from 'utils/lotteryUtils'
import { formatDecimal } from 'utils/math';
import { groupBy } from 'lodash';
import { handleRewardsData } from '../../utils/index'
import BuyTicketsModal from '../BuyTickets/BuyTicketsModal'
import LotteryDetailMoal from '../LotteryDetailModal/LotteryDetailMoal';
import LotteryDetailNextDraw from '../LotteryDetailModal/LotteryDetailNextDraw';
import { StyledWinNumsStyle, StylePrizePot, StylePrizeContainer } from '../LotteryHistory/AllHistory/AllHistoryStyle';
import { StyledCardContainer, StyledCardHeader, StyledTitle, StyledBigNum, StyledSum, StyledMore, StyledTickets, StyledPrize, StyledNodata, StyledPrizePotContainer } from '../NextPostDrawCardStyle'
import LotteryDetail from '../LottertDetail/LotteryDetail'
import { usePriceDreamBusd } from 'state/hooks'
import { useLottery } from 'hooks/useContract'
import { NoBscProviderError } from '@binance-chain/bsc-connector';
import { createLanguageService } from 'typescript';
import { nanoid } from 'nanoid';

type propsType = {
    latestedDraw: boolean,
    nextOrPost: boolean,
    lotteryInfo: any,
    lotteryIndex: string | number,
    currentLotteryId?: string | number,
    refeshPageData: any,
    oneStartTimer: any
}
const NextPostDrawCard = (props: propsType) => {
    const { nextOrPost, lotteryInfo, lotteryIndex, latestedDraw, currentLotteryId, refeshPageData, oneStartTimer } = props;
    const lotteryContract = useLottery();
    const { t } = useTranslation();
    const amountDcoin = useRef(0);
    const amountKyuryo = useRef(0);
    const burnDcoin = useRef(0);
    const burnKyuryo = useRef(0);
    const timer = useRef(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [startTimeOfLottery, setStartTimeOfLottery] = useState(0);
    const [winningNumbers, setWinningNumbers] = useState([]);
    const [rewards, setRewards] = useState([]);
    const [tickets, setTickets] = useState(0);
    const [isStart, setIsStart] = useState(false);
    const currentAccount = useRef('');
    const [nodata, setNodata] = useState(false);
    const [flopFlag, setFlipFlag] = useState(false);
    const [totalPlayers, setTotalPlayers] = useState(0);
    const timerStart = useRef(null)
    const { account } = useWeb3React();
    const { isSm, isXs, isLg, isMd, isXl } = useMatchBreakpoints();
    const isMobile = isXs || isSm || isLg || isMd;
    const [openBuy, setOpenBuy] = useState(false)
    const DreamPriceUsd = usePriceDreamBusd();
    // 判断是否为第一期
    const PhaseI = (+currentLotteryId === 1 ||  +currentLotteryId === 0)

    const formatIndex = (num: number | string): string => {
        if(num === 0) {
            return <div style={{background: '#8593AE', width: '60px', display: 'inline-block',marginBottom:'2px'}}> &nbsp;</div> as any
        }
        if (+num < 10) {
            return `#00${num}`;
        }
        if (+num < 100 && +num > 9) {
            return `#0${num}`;
        }
        return `#${num}`;
    }

    const [openModal] = useModal(<BuyTicketsModal  onDismiss />)
    const [openLotteryDetailModal,setOpenLotteryDetailModal]=useState(false)
    const [openLotteryDetailNextDrawModal,setOpenLotteryDetailNextDrawModal]=useState(false)
    // const [openLotteryDetailModal] = useModal(<LotteryDetailMoal 
    //     round={lotteryIndex} 
    //     winningNumbers={winningNumbers} 
    //     totalTickets={tickets} 
    //     onDismiss/>)

    // const [openLotteryDetailNextDrawModal] = useModal(<LotteryDetailNextDraw 
    //     round={lotteryIndex} 
    //     winningNumbers={winningNumbers} 
    //     totalTickets={tickets} 
    //     onDismiss
    // />)

    const [isDcoinKyuruoPrice, setIsDcoinKyuruoPrice] = useState(0)

    const getDcoinKyuryoPrice = async () => { 
        const info = await getViewLottery(lotteryContract);
        const { priceTicketInDcoin, priceTicketInKyuryo } = info;
        setIsDcoinKyuruoPrice(Number(localStorage.getItem('isDcoinKyuruo')==='0'?priceTicketInDcoin:priceTicketInKyuryo));
     }
    
    const createValidateRes = (res) => {
        const times = res[1]
        const arr=  times.map((item, index) => {
            return {
                lotteryId: res[0][index],
                round: index+1,
                date:  moment(Number(res[1][index]) * 1000).format('LL'),
                tickets:  res[2][index],
                detail: ''
            }
        });
        return arr;
    }

    const setEachValue = (info) => {
        const { rewardsBreakdown, countWinnersPerBracket, finalNumber, startTime, endTime, treasuryFee, amountCollectedInDcoin, amountCollectedInKyuryo } = info;
        if (endTime) {
            amountDcoin.current = Number(amountCollectedInDcoin) / 1e18;
            amountKyuryo.current = Number(amountCollectedInKyuryo) / 1e18;
            burnDcoin.current = amountCollectedInDcoin * treasuryFee / 10000 / 1e18;
            burnKyuryo.current = amountCollectedInKyuryo * treasuryFee / 10000 / 1e18;
            setStartTimeOfLottery(Number(endTime) * 1000);
            setWinningNumbers(finalNumber.substring(1).split(''));
            // console.log(100, finalNumber.substring(1).split(''))
            const rewardsData = handleRewardsData(rewardsBreakdown, countWinnersPerBracket, amountDcoin.current - burnDcoin.current, amountKyuryo.current - burnKyuryo.current);
            if (rewardsData?.length) {
                setNodata(false)
                setRewards(rewardsData)
            } else {
                setNodata(true)
            }
        } else {
            setNodata(true)
        }
    }

    const getTotalPlayers = async () => {
        const players = await checkTotalPlayers(lotteryContract, lotteryIndex);
        setTotalPlayers(players)
    }

    const getCurrentLotteryTickets = async () => {
        let result = await checkMyLotteryHistory(lotteryContract, account);
        result = createValidateRes(result);
        const groups = groupBy(result, 'lotteryId');
        const keys = Object.keys(groups);
        const arr = [];
        for(let i=0; i< keys?.length; i++ ) {
            let total = 0;
            groups[keys[i]].forEach((item) => {
                total += Number(item.tickets)
            })
            arr.push({
                lotteryId: keys[i],
                tickets: total
            })
        }
        const targetItem = arr.find(item => Number(item.lotteryId) === Number(lotteryIndex));
        if (targetItem) {
            setTickets(targetItem.tickets)
        } else {
            setTickets(0)
        }
    }
    
    useEffect(() => {
        if ( (!account || (account !== currentAccount.current))) {
            if (account) {
                getCurrentLotteryTickets();
            }
            getTotalPlayers();
            setEachValue(lotteryInfo);
            currentAccount.current = account;
        }
        getDcoinKyuryoPrice()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [account])

    useEffect(() => {
        if (account) {
            getCurrentLotteryTickets();
        }
        getTotalPlayers();
        setEachValue(lotteryInfo);
    }, [lotteryInfo, lotteryIndex, account, nextOrPost]);

    useEffect(() => {
        EventBus.on('reFetchDataWhenBuyTicketsSuccessfully', (res) => {
            if (account) {
                getCurrentLotteryTickets();
            }
        })
        return () => {
            EventBus.off('reFetchDataWhenBuyTicketsSuccessfully')
        }
    })

    useEffect(() => { 
        clearInterval(timer.current)
        timer.current = setInterval(() => { 
            if(lotteryInfo?.endTime < new Date().getTime() / 1000){
                refeshPageData()
            }
        }, 6000)
        return () => { 
            clearInterval(timer.current)
         }
    },[lotteryInfo, lotteryIndex, account, nextOrPost])

    useEffect(() => { 
        if(oneStartTimer * 1000 > new Date().valueOf()) {
            setIsStart(false)
            timerStart.current = setInterval(() => {
                if(oneStartTimer * 1000 <= new Date().valueOf()) {
                    setIsStart(true)
                }
            }, 2000); 
        } else {
            setIsStart(true)
        }
        return () => { 
            clearInterval(timerStart.current)
         }
    }, [oneStartTimer])

    
    const openM = () => { 
        setOpenBuy(true)
    }

    const onDismiss = () => { 
        setOpenBuy(false)
     }

    return (
        <>
            <StyledCardContainer isMobile={isMobile} style={{paddingBottom: (latestedDraw && !nextOrPost) ? '30px' : 0}}>
                <StyledCardHeader isMobile={isMobile}>
                    <h2 style={{ fontSize: isMobile ? '1rem' : "24px" }}> {nextOrPost ? 'PAST DRAW' : 'NEXT DRAW'}</h2>
                    {
                        nextOrPost && !(latestedDraw || PhaseI) && <div>
                            <div className='draw-time'> {latestedDraw || PhaseI ? '' : formatIndex(lotteryIndex)} | Drawn : {latestedDraw  || PhaseI  ? '' :  startTimeOfLottery ? moment(!isStart ? oneStartTimer * 1000 : startTimeOfLottery).format('MMM D, YYYY, h:mm A') : <div style={{background: '#8593AE', width: '130px', display: 'inline-block'}}> &nbsp;</div>} </div>
                        </div>
                    }
                    {
                        (!nextOrPost) && <div style={{width: isMobile ? '100%' : ''}}>
                            <div className='draw-time'> {latestedDraw ? '' : formatIndex(lotteryIndex)} | Draw : {latestedDraw ? '' : startTimeOfLottery ? moment(!isStart ? oneStartTimer * 1000 : startTimeOfLottery).format('MMM D, YYYY, h:mm A') : <div style={{background: '#8593AE', width: '130px', display: 'inline-block'}}> &nbsp;</div>} </div>
                        </div>
                    }
                </StyledCardHeader>
                <div>
                    {
                        <StyledPrize isMobile={isMobile}>
                            {
                                nextOrPost ? ( PhaseI || nodata || latestedDraw) ? <StyledNodata>{t("No Data")}</StyledNodata> : 
                                <div>
                                    <section style={{color:'#F8E513', textAlign:'center', fontSize:'22px', margin: '20px 0'}}>
                                        Winning Number
                                    </section>
                                    <StyledWinNumsStyle isMobile={isMobile} style={{paddingBottom: '0', marginBottom: '30px'}}>
                                        <div className='numbers'>
                                            {
                                                winningNumbers.map(item=>{
                                                    return <span key={Math.random()} className='square'>{item}</span>
                                                })
                                            }
                                        </div>
                                    </StyledWinNumsStyle>
                                    <section>
                                        {
                                            Boolean(account && tickets) &&
                                            <StyledTickets isMobile={isMobile}>
                                                <div className='flex your-tickets'>
                                                    <div className='title' style={{marginTop: '20px'}}>{t("Your Tickets")}</div>
                                                    <div className='button'>
                                                        <span className='desc' >You have {tickets} {(tickets === 1 || tickets === 0) ? 'ticket' : 'tickets'} this round</span>
                                                        <div  style={{color: '#3232F8'}} className='view-ticket' onClick={() => setOpenLotteryDetailModal(true)}>view your tickets</div>
                                                    </div>
                                                </div>
                                            </StyledTickets> 
                                        }
                                    </section>
                               
                                    <StylePrizeContainer>
                                        <StylePrizePot isMobile={isMobile}>
                                            <section className='left'>
                                                <div className='title'>{t("Prize pot")}</div>
                                                <div className='prize'>{formatDecimal(amountDcoin.current, 4)} DCOIN</div>
                                                <div className='prize'>{formatDecimal(amountKyuryo.current, 4)} KYURYO</div>
                                            </section>
                                            <section className='right'>
                                                <div className='info' style={{textAlign: "left"}}>{t("Match the winning number in the same order to share prizes.")}</div>
                                                <div className='info'>{t("Total players this round: ")} {totalPlayers}</div>
                                            </section>
                                        </StylePrizePot>  
                                    </StylePrizeContainer>
                                    
                                </div> : ""
                            }
                            {
                               !nextOrPost &&  
                               <StyledPrizePotContainer isMobile={isMobile}>
                                    <section className={nextOrPost ? 'post-container' : 'next-container'}>
                                        <section>
                                            <StyledTitle isMobile={isMobile} style={{marginBottom: '10px'}}>{t("Prize pot")}</StyledTitle>
                                            {
                                                (latestedDraw && !nextOrPost) ?  <div>
                                                <StyledSum style={{fontWeight: 600, fontSize: isMobile ? 20 : 30, color: 'rgb(250,206,156)'}} isMobile={isMobile}>0 DCOIN</StyledSum>
                                                <StyledSum style={{fontWeight: 600, fontSize: isMobile ? 20 : 30, color: 'rgb(250,206,156)'}} isMobile={isMobile}>0 KYURYO</StyledSum>
                                                </div>
                                                :
                                                <div>
                                                    <StyledSum style={{fontWeight: 600, fontSize: isMobile ? 20 : 30, color: 'rgb(250,206,156)'}} isMobile={isMobile}>{formatDecimal(amountDcoin.current, 4)} DCOIN</StyledSum>
                                                    <StyledSum style={{fontWeight: 600, fontSize: isMobile ? 20 : 30, color: 'rgb(250,206,156)'}} isMobile={isMobile}>{formatDecimal(amountKyuryo.current, 4)} KYURYO</StyledSum>
                                                </div>
                                            }
                                        </section>
                                        {
                                            nextOrPost && <section className='right'>
                                                <div className='info'>{t("Match the winning number in the same order to share prizes.")}</div>
                                                <div className='info'>{t("Total players this round: ")} {totalPlayers}</div>
                                            </section>
                                        }
                                        {
                                            // next draw
                                            !nextOrPost && <section>
                                                {
                                                    account && <div>
                                                        <StyledTickets isMobile={isMobile}>
                                                            <div className='flex your-tickets'>
                                                                <div className='title'style={{marginTop:'24px'}}>{t("Your Tickets")}</div>
                                                                <div className='button' style={{marginTop: '20px'}}>
                                                                    {
                                                                        latestedDraw ? 
                                                                        <div className='desc' style={{marginBottom: '12px'}}>You have 0 ticket this round</div>:
                                                                        <div className='desc' style={{marginBottom: '12px'}}>You have {tickets} {(tickets === 1 || tickets === 0) ? 'ticket' : 'tickets'} this round</div>
                                                                    }
                                                                    {
                                                                        (tickets === 0 || latestedDraw) ? <Button style={{ height: !isMobile ? '56px' : '35px', borderRadius: '12px', background: '#fff', width: !isMobile ? '341px' : '100%', color: '#000'}} disabled={!isStart} onClick={openM} >{ !isStart ? t("on sale soon!"):  t("Buy Tickets ")}</Button>
                                                                        : <p  style={{color: '#3232F8'}} className='view-ticket' onClick={() => { nextOrPost ? setOpenLotteryDetailModal(true) :  setOpenLotteryDetailNextDrawModal(true)}} >view your tickets</p>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </StyledTickets> 
                                                    </div>
                                                }
                                                {
                                                    !account && <div style={{display: 'flex', justifyContent: 'center', margin: '20px 0'}}>
                                                        <Login
                                                            setIsModalOpen={setIsModalOpen}
                                                            isModalOpen={isModalOpen}
                                                        >
                                                        <div style={{width: !isMobile ? '341px' : '341px',height: '56px', background: '#fff', color: '#000' }} className={account ? 'styledHeaderTextC corsor-pointer' : 'styledHeaderTextC-hover styledHeaderTextC corsor-pointer'}>{ !isStart ? t("on sale soon!"):  t("Buy Tickets")}</div>
                                                        </Login>
                                                        {/* <Button  style={{ height: '56px', borderRadius: '12px', background: '#fff', width:  !isMobile ? '341px' : '80%', color: '#000'}} disabled={!isStart} onClick={openModal} >{ !isStart ? t("on sale soon!"):  t("Buy Tickets1")}</Button> */}
                                                    </div>
                                                }
                                            </section>
                                        }
                                    </section>    
                                </StyledPrizePotContainer>
                            }
                            
                            
                            {((flopFlag && !nodata && ((!latestedDraw && !nextOrPost) || nextOrPost)) && (!nextOrPost || (nextOrPost && +currentLotteryId > 1))) && <LotteryDetail rewards={rewards} burnDcoin={burnDcoin.current} burnKyuryo={burnKyuryo.current} source={nextOrPost ? "postDraw" : "nextdraw"} />}
                            {
                                !nodata && ((!nextOrPost && +currentLotteryId > 0 && !latestedDraw) || (nextOrPost && +currentLotteryId > 1 )) && <StyledMore isMobile={isMobile} onClick={() => { setFlipFlag(flag => !flag) }}>
                                    <img style={{ transform: flopFlag ? 'rotate(180deg) scale(0.5)' : 'rotate(0deg) scale(0.5)' }} alt="arrow" src='https://dreamfinance.s3.ap-northeast-1.amazonaws.com/assets/images/lottery/down_arrow.png' />  {flopFlag ? "Hide" : "Details"}
                                </StyledMore>
                            }
                           
                        </StyledPrize>
                    }
                </div>
            </StyledCardContainer>
            <Modal centered className='buy-tickets-modal' title={null} footer={null} open={openBuy} closable={false}>
                <BuyTicketsModal  onDismiss={onDismiss} />
            </Modal>
            <Modal centered className='buy-tickets-modal' title={null} footer={null} open={openLotteryDetailModal} closable={false}>
                <LotteryDetailMoal 
                    round={lotteryIndex} 
                    winningNumbers={winningNumbers} 
                    totalTickets={tickets} 
                    onDismiss={()=>setOpenLotteryDetailModal(false)}/>
            </Modal>
            <Modal centered className='buy-tickets-modal' title={null} footer={null} open={openLotteryDetailNextDrawModal} closable={false}>
                    <LotteryDetailNextDraw 
                        round={lotteryIndex} 
                        winningNumbers={winningNumbers} 
                        totalTickets={tickets} 
                        id={nanoid()}
                        onDismiss={()=>{setOpenLotteryDetailNextDrawModal(false)}}
                />
            </Modal>
        </>
    )
}

export default NextPostDrawCard;