/* eslint-disable import/prefer-default-export */
import { useCallback, useState, useEffect } from 'react'
import { useWeb3React } from '@web3-react/core'
import { useLottery } from 'hooks/useContract'
import { checkAndClaim, getLastDrawedLottory } from '../utils/lotteryUtils'

export const useLotteryCheckAndClaim = () => {
  const { account } = useWeb3React()
  const lotteryContract = useLottery()
  const handleCheck = useCallback(
    async () => {
      try {
        const txHash = await checkAndClaim(lotteryContract, account)
        return txHash
      } catch (e) {
        return {} as any
      }
    },
    [lotteryContract, account],
  )
  const getLastDrawedLottoryInfo = useCallback(
    async () => {
      try {
        const data = await getLastDrawedLottory(lotteryContract, account)
        return data
      } catch (e) {
        return {} as any
      }
    },
    [lotteryContract, account],
  )

  return { handleCheck ,getLastDrawedLottoryInfo}
}