/* eslint-disable import/no-named-as-default */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-await-in-loop */
import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from "react-i18next";
import { groupBy, sortBy } from 'lodash'
import EventBus from 'utils/eventBus'
import {  useModal, useMatchBreakpoints, TimerIcon } from '@dreamweb/uikit'
import moment from 'moment'
import { checkMyLotteryHistory, getViewLottery, getCurrentLotteryId, checkHistoryLotteryInfoByLotteryId } from 'utils/lotteryUtils';
import { useLottery } from 'hooks/useContract'
import { useWeb3React } from '@web3-react/core'
import { StyledCardContainer, StyledNodata, StyledBox, StyledCardHeader } from '../HistoryPanelStyle';
import HistoryTable from './HistoryTable';
 
 
const headerList = [{name:'#'}, { name: 'DATE'}, { name: "YOUR TICKETS"}, { name:''}]
 
const YourHistory  = (props: any) => {
    const { t } = useTranslation();
    const timer = useRef(null)
    const lotteryContract = useLottery();
    const { account } = useWeb3React();
    const [list, setList] = useState([]);
    // 真没有数据
    const [nodata, setNodata] = useState(true);
    const [noAccount, setNoAccount] = useState(false);
    const [lotteryStatus, setLotteryStatus] = useState({})
    const currentId = useRef(0)
    const currentAccount = useRef('');
    const { isSm, isXs, isLg, isMd,isXl } = useMatchBreakpoints();
    const isMobile = isXs || isSm || isLg || isMd;

    const createValidateRes = (res) => {
        const times = res[1]
        const arr=  times.map((item, index) => {
            return {
                lotteryId: res[0][index],
                round: index+1,
                date:  moment(Number(res[1][index]) * 1000).format('MMM D, YYYY, h:mm A'),
                tickets:  res[2][index],
                detail: ''
            }
        });
        const result = arr.filter(item => item.tickets !== '0');
        return sortBy(result, item => item.lotteryId);
    }

    const getLotteryInfo =async () => {
        const info = await getViewLottery(lotteryContract);
        const { status } = info; // 0 pending, 1 open, 2 close, 3 Claimable 
        const currentLotteryId = await getCurrentLotteryId(lotteryContract);
        setLotteryStatus(status);
        currentId.current = currentLotteryId;
    }

    const getReseult = async()=>{
        // const info = await getViewLottery(lotteryContract);
        // const { status } = info; // 0 pending, 1 open, 2 close, 3 Claimable 
        const currentLotteryId = await getCurrentLotteryId(lotteryContract);
        const info = await checkHistoryLotteryInfoByLotteryId(Number(currentLotteryId), lotteryContract);
        const result = await checkMyLotteryHistory(lotteryContract, account);
        if (result[0]?.length === 0) {
            setNodata(true);
        } else {
            setNodata(false);
            // result = result.filter(item => item)
            // eslint-disable-next-line no-lonely-if
            let lotterylist = createValidateRes(result);
            if (info.status !== '3') {
                lotterylist = lotterylist.filter(item => Number(item.lotteryId) < Number(currentLotteryId)) 
            }
            const groups = groupBy(lotterylist, 'lotteryId');
            const arr = [];
            const keys = Object.keys(groups);
            for(let i=0; i< keys?.length; i++ ) {
                const finalTime = await checkHistoryLotteryInfoByLotteryId(Number(keys[i]), lotteryContract);
                let total = 0;
                groups[keys[i]].forEach((item) => {
                    total += Number(item.tickets)
                })
                arr.push({
                    lotteryId: keys[i],
                    // date: groups[keys[i]][0].date,
                    tickets: total,
                    date: moment(Number(finalTime?.finalTime) * 1000).format('MMM D, YYYY, h:mm A'), 
                })
            }
            setList(arr.reverse());
        }
    }

    useEffect(() => {
        if ( (!account || (account !== currentAccount.current))) {
            if (account) {
                getLotteryInfo();
            }
            currentAccount.current = account;
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [account, props.update])

    useEffect(()=>{
        getLotteryInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.update])

    useEffect(()=>{
        // account 有值链接成功
        if (account) {
            getReseult();
            clearInterval(timer.current)
            timer.current = setInterval(() => { 
                getReseult();
            }, 6000)
            setNoAccount(false)
        } else {
            setNoAccount(true)
            setNodata(false)
        }
        return () => { 
            clearInterval(timer.current)
         }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[account, props.update])

    return (
        <StyledCardContainer isMobile={isMobile}>
            <StyledBox>
                {
                    noAccount && <div>
                        <StyledCardHeader isMobile={isMobile}>
                            <div style={{paddingTop: isMobile? '1.2rem': '14px'}}>{t("HISTORY")}</div>
                        </StyledCardHeader> 
                        <StyledNodata isMobile={isMobile}>{t("Connect your wallet to check your history")}</StyledNodata>
                    </div>
                }
                {
                    nodata && <div>
                        <StyledCardHeader isMobile={isMobile}>
                            <div style={{paddingTop: isMobile? '': '14px', textAlign: 'start'}}>{t("HISTORY")}</div>
                        </StyledCardHeader> 
                        <StyledNodata isMobile={isMobile}> 
                            <span>{t('No lottery history found.  ')}</span>
                            <span>{t('Buy tickets for the next round!')}</span>
                        </StyledNodata>
                    </div>
                }
                {
                    // nodata为真没有数据 noAccount 为假 链接成功
                    (!noAccount && !nodata) &&  <HistoryTable update={props?.update} setUpdate={props?.setUpdate} headerList={headerList} bodyList = {list} />
                }
            </StyledBox>
        </StyledCardContainer>
    )
}

export default React.memo(YourHistory);