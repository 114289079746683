/* eslint-disable no-nested-ternary */
/* eslint-disable no-return-assign */
/* eslint-disable import/no-cycle */
/* eslint-disable object-shorthand */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-await-in-loop */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable consistent-return */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect, useCallback } from "react";
import '../index.css'
import { getPool, getFarm , getLp } from 'utils/change'
import { nanoid } from "nanoid";
import { Decimal } from "decimal.js";
import { getDcoinAddress , 
  getFarmAddress,
  getPoolAddress
} from 'utils/addressHelpers'
import useToast from 'hooks/useToast'
import useWeb3 from 'hooks/useWeb3'
import _ from 'lodash'
import { approve as approveF } from 'utils/callHelpers'
import { useWeb3React } from '@web3-react/core'
import { getBep20Contract } from 'utils/contractHelpers'
import { useHistory } from 'react-router-dom'
import { useUpdateEffect, useUpdate } from 'ahooks'
import Menu from "./components/Menu";
import List from "./components/List";
import Card from "./components/Card";
import { TokenText, ComingSoon } from './indexStyle'
import './index.css'



interface MenuDataTypes {
  staked: boolean;
  pageType: number;
  selectType: string;
  status: boolean;
  searchValue: string;
}


const result = sessionStorage.getItem('gas') || 50000
let poolsG = []
let isFarmsG
const Pool = () => {
  const web3 = useWeb3()
  const { toastError, toastSuccess, toastInfo, toastWarning } = useToast()
  const history = useHistory()
  const { account } = useWeb3React()
  const farmContract =  getFarm(web3)
  const poolContract =  getPool(web3)
  const lpContract = getLp(web3)
  const dcoinAddress = getDcoinAddress();
  const farmAddress = getFarmAddress();
  const poolAddress = getPoolAddress();
  const update = useUpdate();
  const [approveAddress,setApproveAddress] = useState(dcoinAddress)
  const [menuData, setMenuData] = useState<MenuDataTypes>()
  const [poolLenght,setPoolLenght]=useState(0)
  const style = { minHeight: 'calc(100vh - 18rem)' }
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const handleResize = () => setWindowSize(window.innerWidth);
  const [poolsCopy, setPoolsCopy] = useState([]);
  const [isFarms,setIsFarms] = useState(new RegExp('/farms').test(window.location.href)?'Farms':'Pools')
  const [isUpdata,setIsUpdate]=useState(false)
  const [pools, setPools] = useState([]);
  const [showDetailList, setshowListDetial] = useState<number[]>([])
  const [program, setProgram] = useState();
  const [timer,setTimer]=useState(null)
  const [dataLoading,setDataLoading]=useState(true)
  const [matches, setMatches] = useState(
    window.matchMedia('(min-width: 769px)').matches,
  );

  useEffect(() => {
    window
      .matchMedia('(min-width: 769px)')
      .addEventListener('change', (e) => {
        setMatches(e.matches)
      });
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  });

  useEffect(() => { 
    poolsG = pools
  }, [pools])

  // menu状态
  const getMenuData = (data: MenuDataTypes) => {
    if (data.pageType !== menuData?.pageType) {
      setshowListDetial([])
    }
    setMenuData(data)
  }

  // 展开收起
  const showDetailClick = useCallback((index: number) => {
    if (showDetailList.indexOf(index) !== -1) {
      showDetailList.splice(showDetailList.indexOf(index), 1)
    } else {
      showDetailList.push(index)
    }
    setshowListDetial(showDetailList)
  }, [])



  const changePoolsData = useCallback((pools) => {
    // console.log('menuData', menuData);

    let poolsCopyData = [...pools]

    if(menuData?.selectType==='APY') {
      poolsCopyData.sort(function(a,b){ 
        return b?.apy - a?.apy
       })
    }
    if(menuData?.selectType==="Total staked") {
      poolsCopyData.sort(function(a,b){ 
        return b?.totalStaked - a?.totalStaked
       })
    }
    if(menuData?.staked) {
      poolsCopyData = poolsCopyData.filter((e) => { 
        return e?.staked
       })
    }

    return poolsCopyData
  }, [menuData])
  
  const changeCurrentShowListDeatil = (data) => {
    for (const x of data) {
      if (showDetailList.indexOf(x.index) !== -1) {
        x.showDetail = true
      }
    }
    return data
  }

  
  // 获取token0 - token1
  const getToken = async (index,paycoin) => { 
    if(isFarms==='Farms') {
      // console.log(2)
      // console.log('获取token0 - token1', paycoin)
      const res = await lpContract.methods.getReverseTokens(paycoin).call()
      // console.log('token0 - token1', res)
      return getIconName(res,index,paycoin)
    } 
    // console.log(3, isFarms==='Farms')
    return getIconName(null,index, paycoin)
    
  }

    
  // getIconName
  const getIconName = async (e,index,paycoin) => { 
    const paycoinContract = await getBep20Contract(paycoin)
    const getPaycoinToken1 = await paycoinContract.methods.symbol().call()
    if(e) {
      const token0Contract = await getBep20Contract(e?.[0])
      const token1Contract = await getBep20Contract(e?.[1])
      const getToken0 = await token0Contract.methods.symbol().call()
      const getToken1 = await token1Contract.methods.symbol().call()
      // console.log(index, 'token0 token1', paycoin, getPaycoinToken1, getToken0, getToken1)
      return {
        tokenArr:[getPaycoinToken1, getToken0, getToken1],
        addArr: [paycoin, e?.[0], e?.[1]]
      }
    } 
      return {
      tokenArr:[getPaycoinToken1],
      addArr: [paycoin]
    }
    
  }

  
  const getUserInfo = async (index) => {
    const stake1 = await userInfo(index)
    return new Decimal(stake1?.staked).div(1e18).toNumber()
  }

  // 获取 Pool 数量 view
  const getPoolLenght = async () => { 
    // console.log('获取 Pool 数量 view')
    try {
      const view = await (isFarms==='Farms'?farmContract:poolContract).methods.getPoolLenght().call()
      // console.log(385, view, menuData)
      if(view) {
        setPoolLenght(view)
        await getPools(view)
      }
    } catch (error) {
      setPools([])
      setPoolsCopy([])
      setDataLoading(true)
      // console.log(error)
    }
  }

  // 获取指池数据, paycoin 和 rewardcoin, paycoin 就是 LP， rewardcoin 就是 dcoin
  const getPools = async (view) => { 
    try {
      const newPools = await Promise.all(
        [...new Array(+view)].map(async (item,index) => {
          // console.log('开始获取指池数据 view', item, index)
          const view = await (isFarms==='Farms'?farmContract:poolContract).methods.pools(index).call()
          const token = await getToken(index,view?.paycoin)
          const staked = await getUserInfo(index)
          return new Promise((resolve) => {
            resolve({...view, farmAddress: isFarms==='Farms'?farmAddress:poolAddress, finished: !menuData.status, token, isFarms: isFarms==='Farms', staked: staked, idx: index})
          })
        })
      )
      // console.log(220, !_.isEqual(newPools,poolsG))
      if(!_.isEqual(newPools,poolsG)) {
        setPools(newPools)
        setPoolsCopy(changePoolsData(newPools))
        // console.log('获取指池数据 view', newPools)
      }
    } catch (error) {
      // setPools([])
      // setPoolsCopy([])
      // console.log(231, )
    }
  }
  
  // 获取 已质押得到的奖励数量 view
  const pending = async (index) => { 
    try {
      // console.log('开始获取 已质押得到的奖励数量 view', index, account)
      if(account){
        const view = await (isFarms==='Farms'?farmContract:poolContract).methods.pending(index, account).call()
        // console.log('获取 已质押得到的奖励数量 view', view)
        return view
      }
      return 0
    } catch (error) {
      // console.log(error)
      return 0
    }
  }

  // 质押  stake
  const deposit = async (index,val) => { 
    try {
      // console.log('开始质押', index, new Decimal(val || 0).mul(new Decimal(1e18)).toFixed())
      await (isFarms==='Farms'?farmContract:poolContract).methods.deposit(index, new Decimal(val || 0).mul(new Decimal(1e18)).toFixed()).send({
        from: account, 
        gasPrice: +result >= 5000000000 ? +result : +result * 1.5,
        // value: new Decimal(val || 1).mul(new Decimal(1e18)).toFixed()
      })
      toastSuccess('Stake successful')
      setIsUpdate((pre) => !pre)
    } catch (error:any) {
      toastWarning(error?.message ? error?.message : 'Stake failed')
    }
  }

    
  // 取回 Unstake
  const withdraw = async (index, val) => { 
    try {
      // console.log('开始取回', index, account)
      await (isFarms==='Farms'?farmContract:poolContract).methods.withdraw(index, new Decimal(val || 1).mul(new Decimal(1e18)).toFixed()).send({
        from: account, 
        gasPrice: +result >= 5000000000 ? +result : +result * 1.5,
        // value: new Decimal(val || 1).mul(new Decimal(1e18)).toFixed()
      })
      setIsUpdate((pre) => !pre)
      toastSuccess('Unstake successful')
    } catch (error:any) {
      toastWarning(error?.message ? error?.message : 'Unstake failed')
    }
  }

    
  // 领取奖励 Harvest
  const claim = async (index) => { 
    try {
      // console.log('开始领取奖励', index, (isFarms==='Farms'?farmContract:poolContract).methods)
      await (isFarms==='Farms'?farmContract:poolContract).methods.harvest(index).send({
        from: account, 
        gasPrice: +result >= 5000000000 ? +result : +result * 1.5,
      })
      setIsUpdate((pre) => !pre)
      toastSuccess('Harvest successful')
    } catch (error:any) {
      toastWarning(error?.message ? error?.message : 'Harvest failed')
    }
  }

    
  // approve
  const approve = async (newApproveAddress) => {
    try {
      await approveF(getBep20Contract(newApproveAddress, web3), isFarms==='Farms'?farmAddress:poolAddress, account)
      setIsUpdate((pre) => !pre)
      toastSuccess('Approve successful')
    } catch (error:any) {
      toastWarning(error?.message ? error?.message : 'Approve failed')
    }
  }

  // userInfo 获取 Staked
  const userInfo = async (v) => {
    try {
      if(account) {
        const view = await (isFarms==='Farms'?farmContract:poolContract).methods.userInfo(v, account).call()
        // console.log('Staked', view)
        return view
      }
      return {
        staked: 0
      }
    } catch (error) {
      // 
    }
  }

  
  // 查询是否授权
  const paycoinAllowance = async (address) => { 
    // 查询是否授权
    const maxNum = await getBep20Contract(address, web3).methods.allowance(account, farmAddress).call()
    // 未授权时授权
    if(!+maxNum){
      try {
        // await approveF(getBep20Contract(address, web3), farmAddress, account)
      } catch (error) {
        // 
      }
    }
  }

  
  useUpdateEffect(() => {
    setPoolsCopy(changePoolsData(pools))
  }, [menuData])

  const timingAcquisition = async () => { 
    setDataLoading(true)
    await getPoolLenght() 
    setDataLoading(false)
  }

  useEffect(() => { 
    clearInterval(timer)
    let newTimer
    if(poolLenght) {
      newTimer = setInterval(() => {
        // console.log('pools', poolsG, poolLenght)
        getPools(poolLenght)
      }, 2500);
      setTimer(newTimer)
    }
    return () => { 
      clearInterval(newTimer)
     }
  }, [poolLenght, account, isFarms])
    

  useEffect(() => {
    if(menuData){
      timingAcquisition()
    }
  }, [isFarms,isUpdata, menuData,account])

  
  useEffect(() => { 
    const unListen = history.listen((e) => {
      // console.log(390, isFarmsG)
      if(new RegExp('/farms').test(e.pathname)) {
        if(isFarmsG!=='Farms'){
          setDataLoading(true)
          setPools([])
          setPoolsCopy([])
        }
        isFarmsG='Farms'
        setIsFarms('Farms')
      } else {
        if(isFarmsG!=='Pools'){
          setDataLoading(true)
          setPools([])
          setPoolsCopy([])
        }
        isFarmsG='Pools'
        setIsFarms('Pools')
      }
     })
    return ()=>{
      unListen()
    }
  }, [account])

  return (
    <div className="pools-box" style={style}>
      <TokenText isMobile={matches}>
        <main>
          <div className='main'>
            <div className='module1'>
              <div className='text1'>{isFarms}</div>
              <div className='text2-box'>
                <div className='text2-1'>Stake {isFarms==='Farms'?'LP':''} {isFarms==='Farms'?'tokens':'tokens'} to earn.</div>
              </div>
            </div>
          </div>
        </main>
      </TokenText>
      {
        isFarms==='Farms' ?
        <ComingSoon>
          <div className="main">
            <img src="/images/ComingSoon.png" alt="" />
            <div>
              Coming Soon
            </div>
          </div>
        </ComingSoon> :
        <>
          <Menu changeData={getMenuData} isFarms={isFarms==='Farms'}/>
          {
            poolsCopy.length === 0 &&
            <div className="flex flex-col justify-center items-center mt-10 lg:px-32 lg:py-20">
              <span className="text-text2 text-10xl">{dataLoading?'loading':poolsCopy.length === 0?'No Data':''}</span>
            </div>
          }
          {
            !dataLoading && poolsCopy.length &&
            <div className="lg:container lg:max-w-screen-xl lg:mx-auto px-8 lg:px-32 py-10 lg:py-20 text-text1">
              <div className={menuData?.pageType === 1 ? `grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 gap-y-8 gap-x-20 ` : 'rounded-20 overflow-hidden'}>
                {
                  poolsCopy instanceof Array && poolsCopy.map((item: any, index: React.Key) => {
                    return menuData?.pageType === 1 ? (
                      <Card userInfo={userInfo} lpContract={lpContract} getPending={pending} idx={index} showDetailClick={showDetailClick} menuData={menuData} deposit={deposit} approve={approve} withdraw={withdraw} claim={claim} itemData={item} key={nanoid()} program={program} />
                    ) : (
                      <List userInfo={userInfo} lpContract={lpContract} getPending={pending} idx={index} showDetailClick={showDetailClick} windowSize={windowSize} deposit={deposit} approve={approve} withdraw={withdraw} claim={claim} program={program} itemData={item} key={nanoid()} className={`${index !== poolsCopy.length - 1 ? 'border-list border-b' : ''}`}  />
                    )
                  })
                }
              </div>
            </div>
          }
        </>
      }
    </div>
  )
}

export default Pool