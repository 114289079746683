import styled from 'styled-components';

const StyleBase = styled.div``
 
const StyleContainer = styled.div`
    width: 100%;
`
const StyleHeader = styled(StyleBase)<{isMobile?: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    flex-direction: column;
    width: 100%;
    height: ${({isMobile}) => isMobile ?  '10rem' : '300px' };
    padding-top: ${({isMobile}) => isMobile ?  '0' : '60px' };
    margin: ${({isMobile}) => isMobile ?  '2rem 0' : '65px 0' };
    background: rgb(194 194 194 / 15%);
    // background-color: #6DA5F7;

`
const StyleTitle = styled(StyleBase)<{isMobile?: boolean }>`
    margin-bottom: ${({isMobile}) => isMobile ?  '1rem' : '20px' };
    font-size: ${({isMobile}) => isMobile ?  '1.2rem' : '24px' };
    font-weight: normal;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;
`

const StyleButton = styled(StyleBase)<{isMobile?: boolean }>`
    display: flex;
    justify-content: center;
    flex-direction: column;
    font-size: ${({isMobile}) => isMobile ?  '1.4rem' : '22px' };
    button{
        width: ${({isMobile}) => isMobile ?  '8rem' : '300px' };
        height: ${({isMobile}) => isMobile ?  '2rem' : '45px' };
        margin-top:  ${({isMobile}) => isMobile ?  '0.6rem' : '30px' };;
    }
`

const StyledDispaly = styled(StyleBase)<{isMobile?: boolean }>`
    color: white;
    font-size: ${({isMobile}) => isMobile ?  '0.8rem' : '22px' };
    .title {
        margin-bottom: 10px;
    }
    .desc {
        margin-bottom: 10px;
    }
`
export { StyleContainer, StyleHeader, StyleTitle, StyleButton, StyledDispaly }