/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable no-alert */
import { useCallback } from 'react'
import { useWeb3React, UnsupportedChainIdError } from '@web3-react/core'
import { NoBscProviderError } from '@binance-chain/bsc-connector'
import {
  NoEthereumProviderError,
  UserRejectedRequestError as UserRejectedRequestErrorInjected,
} from '@web3-react/injected-connector'
import {message} from 'antd'
import useToast from 'hooks/useToast'
import {
  UserRejectedRequestError as UserRejectedRequestErrorWalletConnect,
  WalletConnectConnector,
} from '@web3-react/walletconnect-connector'
import { ConnectorNames, connectorLocalStorageKey } from '@dreamweb/uikit'
import { connectorsByName } from 'utils/web3React'
import { useAppDispatch } from 'state'
import { connectWeb3 } from 'utils/connectWeb3'
import { newUseMessage } from 'App'

// enum ConnectorNames {
//   Injected = "injected",
//   WalletConnect = "walletconnect",
//   BSC = "bsc"
// }

// const connectorLocalStorageKey = "connectorId";

const useAuth = () => {
  const dispatch = useAppDispatch()
  const { toastError, toastSuccess, toastInfo, toastWarning } = useToast()
  const { activate, deactivate } = useWeb3React()
  const login = useCallback((connectorID?: ConnectorNames) => {
    const connector = connectorsByName[connectorID]
    if (connector) {
      activate(connector, async (error: Error) => {
        if (error instanceof UnsupportedChainIdError) {
          toastWarning('Prompt', 'Currently this page only supported in BSC, Please switch your network to continue.')
          // message.warning({
          //   content: 'Currently this page only supported in BSC, Please switch your network to continue.',
          //   duration: 3
          // })
          deactivate()
          await connectWeb3()
          activate(connector)
        } else {
          window?.localStorage.removeItem(connectorLocalStorageKey)
          if (error instanceof NoEthereumProviderError || error instanceof NoBscProviderError) {
            window.location.href='https://metamask.app.link/dapp/dreamfi.finance'
          } else if (
            error instanceof UserRejectedRequestErrorInjected ||
            error instanceof UserRejectedRequestErrorWalletConnect
          ) {
            if (connector instanceof WalletConnectConnector) {
              const walletConnector = connector as WalletConnectConnector
              walletConnector.walletConnectProvider = null
            }
            
            toastWarning('Prompt', 'Authorization Error, Please authorize to access your account')
            // newUseMessage({isShow:true,isMf: false, t: true, isSetTimeout: true, message: 'Authorization Error, Please authorize to access your account'})
            // message.warning('Authorization Error, Please authorize to access your account')
          } else {
            toastWarning('Prompt', error.message)
            // newUseMessage({isShow:true,isMf: false, isSetTimeout: true, t: true, message: 'error.message'})
            // message.warning(`${error.message}`)
          }
        }
      })
    } else {
      toastWarning('Prompt', 'Can’t find connector, The connector config is wrong')
      // newUseMessage({isShow:true,isMf: false, t: true, isSetTimeout: true, message: 'Can’t find connector, The connector config is wrong'})
      // message.warning('Can’t find connector, The connector config is wrong')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activate])

  const logout = useCallback(() => {
    deactivate()
    window?.localStorage.removeItem(connectorLocalStorageKey)
  }, [deactivate])

  return { login, logout }
}

export default useAuth

