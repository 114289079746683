const tokens = {
  bnb: {
    symbol: 'BNB',
    projectLink: 'https://www.binance.com/',
    decimals: 18,
  },
  dcoin: {
    symbol: 'DCOIN',
    address: {
      56: '0xB84c120c7B96A08fAB69087B289729E453503E82',
      97: '0xe3A7389A47B90571E91A49DD86ccEC3FbED0f318',
    },
    decimals: 18,
    projectLink: '',
  },
  busd: {
    symbol: 'BUSD',
    address: {
      56: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
      97: '0xeD1829f3Ee4C282FeD8811Bca1DC28645FFF1bA0',
    },
    decimals: 18,
    projectLink: 'https://www.paxos.com/busd/',
  },
  usdt: {
    symbol: 'USDT',
    address: {
      56: '0x55d398326f99059ff775485246999027b3197955',
      97: '0x7bF108844eE03Fa70f0981980776c3b52bA4cAa9',
    },
    decimals: 18,
    projectLink: 'https://tether.to/',
  },
  wbnb: {
    symbol: 'wBNB',
    address: {
      56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      97: '0xA75cCf1E551e5A70F5fBE35D0f19BF3A2d3144a7',
    },
    decimals: 18,
    projectLink: 'https://pancakeswap.finance/',
  },
  kyuryo: {
    symbol: 'Kyuryo',
    address: {
      56: '0xc3FE9F68bB7dC321C6037542B2ECcaf4FD37353C',
      97: '0xf51f4dF6A01829d5D35fF5747927286Eb2545faD',
    },
    decimals: 18,
    projectLink: '',
  },
}

export default tokens
 