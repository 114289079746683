import styled from 'styled-components';

const StyleBase = styled.div``

const StyledContainer = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-bottom: 35px;
`
const StyledTitle= styled.div`
    font-family: Nirmala UI-Bold,Nirmala UI;
    font-size: 24px;
    font-weight: normal;
    line-height: 30px;
    color: #FFFFFF;
`

const StyleIntro = styled(StyleBase)<{isMobile?: boolean }>`
    width: ${({isMobile}) => isMobile ?  '100%' : '580px' };
    margin-bottom: 40px;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    line-height: 22px;
    color: #C2C2C2;
`

export { StyledContainer,  StyledTitle, StyleIntro}