import React from 'react'
import styled from 'styled-components'
import { Text } from '@dreamweb/uikit'
import { Spinner } from '../Shared'
import { AutoColumn } from '../Column'
import { Wrapper, Section, ConfirmedIcon, ContentHeader } from './helpers'

type ConfirmationPendingContentProps = { onDismiss: () => void; pendingText: string }

const CustomLightSpinner = styled(Spinner) <{ size: string }>`
  height: ${({ size }) => size};
  width: ${({ size }) => size};
`

const DarkText = styled.div`
  color: #fff;
  font-size: 14px;
  margin-top: 10px;
  text-align: center;
`

const ConfirmationPendingContent = ({ onDismiss, pendingText }: ConfirmationPendingContentProps) => {
  return (
    <Wrapper>
      <Section>
        <ContentHeader onDismiss={onDismiss}>
          {/* WAITING FOR CONFIRMATION */}
          Waiting For Confirmation
        </ContentHeader>
        <ConfirmedIcon>
          loading...
        </ConfirmedIcon>
        <AutoColumn gap="12px" justify="center">
          <AutoColumn gap="12px" justify="center">
            <DarkText>{pendingText}</DarkText>
          </AutoColumn>
          <DarkText>Confirm this transaction in your wallet</DarkText>
        </AutoColumn>
      </Section>
    </Wrapper>
  )
}

export default ConfirmationPendingContent
