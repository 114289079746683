/* eslint-disable import/prefer-default-export */
import { useCallback } from 'react'
import { useWeb3React } from '@web3-react/core'
import { useERC20 } from 'hooks/useContract'
import { updateUserAllowance, fetchFarmUserDataAsync } from 'state/actions'
import { approve } from 'utils/callHelpers'
import { Contract } from 'web3-eth-contract'
import { Address } from 'config/constants/types'
import { useAppDispatch } from 'state'
import { getAddress, getLotteryAddress, getIsDcoinKyuruoAddress } from 'utils/addressHelpers'
import { useDcoinKyuryopool } from './useContract'

// Approve the lottery
export const useLotteryApprove = (v:any) => {
  const { account } = useWeb3React()
  const isDcoinKyuruoAddress = getIsDcoinKyuruoAddress(v)
  const lotteryAddress = getLotteryAddress()
  const isDcoinKyuruoContract = useERC20(isDcoinKyuruoAddress)
  const handleApprove = useCallback(async () => {
      const tx = await approve(isDcoinKyuruoContract, lotteryAddress, account)
      return tx
  }, [lotteryAddress, account, isDcoinKyuruoContract])

  return { onApprove: handleApprove }
}

// Approve a Pool
export const useSousApprove = (lpContract: Contract, sousId) => {
  const dispatch = useAppDispatch()
  const { account } = useWeb3React()
  const sousChefContract = useDcoinKyuryopool(sousId)

  const handleApprove = useCallback(async () => {
    try {
      const tx = await approve(lpContract, sousChefContract.options.address, account)
      dispatch(updateUserAllowance(sousId, account))
      return tx
    } catch (e) {
      return false
    }
  }, [account, dispatch, lpContract, sousChefContract, sousId])

  return { onApprove: handleApprove }
}

// Approve a Farm
export const useApprove = (lpContract: Contract, lpFarmAddr: Address) => {
  const dispatch = useAppDispatch()
  const { account } = useWeb3React()

  const handleApprove = useCallback(async () => {
    try {
      const tx = await approve(lpContract, getAddress(lpFarmAddr), account)
      dispatch(fetchFarmUserDataAsync(account))
      return tx
    } catch (e) {
      return false
    }
  }, [account, dispatch, lpContract, lpFarmAddr])

  return { onApprove: handleApprove }
}
