import addresses from 'config/constants/contracts'
import tokens from 'config/constants/tokens'

// eslint-disable-next-line consistent-return
export const getAddress = (address): string => {
  const mainNetChainId = 56
  const chainId = process &&  process.env.REACT_APP_CHAIN_ID
  return address[chainId] ? address[chainId] : address[mainNetChainId]
}

export const getLotteryAddress = () => {
  return getAddress(addresses.lottery)
}


export const getWbnbAddress = () => {
  return getAddress(tokens.wbnb.address)
}

export const getUsdtAddress = () => {
  return getAddress(tokens.usdt.address)
}


export const getRouterAddress = () => {
  return getAddress(addresses.router)
}
export const getIsDcoinKyuruoAddress = (v:any) => {
  return getAddress(+v===0?addresses.dcoinToken:addresses.kyuruoToken) 
}

export const getDcoinAddress = () => {
  return getAddress(addresses.dcoinToken) 
}
export const getKyuruoAddress = () => {
  return getAddress(addresses.kyuruoToken) 
}




export const getMulticallAddress = () => {
  return getAddress(addresses.multiCall)
}

export const getDreamFramAddress = () => {
  return getAddress(addresses.dreamFram)
}

export const getChangeAddress = () => {
  return getAddress(addresses.change)
}


export const getChangeDAddress = () => {
  return getAddress(addresses.changeD)
}



export const getChangeYAddress = () => {
  return getAddress(addresses.changeY)
}


export const getNftAddress = () => {
  return getAddress(addresses.nft)
}

export const getNftStakingAddress = () => {
  return getAddress(addresses.NFTStaking)
}


export const getPokemonNFTStakingAddress = () => {
  return getAddress(addresses.pokemonNFTStaking)
}

export const getPegasusNftAddress = () => {
  return getAddress(addresses.pegasusNft)
}

export const getPokemonNftAddress = () => {
  return getAddress(addresses.pokemonNft)
}

export const getPrimaryMarketAddress = () => {
  // return getAddress(addresses.primaryMarket)
}

export const getExchangeAddress = () => {
  return getAddress(addresses.exchange)
}

export const getMDFNftAddress = () => {
  return getAddress(addresses.mdfNft)
}

export const getFarmAddress = () => {
  return getAddress(addresses.farm)
}


export const getPoolAddress = () => {
  return getAddress(addresses.pool)
}

export const getLpAddress = () => {
  return getAddress(addresses.LP)
}

