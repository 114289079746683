/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-cycle */
import React, { useCallback, useState, useEffect, useRef } from 'react'
import { Button, useMatchBreakpoints } from '@dreamweb/uikit'
import { useLotteryCheckAndClaim } from 'hooks/useLottery'
import { useTranslation } from "react-i18next";
import { useLottery } from 'hooks/useContract'
import Login from 'components/Login'
import { useWeb3React } from '@web3-react/core'
import { claimTickets } from 'utils/lotteryUtils';
import { interceptAdd } from 'utils/utils'
import { StyleTitle, StyleHeader, StyleContainer, StyleButton, StyledDispaly } from './DoYouWinStyle';
import { checkLotteryStatus } from '../../utils'

const DoYouWin: React.FC<any> = ({ records, setUpdate, update }) => {
    const { t } = useTranslation();
    const { account } = useWeb3React();
    const [requestedClaim, setRequestedClaim] = useState(false)
    const [canClaim, setCanClaim] = useState(false);
    const { handleCheck, getLastDrawedLottoryInfo } = useLotteryCheckAndClaim()
    const lotteryContract = useLottery();
    const { isSm, isXs, isLg, isMd, isXl } = useMatchBreakpoints();
    const isMobile = isXs || isSm || isLg || isMd;
    const [lotteryIdsModal, setLotteryIdsModal] = useState([])
    const [lotteryIdModal, setLotteryIdModal] = useState(0)
    const [bracketsModal, setBracketsModal] = useState([])
    const [isChecking, setIsChecking] = useState(false)
    const [periods, setPeriods] = useState(1)
    const [isWin, setIsWin] = useState(false);
    const [notWin, setNotWin] = useState(false);
    const [noReward, setNoReward] = useState(false);
    const [showCheck, setShowCheck] = useState(true);
    const [showTitle, setShowTitle] = useState(true);
    const [loading, setIsLoadig] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const currentAccount = useRef('');

    const handleCheckReward = async() => {
        setRequestedClaim(false)
        localStorage.setItem('claimLoding', '0')
        try {
            setIsChecking(true)
            const data = await getLastDrawedLottoryInfo();
            setIsChecking(false)
            const { lottery, user, lotteryId } = data;
            // console.log(41, lottery, user, lotteryId)
            const {finalNumber} = lottery as any;
            if (finalNumber) {
                const { lotteryIds, brackets, winNumbers } = checkLotteryStatus(user, finalNumber.substring(1).split(''));
                // console.log(46, lotteryIds, brackets, winNumbers)
                setBracketsModal(brackets)
                setLotteryIdsModal(winNumbers)
                setLotteryIdModal(lotteryId)
                setShowCheck(false)
                setShowTitle(false)
                if (!lotteryIds?.length) {
                    setNotWin(true)
                } else {
                    setIsWin(true)
                    setCanClaim(true)
                }
            }
        } catch (error) {
            console.error(error)
        }
    }

    const checkCanClamin = async () => {
        if (!account) return false;
        const data = await getLastDrawedLottoryInfo();
        const { lottery, user, lotteryId } = data;
        const userInfo = await lotteryContract.methods.viewUserInfoForLotteryId(account, lotteryId, 0, 1000).call();
        const {finalNumber} = lottery as any;
        const status = []
        if (finalNumber) {
            const { lotteryIds, brackets } = checkLotteryStatus(user, finalNumber.substring(1).split(''));
            userInfo[1].forEach((item,index) => {
                const reverseId = `1${String(item).substring(1).split('').reverse().join('')}`;
                if (lotteryIds.includes(reverseId)) {
                    status.push(userInfo[2][index])
                }
            })
        }
        return status.every(item => item)
    }

    useEffect(() => { 
        setRequestedClaim(!!+localStorage.getItem('claimLoding'))
    }, [update])
    const handleClaim =  async () => {
        try {
            const allReward = await checkCanClamin();
            if (allReward) {
                setIsWin(false)
                setNoReward(true)
                setRequestedClaim(true)
                setCanClaim(false)
                setUpdate(pre=>!pre)
            } else {
                setRequestedClaim(true)
                localStorage.setItem('claimLoding', '1')
                setUpdate(pre=>!pre)
                await claimTickets(lotteryContract, lotteryIdsModal, bracketsModal, account, lotteryIdModal);
                setRequestedClaim(false)
                localStorage.setItem('claimLoding', '0')
                setUpdate(pre=>!pre)
            }
        } catch (error) {
            localStorage.setItem('claimLoding', '0')
            setRequestedClaim(false)
            setUpdate(pre=>!pre)
            console.error(error)
        }
    }
    useEffect(() => {
        
        async function lottoryInfo() {
            const data = await getLastDrawedLottoryInfo();
            setPeriods(data.lotteryId)
        }
        lottoryInfo()
        checkCanClamin()
        if ((!account || (account !== currentAccount.current))) {
            setShowTitle(true)
            setCanClaim(false)
            setIsWin(false)
            setNoReward(false)
            setNotWin(false)
            setShowCheck(true)
            currentAccount.current = account;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [account])


    return (
        <>
            <StyleContainer >
                <StyleHeader isMobile={isMobile} 
                // style={{ backgroundColor: '#6DA5F7' }}
                >
                    {
                        showTitle && <StyleTitle isMobile={isMobile}>{t("DID YOU WIN??")}</StyleTitle>
                    }
                    <StyleButton isMobile={isMobile}>
                        {
                            noReward && <StyledDispaly isMobile={isMobile} style={{color: 'white'}}>
                                <div className='title'>The reward has already been claimed</div>
                            </StyledDispaly>
                        }
                        {
                            isWin && <StyledDispaly isMobile={isMobile} style={{color: 'white'}}>
                                <div className='title'>Congratulations! you win!</div>
                                <div className='desc'>Round {lotteryIdModal}</div>
                                <div className='desc'>winning tickets {lotteryIdsModal?.length}</div>
                            </StyledDispaly>
                        }
                        {
                            notWin && <StyledDispaly isMobile={isMobile} style={{color: 'white'}}>
                                <div className='title'>
                                    <p>No prizes to collect.</p>
                                    <p>Better luck next time!</p>
                                </div>
                            </StyledDispaly>
                        }
                        {
                          !account && 
                          <Login
                              setIsModalOpen={setIsModalOpen}
                              isModalOpen={isModalOpen}
                          >
                            <Button style={{background: '#fff', color: '#000', borderRadius: '12px', width: '341px', height: '56px'}} className={account ? 'styledHeaderTextC corsor-pointer' : 'styledHeaderTextC-hover styledHeaderTextC corsor-pointer'}>{interceptAdd(account)}</Button>
                          </Login>
                        }
                        { (account && showCheck ) && <Button style={{background: '#fff', color: '#000', borderRadius: '12px'}} disabled={isChecking || periods < 2} onClick={handleCheckReward}>
                                {t("Check!!") }
                            </Button>
                        }
                        { (account && canClaim ) && <Button disabled={requestedClaim} onClick={handleClaim}>
                                {
                                    loading && <img src="https://dreamfinance.s3.ap-northeast-1.amazonaws.com/assets/images/button_loading.gif" alt=''  />
                                }
                                <span style={{marginLeft: '12px', color: '#000'}}>{t('Claim')}</span>
                            </Button>
                        }
                    </StyleButton>
                </StyleHeader>
            </StyleContainer>
        </>
    )
}
export default DoYouWin;
 