import React, { useEffect, useRef, useState } from 'react'
import moment from 'moment';
import { formatDateWithSlash } from 'utils/utils'
import { GYTN } from '../../LotteryStyle'

export default function Index(props: any) {
  const timerEnd = useRef(null)
  const [countdown,setCountdown]=useState('00 h 00 m 00 s')
  useEffect(() => { 
    if(props?.isStart) {
      clearInterval(timerEnd.current)
      timerEnd.current = setInterval(() => { 
        const newTemer = formatDateWithSlash(+props?.endtime - +moment().format('X'))
        setCountdown(`${newTemer?.h} h ${newTemer?.m} m ${newTemer?.s} s`)
        if((+props?.endtime - +moment().format('X')) <= 0) {
          clearInterval(timerEnd.current)
        }
      }, 1000)
    } else {
      clearInterval(timerEnd.current)
      timerEnd.current = setInterval(() => { 
        const newTemer = formatDateWithSlash(props?.oneStartTimer- +moment().format('X'))
        setCountdown(`${newTemer?.d} d ${newTemer?.h} h ${newTemer?.m} m ${newTemer?.s} s`)
        if((+props?.oneStartTimer - +moment().format('X')) <= 0) {
          clearInterval(timerEnd.current)
        }
      }, 1000)
    }
    return () => {
      clearInterval(timerEnd.current)
    }
  }, [props?.oneStartTimer, props?.endtime, props?.isStart])
  return (      
  <GYTN isMobile={props?.isMobile}>
    <div className='title'>Get your tickets now!</div>
    <div className='timer-box'>
      <div className='timer'>{countdown}</div>
      <div className='nd'>until the draw</div>
    </div>
  </GYTN>
  )
}
