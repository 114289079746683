import { Currency, CurrencyAmount, Fraction, Percent } from '@dreamfinance-libs/sdk'
import React from 'react'
import styled from 'styled-components'
import useI18n from 'hooks/useI18n'
import { Button, Text } from '@dreamweb/uikit'
// import { TranslateString } from 'utils/translateTextHelpers'
import { RowBetween, RowFixed } from '../../components/Row'
import CurrencyLogo from '../../components/CurrencyLogo'
import { Field } from '../../state/mint/actions'

const DarkText = styled.div`
  color: #fff;
  font-size: 14px;
  margin-top: 10px;
  text-align: center;
`

const ThomText = styled.div`
  color: #FF9100;
  font-size: 14px;
  margin-top: 10px;
  text-align: center;
`

export function ConfirmAddModalBottom({
  noLiquidity,
  price,
  currencies,
  parsedAmounts,
  poolTokenPercentage,
  onAdd,
  onDismiss,
}: {
  noLiquidity?: boolean
  price?: Fraction
  currencies: { [field in Field]?: Currency }
  parsedAmounts: { [field in Field]?: CurrencyAmount }
  poolTokenPercentage?: Percent
  onAdd: () => void
  onDismiss?: any
}) {
  const TranslateString = useI18n()
  return (
    <>
      <RowBetween>
        <DarkText>{currencies[Field.CURRENCY_A]?.symbol} Deposited</DarkText>
        <RowFixed>
          <CurrencyLogo currency={currencies[Field.CURRENCY_A]} style={{ marginRight: '8px' }} />
          <ThomText>{parsedAmounts[Field.CURRENCY_A]?.toSignificant(6)}</ThomText>
        </RowFixed>
      </RowBetween>
      <RowBetween>
        <DarkText>{currencies[Field.CURRENCY_B]?.symbol} Deposited</DarkText>
        <RowFixed>
          <CurrencyLogo currency={currencies[Field.CURRENCY_B]} style={{ marginRight: '8px' }} />
          <ThomText>{parsedAmounts[Field.CURRENCY_B]?.toSignificant(6)}</ThomText>
        </RowFixed>
      </RowBetween>
      <RowBetween>
        <DarkText>Rates</DarkText>
        <ThomText>
          {`1 ${currencies[Field.CURRENCY_A]?.symbol} = ${price?.toSignificant(4)} ${currencies[Field.CURRENCY_B]?.symbol
            }`}
        </ThomText>
      </RowBetween>
      <RowBetween style={{ justifyContent: 'flex-end' }}>
        <ThomText>
          {`1 ${currencies[Field.CURRENCY_B]?.symbol} = ${price?.invert().toSignificant(4)} ${currencies[Field.CURRENCY_A]?.symbol
            }`}
        </ThomText>
      </RowBetween>
      <RowBetween>
        <DarkText>Share of Pool:</DarkText>
        <ThomText>{noLiquidity ? '100' : poolTokenPercentage?.toSignificant(4)}%</ThomText>
      </RowBetween>
      <Button mt="20px" onClick={async ()=>{
        await onAdd()
        onDismiss()
      }} style={{color:'#000'}}>
        {noLiquidity ? TranslateString(250, 'Supply') : TranslateString(252, 'Confirm Supply')}
      </Button>
    </>
  )
}

export default ConfirmAddModalBottom
