/* eslint-disable no-extra-boolean-cast */
/* eslint-disable no-nested-ternary */
import styled from 'styled-components';

const StyleBase = styled.div``

const Wrapper = styled(StyleBase)<{isMobile?: boolean}>`
  position: relative;
  top: ${({isMobile}) => isMobile ?  '-52px' : '' };
  display: flex;
  justify-content: center;
  width: ${({isMobile}) => isMobile ?  'calc(100vw - 2px)' : '100vw' };
  ${({isMobile}) => isMobile ?  'min-width' : '' }: 1220px;
  min-height: 100vh;
  z-index: 1;
  overflow: hidden;
  >main {
    display: flex;
    justify-content: center;
    width: ${({isMobile}) => isMobile ?  '1220px' : '100%' };
    color: #FFFFFF;
    >.main {
      width: ${({isMobile}) => isMobile ?  '1220px' : 'calc(100vw - 30px)' };
    }
  }
`
const StyledHeader = styled(StyleBase)<{isMobile?: boolean}>`
    position: fixed;    
    top: 0;
    left: 0;
    display: flex;
    justify-content: ${({isMobile}) => isMobile ?  'space-between' : 'space-between' };
    align-items: center;
    width: ${({isMobile}) => isMobile ?  '100%' : '100%' };
    padding: ${({isMobile}) => isMobile ?  '0 24px' : '0 15px' };
    padding-top: ${({isMobile}) => isMobile ?  '22px' : '37px' };
    ${({isMobile}) => isMobile ?  'z-index' : 'z-index' }: ${({isMobile}) => isMobile ?  '500' : '500' };
    background: #000000;
    .logo {
      display: flex;
      justify-content: ${({isMobile}) => isMobile ?  'center' : 'space-between' };
      align-items: center;
      a {
        width: ${({isMobile}) => isMobile ?  '32px' : '32px' };
        height: ${({isMobile}) => isMobile ?  '32px' : '32px' };
      }
      img {
        max-width: none;
        width: ${({isMobile}) => isMobile ?  '32px' : '32px' };
        height: ${({isMobile}) => isMobile ?  '32px' : '32px' };
      }
      .logo-text {
        display: ${({isMobile}) => isMobile ?  '' : 'none' };
        font-size: 32px;
        font-family: Nirmala UI-Regular,Nirmala UI;
        font-weight: bold;
        color: #FFFFFF;
        margin-left: 11px;
      }
    }
    .menu {
      display: flex;
      justify-content: center;
    }
    .m-menu {
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 11px;
      img {
        width: 20px;
      }
    }
    .menu-text {
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: M PLUS 1;
      font-style: normal;
      font-size: 16px;
      line-height: 150%;
      text-align: right;
      letter-spacing: 0.02em;
      color: #fff;
      font-weight: 700;
      .is-action {
        a {
          color: #fff;
        }
        span {
          color: #fff;
        }
      }
      a:hover {
        color: #fff;
      }
      span:hover {
        color: #fff;
      }
      a {
        text-decoration:none;
        color: #757575;
      }
      span {
        color: #757575;
      }
      >div {
        margin-right: 32px;
      }
      .en {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 92px;
        height: 48px;
        background: linear-gradient(110.46deg, rgba(18, 25, 40, 0.9) 31.56%, rgba(15, 23, 40, 0.9) 99.56%);
        border-radius: 25px;
      }
    }
    
    .tab {
      display: flex;
      justify-content: flex-end;
      >div {
          // width: 86px;
          height: ${({isMobile}) => isMobile ?  '40px' : '30px' };
          border-radius: 4px 4px 4px 4px;
      }  
      .top-8 {
          top: 2.6rem;
      }
      .mr-16 {
          margin-right: 10px;
      }
    }
    .chain {
      width: ${({isMobile}) => isMobile ?  '86px' : '86px' };
      height: ${({isMobile}) => isMobile ?  '40px' : '30px' };
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 12px;
      border-radius: 4px 4px 4px 4px;
      opacity: 1;
      color: #E6E6E6;
      border: 1px solid #FFFFFF;
    }
    .login {
      display: flex;
      justify-content: center;
      align-items: center;
      width: ${({isMobile}) => isMobile ?  '150px' : '132px' };
      height: ${({isMobile}) => isMobile ?  '40px' : '30px' };
      background: #000;
      border-radius: 4px 4px 4px 4px;
      font-size: 14px;
      font-family: Nirmala UI-Regular;
      font-weight: 400;
      border: 1px solid #fff;
      color: #fff !important;
      >.login-popver {
        color: #fff !important;
        font-family: Nirmala UI-Regular;
        width: ${({isMobile}) => isMobile ?  '132px' : '132px' };
        height: ${({isMobile}) => isMobile ?  '40px' : '30px' };
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .styledHeaderTextC-hover {
        color: #fff !important;
      }
    }
    .t-menu {
      margin-left: 12px;
      width: 42px;
      height: 42px;
      >img {
        width: 42px;
        height: 42px;
      }
    }
`

const LoginHover = styled(StyleBase)<{isMobile?: boolean}>`
  width: ${({isMobile}) => isMobile ?  '162px' : '145px' };
  color: #fff;
  font-size: ${({isMobile}) => isMobile ?  '18px' : '16px' };
  >div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 42px;
  }
`

const StyledFooter = styled(StyleBase)<{isMobile?: boolean}>`
  position: relative;
  display: flex;
  justify-content: center;
  width: ${({isMobile}) => isMobile ?  'calc(100vw - 2px)' : '100vw' };
  padding-top: ${({isMobile}) => isMobile ?  '188px' : '30px' };
  ${({isMobile}) => isMobile ?  'min-width' : '' }: 1220px;
  background: #000000;
  z-index: 1;
  .main {
    width: ${({isMobile}) => isMobile ?  '1220px' : 'calc(100vw - 30px)' };
  }
  >main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-size: 100% 100%;
    width: ${({isMobile}) => isMobile ?  '1220px' : '100%' };
    color: #FFFFFF;
    padding-bottom: 60px;
    .h5-footer {
      background: #232323;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100vw;
      display: ${({isMobile}) => isMobile ?  'none' : 'flex' };
      justify-content: ${({isMobile}) => isMobile ?  'space-evenly' : 'space-evenly' };
      align-items: center;
      height: 62px;
      .img {
        img {
          width: 16px;
          height: 16px;
        }
      }
    }
    
    .logo-g {
      display: ${({isMobile}) => isMobile ?  'flex' : 'flex' };
      justify-content: ${({isMobile}) => isMobile ?  'center' : 'flex-start' };
      align-items: center;
      margin-bottom: 34px;
      >img {
        width: ${({isMobile}) => isMobile ?  '55px' : '32px' };
        height: ${({isMobile}) => isMobile ?  '55px' : '32px' };
      }
      .logo-text {
        font-size: 16px;
        font-family: Nirmala UI-Bold, Nirmala UI;
        font-weight: bold;
        color: #FFFFFF;
        margin-left: 11px;
      }
    }
    .year {
      display: flex;
      justify-content: ${({isMobile}) => isMobile ?  'space-between' : 'space-between' };
      align-items: center;
      padding-bottom: ${({isMobile}) => isMobile ?  '' : '50px' };  
      .logo {
        display: ${({isMobile}) => isMobile ?  'flex' : 'flex' };
        justify-content: ${({isMobile}) => isMobile ?  'center' : 'space-between' };
        align-items: center;
        >img {
          width: ${({isMobile}) => isMobile ?  '55px' : '32px' };
          height: ${({isMobile}) => isMobile ?  '55px' : '32px' };
        }
        .logo-text {
          font-size: 36px;
          font-family: Nirmala UI-Bold, Nirmala UI;
          font-weight: bold;
          color: #FFFFFF;
          margin-left: 11px;
        }
      }
    }
    .link {
      display: flex;
      justify-content: ${({isMobile}) => isMobile ?  'space-between' : 'space-between' };
      align-items: start;
      border-bottom: 1px solid #707070;
      margin-bottom: 15px;
      flex-wrap: wrap;
      .link-box {
        .title {
          font-size: 16px;
          font-family: Nirmala UI-Bold,Nirmala UI;
          font-weight: 400;
          color: #EEEEEE;
          line-height: 32px;
          margin-bottom: 19px;
        }
        .z-item {
          margin-bottom: 15px;
          display: flex;
          font-size: 16px;
          font-family: Nirmala UI-Regular, Nirmala UI;
          font-weight: 400;
          color: #999999;
          line-height: 20px;
          .img {
            width: 20px;
            height: 20px;
            margin-right: 14px;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }
`
const Banner1 = styled(StyleBase)<{isMobile?: boolean}>`
  position: relative;
  display: flex;
  justify-content: start;
  >main {
    color: #FFFFFF;
    >.banner1 {
      position: relative;
      width: ${({isMobile}) => isMobile ?  '1220px' : 'calc(100vw - 30px)' };
      padding-top:  ${({isMobile}) => isMobile ?  '220px' : '0' };
      display: flex;
      justify-content: ${({isMobile}) => isMobile ?  'flex-start' : 'center' };
      .siw {
        position: relative;
        z-index: 5;
        width: ${({isMobile}) => isMobile ?  '770px' : '300px' };
        height: ${({isMobile}) => isMobile ?  '' : '125px' };
        text-align: ${({isMobile}) => isMobile ?  'start' : 'center' };
        font-size: ${({isMobile}) => isMobile ?  '56px' : '32px' };
        font-family: Nirmala UI-Bold, Nirmala UI;
        font-weight: bold;
        color: #EEEEEE;
        span {
          color: ${({isMobile}) => isMobile ?  '#0072ED' : '#0072ED' };
        }
      }
      .banner1-img {
        width: ${({isMobile}) => isMobile ?  '1007px' : '157%' };
        min-width: ${({isMobile}) => isMobile ?  '' : '543px' };
        position: absolute;
        top: ${({isMobile}) => isMobile ?  '-140px' : '51px' };
        right: ${({isMobile}) => isMobile ?  '-200px' : '-28%' };
        img {
          width: 100%;
        }
      }
    }
    >.banner2 {
      position: relative;
      z-index: 2;
      display: flex;
      flex-direction: ${({isMobile}) => isMobile ?  '' : 'column-reverse' };
      margin-top: ${({isMobile}) => isMobile ?  '375px' : '420px' };
      flex-direction: ${({isMobile}) => isMobile ?  'row-reverse' : '' };
      justify-content: ${({isMobile}) => isMobile ?  'space-between' : '' };
      .banner2-img {
        display: flex;
        justify-content: center;
        align-items: center;
        width: ${({isMobile}) => isMobile ?  '354px' : '100%' };
        min-width: ${({isMobile}) => isMobile ?  '' : '321px' };
        img {
          width: 80%;
        }
      }
      .banner2-main {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-end;    
        flex: ${({isMobile}) => isMobile ?  '1' : '' };
        .banner2-main-change {
          position: relative;
          width: 100%;
          font-size: ${({isMobile}) => isMobile ?  '40px' : '26px' };
          font-family: Nirmala UI-Bold, Nirmala UI;
          font-weight: bold;
          color: #EEEEEE;
          padding-top: 29px;
          margin-bottom: ${({isMobile}) => isMobile ?  '40px' : '24px' };
          border-top: 1px dashed #FFFFFF;
        }
        .banner2-main-change::after {
          background: #fff;
          content: '';
          position: absolute;
          width: 50px;
          height:6px;
          top: -1px;
          left: 0px;
        }
        .banner2-main-text {
          font-size: ${({isMobile}) => isMobile ?  '20px' : '16px' };
          font-family: Nirmala UI-Regular, Nirmala UI;
          font-weight: 400;
          color: #EEEEEE;
          line-height: 32px;
        }
        .icon-box {
          display: flex;
          margin-top: 37px;
          margin-bottom: 28px;
          .icon {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .icon-img {
              width: 30px;
              img {
                width: 100%;
              }
            }
          }
          .buy {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 40px;
            width: 132px;
            height: 40px;
            background: #FFFFFF;
            border-radius: 4px 4px 4px 4px;
            font-size: 14px;
            font-family: Nirmala UI-Bold;
            font-weight: 400;
            color: #000000;
          }
        }
      }
    }
    .banner3 {
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin-top: ${({isMobile}) => isMobile ?  '140px' : '93px' };
      .banner3-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: ${({isMobile}) => isMobile ?  '220px' : '110px' };
        height: ${({isMobile}) => isMobile ?  '150px' : '145px' };
        background: #333333;
        text-align: ${({isMobile}) => isMobile ?  '' : 'center' };
        .banner3-item-img {
          width: 38px;
          img {
            width: 100%;
          }
        }
        .banner3-item-text {
          font-size: ${({isMobile}) => isMobile ?  '16px' : '17px' };
          font-family: Nirmala UI-Regular, Nirmala UI;
          font-weight: 400;
          color: #EBEBEB; 
          margin-top: ${({isMobile}) => isMobile ?  '14px' : '8px' };
        }
        .banner3-item-text-2 {
          display: none;
          width: ${({isMobile}) => isMobile ?  '' : '66%' };
          font-size: ${({isMobile}) => isMobile ?  '16px' : '10px' };
        }
      }
      .banner3-item-b {
        background: #FFFFFF;
        .banner3-item-text {
          color: #303030; 
        }
      }
    }
    >.banner4 {
      position: relative;
      z-index: 2;
      display: flex;
      flex-direction: ${({isMobile}) => isMobile ?  'row-reverse' : 'column-reverse' };
      margin-top: ${({isMobile}) => isMobile ?  '139px' : '57px' };
      .banner4-img {
        display: flex;
        justify-content: center;
        align-items: center;
        width: ${({isMobile}) => isMobile ?  '354px' : '100%' };
        min-width: ${({isMobile}) => isMobile ?  '' : '321px' };
        img {
          width: 80%;
        }
      }
      .banner4-main {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-end;
        width: ${({isMobile}) => isMobile ?  '822px' : '100%' };
        .banner4-main-change {
          position: relative;
          width: 100%;
          font-size: ${({isMobile}) => isMobile ?  '40px' : '26px' };
          font-family: Nirmala UI-Bold, Nirmala UI;
          font-weight: bold;
          color: #EEEEEE;
          padding-top: 29px;
          margin-bottom: ${({isMobile}) => isMobile ?  '40px' : '24px' };
          border-top: 1px dashed #FFFFFF;
        }
        .banner4-main-change::after {
          background: #fff;
          content: '';
          position: absolute;
          width: 50px;
          height:6px;
          top: -1px;
          left: 0px;
        }
        .banner4-main-text {
          font-size: 16px;
          font-family: Nirmala UI-Regular, Nirmala UI;
          font-weight: 400;
          color: #EEEEEE;
          line-height: 32px;
        }
      }
    }
    >.banner5 {
      position: relative;
      z-index: 2;
      display: flex;
      flex-direction: ${({isMobile}) => isMobile ?  'reverse' : 'column-reverse' };
      margin-top: ${({isMobile}) => isMobile ?  '190px' : '93px' };
      .banner5-img {
        display: flex;
        justify-content: center;
        align-items: center;
        width: ${({isMobile}) => isMobile ?  '354px' : '100%' };
        min-width: ${({isMobile}) => isMobile ?  '' : '321px' };
        img {
          width: 80%;
        }
      }
      .banner5-main {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: ${({isMobile}) => isMobile ?  '822px' : '100%' };
        margin-top: ${({isMobile}) => isMobile ?  '36px' : '' };
        .banner5-main-change {
          position: relative;
          width: 100%;
          font-size: ${({isMobile}) => isMobile ?  '40px' : '26px' };
          font-family: Nirmala UI-Bold, Nirmala UI;
          font-weight: bold;
          color: #EEEEEE;
          padding-top: 29px;
          margin-bottom: ${({isMobile}) => isMobile ?  '40px' : '24px' };
          border-top: 1px dashed #FFFFFF;
        }
        .banner5-main-change::after {
          background: #fff;
          content: '';
          position: absolute;
          width: 50px;
          height:6px;
          top: -1px;
          left: 0px;
        }
        .banner5-main-text {
          font-size: ${({isMobile}) => isMobile ?  '20px' : '16px' };
          font-family: Nirmala UI-Regular, Nirmala UI;
          font-weight: 400;
          color: #EEEEEE;
          line-height: 32px;
        }
      }
    }
    >.banner6 {
      position: relative;
      z-index: 2;
      display: flex;
      flex-direction: ${({isMobile}) => isMobile ?  'row-reverse' : 'column-reverse' };
      margin-top: ${({isMobile}) => isMobile ?  '231px' : '93px' };
      .banner6-img {
        display: flex;
        justify-content: center;
        align-items: center;
        width: ${({isMobile}) => isMobile ?  '354px' : '100%' };
        min-width: ${({isMobile}) => isMobile ?  '' : '321px' };
        img {
          width: 80%;
        }
      }
      .banner6-main {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: ${({isMobile}) => isMobile ?  '822px' : '100%' };
        .banner6-main-change {
          position: relative;
          width: 100%;
          font-size: ${({isMobile}) => isMobile ?  '40px' : '26px' };
          font-family: Nirmala UI-Bold, Nirmala UI;
          font-weight: bold;
          color: #EEEEEE;
          padding-top: 29px;
          margin-bottom: ${({isMobile}) => isMobile ?  '40px' : '24px' };
          border-top: 1px dashed #FFFFFF;
        }
        .banner6-main-change::after {
          background: #fff;
          content: '';
          position: absolute;
          width: 50px;
          height:6px;
          top: -1px;
          left: 0px;
        }
        .banner6-main-text {
          font-size: ${({isMobile}) => isMobile ?  '20px' : '16px' };
          font-family: Nirmala UI-Regular, Nirmala UI;
          font-weight: 400;
          color: #EEEEEE;
          line-height: 32px;
        }
      }
    }
    >.banner7 {
      position: relative;
      z-index: 2;
      display: flex;
      flex-direction: ${({isMobile}) => isMobile ?  'reverse' : 'column-reverse' };
      margin-top: ${({isMobile}) => isMobile ?  '167px' : '93px' };
      .banner7-img {
        display: flex;
        justify-content: center;
        align-items: center;
        width: ${({isMobile}) => isMobile ?  '354px' : '100%' };
        min-width: ${({isMobile}) => isMobile ?  '' : '321px' };
        margin-top: ${({isMobile}) => isMobile ?  '21px' : '' };
        img {
          width: 80%;
        }
      }
      .banner7-main {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: ${({isMobile}) => isMobile ?  '822px' : '100%' };
        .banner7-main-change {
          position: relative;
          width: 100%;
          font-size: ${({isMobile}) => isMobile ?  '40px' : '26px' };
          font-family: Nirmala UI-Bold, Nirmala UI;
          font-weight: bold;
          color: #EEEEEE;
          padding-top: 29px;
          margin-bottom: ${({isMobile}) => isMobile ?  '40px' : '24px' };
          border-top: 1px dashed #FFFFFF;
        }
        .banner7-main-change::after {
          background: #fff;
          content: '';
          position: absolute;
          width: 50px;
          height:6px;
          top: -1px;
          left: 0px;
        }
        .banner7-main-text {
          font-size: ${({isMobile}) => isMobile ?  '20px' : '16px' };
          font-family: Nirmala UI-Regular, Nirmala UI;
          font-weight: 400;
          color: #EEEEEE;
          line-height: 32px;
        }
      }
    }
  }
`
const ShowMenu = styled(StyleBase)<{isMobile?: boolean}>`
  background: #323232;
  padding: 20px;
  border-radius: 8px;
  padding-bottom: 5px;
  >div {
    margin-bottom: 20px;
  }
`

// eslint-disable-next-line import/prefer-default-export
export { LoginHover, StyledHeader, Wrapper, StyledFooter, Banner1, ShowMenu
}