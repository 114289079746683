/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/rules-of-hooks */
import React, {useCallback,useEffect,useState} from 'react'
import styled from 'styled-components'
import ModalActions from 'components/ModalActions'
import { accMinus, formatDecimal } from 'utils/math'
import BigNumber from 'bignumber.js'
import { useHistory } from "react-router-dom";
import { useTokenBalance } from 'hooks/useTokenBalance'
import { Text, Button, Input, InputProps, Flex, Link, Modal } from '@dreamweb/uikit'
import { NewModalInput } from './indexStyle'


const StyledTokenInput = styled.div<InputProps>`
  display: flex;
  flex-direction: column;
  background-color: #010101;
  border-radius: 16px;
  color: ${({ theme }) => theme.colors.text};
  padding: 8px 16px 8px 0;
  width: 100%;
`

const StyledInput = styled(Input)`
  box-shadow: none;
  flex: 1;
  margin: 0 8px;
  padding: 0 8px;
  background: rgba(255, 0, 0, 0);
  border: 1px solid #888;
  border-radius: 10px;

  &:focus:not(:disabled) {
    box-shadow: 0px 0px 0px 2px #fff;
  }

  ${({ theme }) => theme?.mediaQueries?.xs} {
    width: 80px;
  }

  ${({ theme }) => theme?.mediaQueries?.sm} {
    width: auto;
  }
`

const StyledErrorMessage = styled(Text)`
  position: absolute;
  bottom: 0px;
  width: 100%;
  text-align: center;
  cursor: pointer;
  color: #fff;
  a {
    color: #fff;
  }
`

export default function Tokens({staked,link, name, isFarms, lpContract, tokenArr, idx, onDismiss, deposit, withdraw, address, isAdd}: any) {
  const [val, setVal] = useState('')  
  const history = useHistory()
  const balance = useTokenBalance(address)
  const [pendingTx, setPendingTx] = useState(false)

  const handleChange = useCallback(
    (e) => {
      if (e.currentTarget.validity.valid) {
        const newVal = e.currentTarget.value.replace(/,/g, '.');
        setVal(newVal);
      }
    },
    [setVal],
  )


  const handleSelectMax = () => {
    // 获取最大值
    if(isAdd) {
      setVal(`${new BigNumber(balance || 0).div(1e18).toNumber()}`)
    } else {
      setVal(staked)
    }
  }

  
  const handleConfirmClick = async () => {
    setPendingTx(true)
    try {
      // 确认
      if(isAdd) {
        await deposit(idx, val)
      } else {
        await withdraw(idx, val)
      }
      setPendingTx(false);
      onDismiss();
      setVal('')
    } catch (e) {
      setPendingTx(false);
      setVal('')
    }
    setVal('')
  }
  return (
    <Modal style={{background: '#232323'}} title={isAdd?'Stake TOKENS':'Unstake TOKENS'} onDismiss={()=>{
      // if(pendingTx) {
      //   return
      // }
      // setVal('')
      onDismiss()
    }} >
      <NewModalInput>
        <div style={{ position: 'relative',paddingBottom: isFarms?20:0 }}>
          <StyledTokenInput>
            <Flex justifyContent="space-between" pl="16px">
              <Text fontSize="14px">{isAdd?'Stake':'Unstake'}</Text>
              <Text fontSize="14px">Balance: {isAdd?formatDecimal((new BigNumber(balance || 0).div(1e18).toNumber()), 4):formatDecimal(staked, 4)}</Text>
            </Flex>
            <Flex alignItems="center" justifyContent="space-between" mt="8px">
              <StyledInput
                pattern="^[0-9]*[.,]?[0-9]*$"
                inputMode="decimal"
                step="any"
                min="0"
                onChange={handleChange}
                placeholder="0"
                value={val}
              />
              <div style={{display:'flex',alignItems:'center'}}>
                <Button style={{color: '#000'}} scale="sm" onClick={handleSelectMax} mr="8px">
                  Max
                </Button>
                <Text fontSize="16px">{tokenArr?.length===1?tokenArr?.[0]:`${tokenArr?.[1]}/${tokenArr?.[2]} LP`}</Text>
              </div>
            </Flex>            
            <Flex alignItems="center" justifyContent="start" mt="8px">
              <Text fontSize="12px">
                <div style={{color:'red', marginLeft: '25px'}}>
                {
                +val > (isAdd?(new BigNumber(balance || 0).div(1e18).toNumber()):staked) ?
                ` Insufficient ${tokenArr?.length===1?tokenArr?.[0]:`${tokenArr?.[1]}/${tokenArr?.[2]} LP`} Balance` : ''
              }
                </div>
              </Text>
            </Flex>

          </StyledTokenInput>
          {
            isFarms &&
            <StyledErrorMessage onClick={()=> {
              history.push(link)
            }} fontSize="14px" color="failure">
              <u>
                GET {name} LP
              </u>
            </StyledErrorMessage>
          }
          <ModalActions>
            <Button 
              style={{borderRadius: '12px'}} 
              variant="secondary" 
              onClick={onDismiss} width="100%" 
              disabled={pendingTx||!+val}
            >
              Cancel
            </Button>
            <Button style={{borderRadius: '12px',color: '#000'}}
              width="100%"
              disabled={pendingTx||!+val||+val>(isAdd?(new BigNumber(balance || 0).div(1e18).toNumber()):staked)}
              onClick={handleConfirmClick}
            >
              {pendingTx ? 'Confirming' : 
              <>
              Confirm
              </>
              }
            </Button>
          </ModalActions>
          {/* <div>
            <Button 
              style={{borderRadius: '12px'}} 
              variant="secondary" 
              onClick={onDismiss} width="100%" 
              disabled={pendingTx}
            >
              Cancel
            </Button>
            <Button style={{borderRadius: '12px',color: '#000'}}
              width="100%"
              disabled={pendingTx}
              onClick={handleConfirmClick}
            >
              {pendingTx ? 'Confirming' : 'Confirm'}
            </Button>
          </div> */}
        </div>
      </NewModalInput>
    </Modal>
  )
}
