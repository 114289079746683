export default {
  router: {
    56: '0x0ad5C283131fec3f3DdE4FA958048858e382eAFF',
    97: '0xe393380A92db9AC698A2ebaE3d2f8963268a16E2',
  },
  dream: {
    97: '0x74fA9E9c6Bc12fB35409b40fec7C3b34Af92545c',
    1: '0xcDfC85B293ff5D78f78d135d702e855680B4cf1C'
  },
  lottery: {
    56: '0x70F89d0f89dA69c846F81A678b94dDa93eC5E6D1',
    97: '0xb3dC8c087c04Cc6eCdc4c772c5a9b3a7706d1849'
  },
  dcoinToken: {
    56: '0xB84c120c7B96A08fAB69087B289729E453503E82',
    97: '0xe3A7389A47B90571E91A49DD86ccEC3FbED0f318'
  },
  kyuruoToken: {
    56: '0xc3FE9F68bB7dC321C6037542B2ECcaf4FD37353C',
    97: '0xf51f4dF6A01829d5D35fF5747927286Eb2545faD'

  },
  multiCall: {
    56: '0x41263cba59eb80dc200f3e2544eda4ed6a90e76c',
    97: '0xff0997392A63Ca7e5eaB82F22077eF848cb468e2',
  },
  dreamFram: {
    56: '0x040270e09bf6F7e1554c85C48C6dcA223db9b008',
    // 56: '0xA31B302209C7Fc8086a3D05D8f98e1376CD08926',
    97: '0x4229fE35B17e7Fdc16Ee4f65e4079B71466837A1',
  },
  exchange: {
    56: '0xd67Dc4984bc41Bac96F0CeC3013Fd19d040F884b',
    97: '0xE931fF0B1a150d6C76b686137857709fe4607f1D',
  },
  change: {
    56: '0x66439ffA9CEc1f42111f42F7DaEB2aCEe8f743d0',
    97: '0x66439ffA9CEc1f42111f42F7DaEB2aCEe8f743d0',
  },
  changeD: {
    56: '0xbA48D315082Bac6693B5D9f4Cd152510bcFCDA55',
    97: '0x1929E89C912e2F58F8223635c20915397c8db48C',
  },
  changeY: {
    56: '0x8905b6c04FfF9Ae7F3FDc6aBCeB3ee0F989C27c5',
    97: '0x51A2f65C5B872497C7844BBe51bd9350748d653b',
  },
  nft: {
    56: '0x29c31C812F7c2C582685799A47685AA0E5a52b06',
    97: '0x29c31C812F7c2C582685799A47685AA0E5a52b06',
  },
  pegasusNft: {
    56: '0x6b90F2A0043146A4c3a9B6f989Cb7C0Ae0006562',
    97: '0x1E34E828eCf1261596b5B96b9735119394008f41',
  },
  mdfNft: {
    56: '0xE547b1801863DD78bD0FD8DA0C20D2eB3D447c2a',
    97: '0x91F6063B8595FC3538B41Fa434779F366dcfFac3',
  },
  pokemonNft: {
    56: '0x4873C6c8c589e2bF238D1F93E4F26942732a71bc',
    97: '0xF31634D2A8e5A587089a9Ee216fCe009eb36e895',
  },
  NFTStaking: {
    56: '0x444f2428E25195EeaD268e8703B3Fc3F85aC1D1C',
    97: '0x68fFb96A5db0E7edaA7024c627dFE0b6b46CD7ea',
  },
  pokemonNFTStaking: {
    56: '0xa07d42dAFF5EA20262fDc63d0BCE0843b9e2a762',
    97: '0xCbd54e61F56B48269E9c88130D08Ebd388D9AFC2',
  },
  farm: {
    56: '0x559515Cab6d0904D5Af0BB5Ee6714CF8437825a4',
    97: '0xc86E60c14195C04384be0Cac4AE3605096792A4e',
  },
  pool: {
    56: '0xb0E3506F9eA34Da8361AC25F67E54C05ab91342e',
    97: '0x2D95E12F78A3A3dd87AfC32BFd4De951f421a2D8',
  },
  LP: {
    56: '0x8CE870cdFE655FDaCe782825C6f45F7c6653a995',
    97: '0xb0de5a131523566dbd8075820cabD74007b75926',
  }
}

