import styled from 'styled-components'

const StyleBase = styled.div``

const StyleTable = styled(StyleBase)<{isMobile?: boolean }>`
    width: ${({isMobile}) => isMobile ?  '100%' : '580px' };
    color: white;
    table{
        width: 100%;
        thead {
            tr{
                display: flex;
                justify-content: space-between;
                height: 40px;
                width:100%;
                wi
                line-height: 40px;
                th{
                    word-wrap: break-word;
                    word-break: break-all;
                }
            }
        }
        tbody{
            tr{
                display: flex;
                justify-content: space-between;
                height: 40px;
                width:100%;
                line-height: 40px;
                td:last-child{
                    cursor: pointer;
                }
            }
        }
    }
`
const StyleHeader = styled.div`
    display: flex;
    justify-content: space-around;
    color: white;
    th{
        word-break:break-all;
    }
`
const StyleBody = styled.div`
    color: white;
    p{
        display: flex;
        justify-content: space-around;
    }
`
const StyledDetail = styled(StyleBase)<{isMobile?: boolean }>`
    .winning-number{
        padding-top: 20px;
        .title{
            color: #F8E513;
            margin-bottom: 16px;
            text-align: center;
            font-size:  ${({isMobile}) => isMobile ?  '1rem' : '22px' };;
            font-weight: bold;
        }
    }
    .your-tickets{
        display: flex;
        padding-left:  ${({isMobile}) => isMobile ?  '0.8rem' : '20%' };
        padding-bottom: 20px;
        .title{
            font-size:  ${({isMobile}) => isMobile ?  '1rem' : '18px' };
            font-weight: bold;
            margin-right: 10%;
        }
        .desc{
            font-size:  ${({isMobile}) => isMobile ?  '0.8rem' : '16px' };
            p {
                margin-bottom: 10px;
            }
        }
    }
    .view-tickets{
        color: #FFF;
        cursor: pointer;
    }
    .ticket-detail{
        display: flex;
    }
`

// eslint-disable-next-line import/prefer-default-export
export { StyleTable, StyleHeader, StyleBody, StyledDetail };