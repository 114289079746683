/* eslint-disable import/no-named-as-default */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-cycle */
import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { ButtonMenu, Button, ButtonMenuItem, useMatchBreakpoints } from '@dreamweb/uikit'
import PastLotteryDataContext from 'contexts/PastLotteryDataContext'
import { getCurrentLotteryId, checkHistoryLotteryInfoByLotteryId, firstOpenTimeBy } from 'utils/lotteryUtils'
import EventBus from 'utils/eventBus'
import { useTranslation } from "react-i18next";
import { useWeb3React } from '@web3-react/core'
import { useLottery } from 'hooks/useContract'
import _ from 'lodash'
import moment from 'moment';
import useGetDocumentTitlePrice from 'hooks/useGetDocumentTitlePrice'
import BigNumber from 'bignumber.js'
import { formatDateWithSlash } from 'utils/utils'
import { useHistory } from "react-router-dom";
import { DEFAULT_TOKEN_DECIMAL } from 'config'
import Header from './components/BuyTickets/Header'
import NextPostDrawPage from './components/PostDrawPage/NextPostDrawCard';
import HowToPlay from './components/HowToPlay/PlayRules'
import MoreDetail from './components/MoreDetail/MoreDetai'
import { StyleContainer } from './LotteryStyle'
import GYTN from './components/GYTN'
import DoYouWin from './components/CheckWinStatus/DoYouWin';
import LotteryHistory from './components/LotteryHistory'

 
const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0px;
`



const Lottery: React.FC = () => {
  const lotteryContract = useLottery();
  const history = useHistory()
  const { t } = useTranslation();
  const [historyData, setHistoryData] = useState([]);
  const [historyError, setHistoryError] = useState(false);
  const [currentLotteryNumber, setCurrentLotteryNumber] = useState(0);
  const [mostRecentLotteryNumber, setMostRecentLotteryNumber] = useState(1);
  const [amount, setAmount] = useState([new BigNumber(0),new BigNumber(0)]);
  const [rewards, setRewards] = useState([]);
  const [lotteryInfo, setLotteryInfo] = useState({});
  const [flag, setFlag] = useState(false);
  const currentLotteryId = useRef<string>('0');
  const { account, chainId } = useWeb3React();
  const [countdown,setCountdown]=useState('00 h 00 m 00 s')
  const [lotteryIndex, setLotteryIndex ] = useState(0);
  const [oneStartTimer, setOneStartTimer] = useState(0);
  const [endtime, setEndtime] = useState('');
  const [update,setUpdate]=useState(false)
  const currentAccount = useRef('');
  const timerLotteryInfo = useRef(null);
  const activeIndex = useRef(0)
  const [tabIndex, setTabIndex] = useState(0)
  // 判断是否开奖
  const [isStart, setIsStart] = useState(false);
  const timerStart = useRef(null)
  const timerEnd = useRef(null)
  const [latestedDraw, setlatestedDraw] = useState(false);
  const { isSm, isXs, isLg, isMd,isXl } = useMatchBreakpoints();
  const chainid = sessionStorage.getItem('chainid')
  const isMobile = isXs || isSm || isLg || isMd;
  // const idDisbale = currentLotteryId == 0 || status == 0

  
 
  const getPostLotteryInfo = async () => {
      const info = await checkHistoryLotteryInfoByLotteryId( latestedDraw ? currentLotteryId.current : Number(currentLotteryId.current) - 1, lotteryContract);
      setLotteryIndex(() => latestedDraw ? Number(currentLotteryId.current) : Number(currentLotteryId.current) - 1)
      setLotteryInfo(() => info);
  }
 
  const getNextLotteryInfo = async () => {
      const info = await checkHistoryLotteryInfoByLotteryId(currentLotteryId.current, lotteryContract);
      const stimer =  await firstOpenTimeBy(lotteryContract)
      setOneStartTimer(stimer)
      setEndtime(info.endTime)
      setLotteryIndex(Number(currentLotteryId.current))
      const { amountCollectedInDcoin, amountCollectedInKyuryo } = info;   
      
      setAmount([new BigNumber(amountCollectedInDcoin).div(DEFAULT_TOKEN_DECIMAL), 
        new BigNumber(amountCollectedInKyuryo).div(DEFAULT_TOKEN_DECIMAL)])  
      setLotteryInfo(info);
  }

  const getCurLotteryId = async () => {
    currentLotteryId.current = await getCurrentLotteryId(lotteryContract);
    setLotteryIndex(Number(currentLotteryId.current))
    const info = await checkHistoryLotteryInfoByLotteryId(Number(currentLotteryId?.current), lotteryContract);
    // 已经开奖 没有下一期 或 第一期的时候没开始的状态是：currentLotteryId === 0 并且 status === 0
    setlatestedDraw((info?.status === '0' && Number(currentLotteryId?.current) === 0) || info?.status === '3')
    getNextLotteryInfo();
  }

  const handleClick = async (index: number) => {
    if (index === 1) {
      setFlag(true)
    } else {
      setFlag(false) 
    }
    setTabIndex(index)
    activeIndex.current = index;
    if (index === 1) { // post draw
        if ( currentLotteryId.current === '0' || currentLotteryId.current === '1') return;
        getPostLotteryInfo();
    } else {
        getNextLotteryInfo();
    }
  }

  const refeshPageData = async () => {
    currentLotteryId.current = await getCurrentLotteryId(lotteryContract);
    if (activeIndex.current === 1) {
      getPostLotteryInfo();
    } else {
      getNextLotteryInfo();
    }
  }
  useEffect(() => {
    if ((!account || (account !== currentAccount.current))) {
        if (lotteryContract && account) {
          // refeshPageData()
        }
        currentAccount.current = account;
    }
    refeshPageData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, flag, chainid])

  // useEffect(() => { 
  //   getPostLotteryInfo()
  //   clearInterval(timerLotteryInfo.current)
  //   timerLotteryInfo.current = setInterval(() => { 
  //     getPostLotteryInfo()
  //   }, 2000)
  //   return () => { 
  //     clearInterval(timerLotteryInfo.current)
  //    }
  //  }, [])

  useEffect(() => {
    if (lotteryContract) {
        getCurLotteryId();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chainid])

  useEffect(() => {
    EventBus.on('reFetchDataWhenBuyTicketsSuccessfully', (res) => {
        if (activeIndex.current === 0) {
          getNextLotteryInfo();
        } 
    })
    return () => {
      EventBus.off('reFetchDataWhenBuyTicketsSuccessfully')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chainid])

  //  网页名字
  // useGetDocumentTitlePrice(localStorage.getItem('isDcoinKyuruo')||0) 

  // 判断是否开奖
  useEffect(() => { 
    if(oneStartTimer * 1000 > new Date().valueOf()) {
        setIsStart(false)
        timerStart.current = setInterval(() => {
            if(oneStartTimer * 1000 <= new Date().valueOf()) {
                setIsStart(true)
            }
        }, 2000); 
    } else {
        setIsStart(true)
    }
    return () => { 
        clearInterval(timerStart.current)
     }
  }, [oneStartTimer])

  useEffect(() => { 
    if(isStart) {
      clearInterval(timerEnd.current)
      timerEnd.current = setInterval(() => { 
        const newTemer = formatDateWithSlash(+endtime - +moment().format('X'))
        // setCountdown(`${newTemer?.h} h ${newTemer?.m} m ${newTemer?.s} s`)
        if((+endtime- +moment().format('X')) <= 10) {
          getNextLotteryInfo()
        }
        if((+endtime - +moment().format('X')) <= 0) {
          setUpdate(pre=>!pre)
          getNextLotteryInfo()
          clearInterval(timerEnd.current)
        }
      }, 1000)
    } else {
      clearInterval(timerEnd.current)
      timerEnd.current = setInterval(() => { 
        const newTemer = formatDateWithSlash(oneStartTimer- +moment().format('X'))
        // setCountdown(`${newTemer?.d} d ${newTemer?.h} h ${newTemer?.m} m ${newTemer?.s} s`)
        if((+oneStartTimer - +moment().format('X')) <= 10) {
          getNextLotteryInfo()
        }
        if((+oneStartTimer - +moment().format('X')) <= 0) {
          setUpdate(pre=>!pre)
          getNextLotteryInfo()
          clearInterval(timerEnd.current)
        }
      }, 1000)
    }
    return () => {
      clearInterval(timerEnd.current)
    }
  }, [oneStartTimer, endtime, isStart])
 

  return (
    <>
      <Header latestedDraw={latestedDraw} endTime={endtime} amount={amount} oneStartTimer={oneStartTimer}/>
      {/* <GYTN isMobile={isMobile}>
        <div className='title'>Get your tickets now!</div>
        <div className='timer-box'>
          <div className='timer'>{countdown}</div>
          <div className='nd'>until the draw</div>
        </div>
      </GYTN> */}
      <GYTN  isMobile={isMobile} oneStartTimer={oneStartTimer} endtime={endtime} isStart={isStart}/>
        <StyleContainer style={{ marginTop: isMobile ? '0rem' : '60px',}}>
          <Wrapper style={{justifyContent: !isMobile ? 'center' : 'center'}}>
            <StyleContainer isMobile={isMobile}  style={{marginTop: isMobile? '0rem' : '60px', width: !isMobile ? '260px' : '260px' }}>
              <ButtonMenu style={{ width: '100%', backgroundColor: '#232323', borderRadius: '9px' }} activeIndex={tabIndex} onItemClick={handleClick} scale="sm" >
                <ButtonMenuItem style={{ width: '50%',backgroundColor: tabIndex !== 1 ? '#fff': '', borderRadius: '8px', color: tabIndex !== 1 ? '#000': '#8A8A8A'}}>{t('Next draw')}</ButtonMenuItem>
                <ButtonMenuItem style={{ width: '50%',backgroundColor: tabIndex === 1 ? '#fff': '', borderRadius: '8px', color: tabIndex === 1 ? '#000': '#8A8A8A'}}>{t('Past draw')}</ButtonMenuItem>
              </ButtonMenu>
            </StyleContainer>
          </Wrapper>
        </StyleContainer>   
        <StyleContainer  style={{marginTop: 0,  paddingTop: 0}}>
          <PastLotteryDataContext.Provider value={{ historyError, historyData, mostRecentLotteryNumber, currentLotteryNumber }} >
            <div style={{ display: 'flex', marginTop: 0, alignItems: 'center', flexDirection: 'column' }}>
                <NextPostDrawPage currentLotteryId={currentLotteryId?.current} latestedDraw={latestedDraw} nextOrPost={flag} lotteryIndex={lotteryIndex} lotteryInfo={lotteryInfo} refeshPageData={refeshPageData} oneStartTimer={oneStartTimer} /> 
            </div>
          </PastLotteryDataContext.Provider>
        </StyleContainer>
        <DoYouWin setUpdate={setUpdate}  update={update}/>
        <StyleContainer  style={{marginTop: 0,  paddingTop: 0}}>
          <LotteryHistory  setUpdate={setUpdate} update={update} chainid={chainid}/>
          <HowToPlay />
          <MoreDetail />
        </StyleContainer>
    </>
  )
}

export default Lottery