
const gas = sessionStorage.getItem('gas') || 50000

const reverseLottery = (obj: any) => {
  return {
    ...obj,
    finalNumber: `1${String(obj.finalNumber).substring(1).split('').reverse().join('')}`,
  }
}

export const getCurrentLotteryId = async (lotteryContract) => {
  const lotteryId = await lotteryContract.methods.currentLotteryId().call();
  return lotteryId;
}

// 根据传入指定id查询历史彩票信息
export const checkHistoryLotteryInfoByLotteryId = async (lotteryId, lotteryContract) => {
  const result = await lotteryContract.methods.viewLottery(lotteryId).call();
  if (result.finalNumber > 0) {
    return reverseLottery(result);
  }
  return result
}


// 查询开始时间
export const firstOpenTimeBy = async (lotteryContract) => {
  const result = await lotteryContract.methods.firstOpenTime().call();
  return result
}


export const getMax = async (lotteryContract) => {
  return lotteryContract.methods.maxNumberTicketsPerBuyOrClaim().call()
}


// 获取本期彩票信息
export const getViewLottery = async (lotteryContract) => {
  const lotteryId = await lotteryContract.methods.currentLotteryId().call(); // 查询类方法调用call
  const result = await lotteryContract.methods.viewLottery(lotteryId).call()
  if (result.finalNumber > 0) {
    return reverseLottery(result);
  }
  return result
}


// 购买彩票  isDcoinKyuruoAddress true 为  Dcoin   false 为  Kyuryo
export const buyTickets = async (lotteryContract, ticketsArr, account, isDcoinKyuruoAddress) => {
  const tickets = ticketsArr.map(item => Number(1 + item.map(num => num).reverse().join('')));
  const lotteryId = await lotteryContract.methods.currentLotteryId().call();
  // console.log('购买参数', lotteryId, tickets, isDcoinKyuruoAddress)
  return lotteryContract.methods.buyTickets(lotteryId, tickets, isDcoinKyuruoAddress).send({ from: account,
    gasPrice: +gas >= 5000000000 ? +gas : +gas * 1.5, }) // 购买和领取中奖这种属于操作，方法调用send 
}


// 查看自己是否获奖并领取
export const checkAndClaim = async (lotteryContract, account) => {
  const lotteryId = await lotteryContract.methods.currentLotteryId().call();
  const result = await lotteryContract.methods.viewUserInfoForLotteryId(account, lotteryId, 0, 1000).call();
  return reverseUserView(result);
}

const reverseUserView = (obj: any) => {
  const data = { ...obj }
  data[1] = data[1].map(num => `1${String(num).substring(1).split('').reverse().join('')}`)
  return data
}

// 检查中奖信息
export const getLastDrawedLottory = async (lotteryContract, account) => {
  let lotteryId = await lotteryContract.methods.currentLotteryId().call(); // 查询类方法调用call
  let result = await lotteryContract.methods.viewLottery(lotteryId).call()
  if (Number(result.status) !== 3 && lotteryId > 1) {
    lotteryId -= 1
    result = await lotteryContract.methods.viewLottery(lotteryId).call()
  }
  const userInfo = await lotteryContract.methods.viewUserInfoForLotteryId(account, lotteryId, 0, 1000).call();
  return {
    lottery: reverseLottery(result),
    user: reverseUserView(userInfo),
    lotteryId,
  }
}

export const claimTickets = async (lotteryContract, ticketIds, brackets, account, lotteryId) => {
  let _lotteryId
  if (lotteryId === undefined) {
    _lotteryId = await lotteryContract.methods.currentLotteryId().call();
  }
  const result = await lotteryContract.methods.claimTickets(lotteryId || _lotteryId, ticketIds, brackets).send({ 
    from: account,
    gasPrice: +gas >= 5000000000 ? +gas : +gas * 1.5,
   });
  return result;
}


// 根据Id查询个人彩票信息
export const checkLotteryDetailById = async (lotteryContract, account, lotteryId) => {
  const result = await lotteryContract.methods.viewUserInfoForLotteryId(account, lotteryId, 0, 1000).call();
  return reverseUserView(result);
}


export const checkTotalPlayers = async (lotteryContract, lotteryId) => {
  const result = await lotteryContract.methods.viewPlayers(lotteryId).call();
  return result
}


// 根据lotteryId检查中奖信息
export const getLastDrawedLottoryId = async (lotteryContract, account, allLotteryId) => {
  // let lotteryId = await lotteryContract.methods.currentLotteryId().call(); // 查询类方法调用call
  let lotteryId = allLotteryId
  let result = await lotteryContract.methods.viewLottery(lotteryId).call()
  if (Number(result.status) !== 3 && lotteryId > 1) {
    lotteryId -= 1
    result = await lotteryContract.methods.viewLottery(lotteryId).call()
  }
  const userInfo = await lotteryContract.methods.viewUserInfoForLotteryId(account, lotteryId, 0, 1000).call();
  return {
    lottery: reverseLottery(result),
    user: reverseUserView(userInfo),
    lotteryId,
  }
}


export const checkMyLotteryHistory = async (lotteryContract, account) => {
  const result = await lotteryContract.methods.viewUserHistoryInfo(account, 1000).call();
  return result;
}
