import { configureStore } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'
import { save, load } from 'redux-localstorage-simple'
import farmsReducer from './farms'
import poolsReducer from './pools'
import dreamPriceReducer from './dreamPrice'
import blockReducer from './block'
import lists from './lists/reducer'
import transactions from './transactions/reducer'
import swap from './swap/reducer'
import user from './user/reducer'
import mint from './mint/reducer'
import toasts from './toasts'
import burn from './burn/reducer'
import { getThemeCache } from '../utils/theme'
import application from './application/reducer'
import multicall from './multicall/reducer'
 
type MergedState = {
  user: {
    [key: string]: any
  }
  transactions: {
    [key: string]: any
  }
}
const PERSISTED_KEYS: string[] = ['user', 'transactions']
const loadedState = load({ states: PERSISTED_KEYS }) as MergedState
if (loadedState.user) {
  loadedState.user.userDarkMode = getThemeCache()
}


const store = configureStore({
  devTools: process &&  process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
  }),
  reducer: {
    block: blockReducer,
    dreamPrice: dreamPriceReducer,
    farms: farmsReducer,
    pools: poolsReducer,
    application,
    transactions,
    swap,
    lists,
    user,
    burn,
    mint,
    multicall,
    toasts
  },
  // middleware: [...getDefaultMiddleware({ serializableCheck: false,thunk: false }), save({ states: PERSISTED_KEYS })],
  // preloadedState: loadedState,
})
 
/**
 * @see https://redux-toolkit.js.org/usage/usage-with-typescript#getting-the-dispatch-type
 */

export type AppState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>()

export default store
