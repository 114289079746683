/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import BigNumber from 'bignumber.js'

type dreamPriceState = {
  price: BigNumber
}
const initialState:  dreamPriceState= { price: new BigNumber(0) }

export const dreamPriceSlice = createSlice({
  name: 'dreamPrice',
  initialState,
  reducers: {
    setIsDcoinKyuruoPrice: (state, action: PayloadAction<BigNumber>) => {
      state.price = action.payload
    },
  },
})

// Actions
export const { setIsDcoinKyuruoPrice } = dreamPriceSlice.actions

export default dreamPriceSlice.reducer



 


