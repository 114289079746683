/* eslint-disable react/no-unknown-property */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable prefer-const */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-cycle */
import {useState,useEffect,useCallback} from 'react'
import { Wrapper
} from 'views/Home/indexStyles'
import { getNFTsStakingForSynchronous, getNFTsHttp } from 'utils/utils'
import { useWeb3React } from '@web3-react/core'
import { useHistory } from "react-router-dom";
import { getNftStakingAddress,getPegasusNftAddress,getMDFNftAddress
} from 'utils/addressHelpers'
import useToast from 'hooks/useToast'
import _ from 'lodash'
import useWeb3 from 'hooks/useWeb3'
import { getNftStaking, getMDFNft, getPegasusNft  } from 'utils/change'
import { ButtonMenu, Button, ButtonMenuItem } from '@dreamweb/uikit'
import { Modal } from 'antd'
import { useUpdateEffect,useDebounceFn, useDebounceEffect} from 'ahooks'
import Menu from "./components/Menu";
import Tokens from './components/Tokens'
import CardBox from './Cardbox'

import { TokenText, Stake, HarvestModal, StyleContainer, Wrapper1 } from './indexStyles'

enum PoolType {
  SinglePegasus,
  SingleMDF,
  Mixed
}

interface MenuDataTypes {
  staked: boolean;
  pageType: number;
  selectType: string;
  status: boolean;
  searchValue: string;
}

const result = sessionStorage.getItem('gas') || 50000
let allDataG = []
let tabIndexG = 0
let NFTLenghtG = 0
export default function Index() {
  const history = useHistory()
  const { account, library } = useWeb3React()
  
  // const account = '0x62F7294C0DfE9882af4f5484668Fa3EF3eD62612'
  // const account = '0xfdE01097fd8b7d32215EFE40532e87Dda7a1E73C'
  // const account = '0xE1D28815B49d999A830BBAD89A70De2547CfA227'
  // const account = '0x0f05D32a3Df08c3BE919B4153A72A7E19Dc47e5A'
  // const account = '0x98F0d810E0CB56E78BF18e97442eF03798b69F82'
  // const account = '0x5B6597358fF2B58B495D6476e283e9e189E744FF'
  // const account = '0x0D6505Ad147572B7A7fF6683EC7ccea67d9D2D1A'
  // const account = '0xd6F4d8Fb13EF9635EB6febD8C0D7BEd967802335'
  // const account = '0xB1cC2Ab1cacA8B3AB1f05be2d96b89D0a66547Fe'
  // const account = '0x8b391F830326D3Eef0B3274eA2e3eA554cffa1ff'
  const [matches, setMatches] = useState(
    window.matchMedia('(min-width: 769px)').matches,
  );
  const web3 = useWeb3()
  const { toastError, toastSuccess, toastInfo, toastWarning } = useToast()
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [tabIndex, setTabIndex] = useState(new RegExp('/pegasus').test(window.location.href)?0:1)
  const [isAdd,setIsAdd] = useState(false)
  const [openHarvest, setOpenHarvest] = useState(false)
  const [arrApy,setArrApy]=useState<any>()
  const [selectType, setSelectType]=useState<any>(0)
  const [totalStaking,setTotalStaking]=useState<any>()
  const [arrEarend,setArrEarend]=useState<any>()
  const [isApproveNM, setIsApproveNM] = useState(false)
  const [arrMyStaked,setMyStaked]=useState<any>()
  const [allData,setAllData]=useState<any>([])
  const [allDataCopy,setAllDataCopy]=useState<any>([])
  const [singleIds,setSingleIds]=useState([])
  const [menuData, setMenuData] = useState<MenuDataTypes>()
  const [isStackToken, setIsStackToken] = useState(false)
  const [dataLoading, setDataLoading] = useState(true)
  const [isLoading,setIsloading] = useState(false)
  const [idvs,setIdvs]=useState<any>()
  const [allIdvs,setAllIdvs]=useState<any>()
  const [total,setTotal]=useState(0)
  const [alltotal,setAllTotal]=useState(0)
  const [NFTLenght,setNFTLenght]=useState(0)
  const [lodaing, setLodaing] = useState(false)
  const [lodaing1, setLodaing1] = useState(false)
  const [dataLodaing, setDataLodaing] = useState(true)
  const [isharvest,setHarvest]=useState<any>(false)
  const [isharvest1,setHarvest1]=useState<any>(false)
  const [timer,setTimer]=useState(null)
  const arrPegasusName=['Pegasus Unicorn',
    'Pegasus Normal',
    'Pegasus Bronze',
    'Pegasus Silver',
    'Pegasus Gold']
  const arrMDFName = ['MDF Normal',
    'MDF Premium']
  // PEGASUS-NFT
  const pegasusNftContract = getPegasusNft(web3)
  // MDF-NFT
  const mdfNftContract = getMDFNft(web3)
  
  // NFTStaking
  const NftStakingContract = getNftStaking(web3)


  // 判断是否授权
  const isApprove = async () => { 
    if(tabIndex===0){
      // console.log('isApprove')
      const res = await pegasusNftContract.methods.isApprovedForAll(account, getNftStakingAddress()).call()
      // console.log('isApprove', res)
      setIsApproveNM(res)
    } else {
      // console.log('isApproveMDFNftContract')
      const res = await mdfNftContract.methods.isApprovedForAll(account, getNftStakingAddress()).call()
      // console.log('isApproveMDFNftContract', res)
      setIsApproveNM(res)
    }
  }
  const nftApprove = async () => { 
    try {
      if(tabIndex===0){
        setLodaing(true)
      }else {
        setLodaing1(true)
      }
      if(tabIndex===0){
        // console.log('nftContract', pegasusNftContract.methods)
        await pegasusNftContract.methods.setApprovalForAll(getNftStakingAddress(), true).send({ from: account,
          gasPrice: +result >= 5000000000 ? +result : +result * 1.5, })
        // console.log('nftContract', pegasusNftContract.methods)

      } else {
        // console.log('MDFNftContract', mdfNftContract.methods)
        await mdfNftContract.methods.setApprovalForAll(getNftStakingAddress(), true).send({ from: account,
          gasPrice: +result >= 5000000000 ? +result : +result * 1.5, })
        // console.log('MDFNftContract', mdfNftContract.methods)
      }
      toastSuccess('Approve successful')
      if(tabIndex===0){
        setLodaing(false)
      }else {
        setLodaing1(false)
      }
    } catch (error:any) {
      toastWarning(error?.message ? error?.message : 'Approve failed')
      if(tabIndex===0){
        setLodaing(false)
      }else {
        setLodaing1(false)
      }
    }
    isApprove()
  }

  
  // menu状态
  const getMenuData = (data:MenuDataTypes) => {
    setMenuData(data)
  }

  
  useEffect(() => { 
    if(account) {
      isApprove()
    }
  }, [tabIndex, account])
  

  useEffect(() => {
    window
      .matchMedia('(min-width: 769px)')
      .addEventListener('change', (e) => {
        setMatches(e.matches)
      });
  }, []);

  const handleClick = async (index: number) => {
    if(index===0) {
      history.push('/staking/pegasus')
    }else {
      history.push('/staking/mdf')
    }
  }

  
  useEffect(() => { 
    setAllData([])
    setAllDataCopy([])
    const unListen = history.listen((e) => { 
      if(new RegExp('/pegasus').test(e.pathname)) {
        if(tabIndexG!==0) {
          setDataLoading(true)
          setAllData([])
          setAllDataCopy([])
          getAllData(NFTLenght)
        }
        tabIndexG=0
        setTabIndex(0)
      } else {
        if(tabIndexG!==1) {
          setDataLoading(true)
          setAllData([])
          setAllDataCopy([])
          getAllData(NFTLenght)
        }
        tabIndexG=1
        setTabIndex(1)
      }
     })
    return ()=>{
      unListen()
    }
  }, [account])

  
  // 获取当前展示
  useEffect(() => { 
    if(account) {
      getUserTokens()
      // getStakingTokens()
    }
  }, [account,tabIndex])

  
  // 获取全部NFT数据 view
  const getUserTokens = async () => { 
    setDataLodaing(true)
    try {
      setAllTotal(0)
      setAllIdvs({
        ids: [],
        lvs: []
      })
      let term1 = {
        ids: [],
        lvs: []
      }
      if(tabIndex===0) {
        console.log(86)
        // term1 = await getNFTsStakingForSynchronous(pegasusNftContract, account)  
        const balance = await pegasusNftContract.methods.balanceOf(account).call()
        console.log(497, balance)
        term1 =  await getNFTsHttp(account, getPegasusNftAddress(), balance, arrPegasusName)
        console.log(87, term1)
        setDataLodaing(false)
        // term1 = await getNFTsStakingForSynchronous(pegasusNftContract, account)   
        
        // term1 = await pegasusNftContract.methods.getUserTokens(account,500,1).call()
      } else {
        // term1 = await getNFTsStakingForSynchronous(mdfNftContract, account)   
        // term1 = await mdfNftContract.methods.getUserTokens(account,500,1).call()
        const balance = await mdfNftContract.methods.balanceOf(account).call()
        // setTotal(balance)  
        // term1 = await getNFTsNoSearch(MDFNftContract, account, currentPage)   
        term1 =  await getNFTsHttp(account, getMDFNftAddress(), balance, arrMDFName)
        setDataLodaing(false)
      }
      const lvs = term1?.lvs; const ids= term1?.ids
      setAllTotal(ids?.length)
      
      // console.log("当前展示页数据", {
      //   ids: [...ids],
      //   lvs: [...lvs]
      // })
      setAllIdvs({
        ids: [...ids],
        lvs: [...lvs]
      })
    } catch (error) {
      setDataLodaing(false)
      // console.log(error)
      // getUserTokens()
    }
    
  }


  // 获取Total Staking
  const getTotalStaking = async () => { 
    setTotalStaking(0)
    try {
      if(tabIndex===0) {
        // console.log('获取Total Staking Single Pegasus Pool 的数量')
        const view = await NftStakingContract.methods.singlePegasusPools(0).call()
        // console.log('Total Staking Single Pegasus Pool 的数量', view)
        setTotalStaking(view?.totalStake)
      } else {
        // console.log('获取Total Staking 获取 single MDF Pool 的数量 view')
        const view = await NftStakingContract.methods.singleMDFPools(0).call()
        // console.log('Total Staking single MDF Pool 的数量 view', view)
        setTotalStaking(view?.totalStake)
      }
    } catch (error) {
      // console.log(error)
    }
  }


  
  // 获取 Single Pegasus Pool 的数量 view
  const singlePegasusIdx = async () => {
    try {
      setDataLoading(true)
      if(tabIndex===0) {
        // console.log('获取 Single Pegasus Pool 的数量')
        const view = await NftStakingContract.methods.singlePegasusIdx().call()
        // console.log('Single Pegasus Pool 的数量', view)
        NFTLenghtG=view
        await setNFTLenght(view)
        await getAllData(NFTLenghtG)
      } else {
        // console.log('获取 single MDF Pool 的数量 view')
        const view = await NftStakingContract.methods.singleMDFIdx().call()
        // console.log('single MDF Pool 的数量 view', view)
        NFTLenghtG=view
        await setNFTLenght(view)
        await getAllData(NFTLenghtG)
      }
      setDataLoading(false)
    } catch (error) {
      setDataLoading(false)
      // console.log(error)
    }
  }

  // 获取所有数据 
  const getAllData = async (view) => { 
    if(!+view){
      return
    }
    try {
      const newArrAllData = await Promise.all(
        [...new Array(+view)].map(async (item,index) => {
          let newApy=0; let newEarend=0; let nweStaked=0; let newTotal = 0; let newtotalStaking = 0; 

          if(account) {
            nweStaked = await NftStakingContract.methods
            .getTokenLength(new RegExp('/pegasus').test(window.location.href)?0:1, index, account).call()
            newApy = await NftStakingContract.methods
            .getNowAPY(new RegExp('/pegasus').test(window.location.href)?0:1, index, account).call()
            if((new RegExp('/pegasus').test(window.location.href)?0:1)===0){
              newTotal = await pegasusNftContract.methods
              .userLevelCount(account,index).call()
            } else {
              newTotal = await mdfNftContract.methods
              .userLevelCount(account,index).call()
            }
          } else {
            newApy = await NftStakingContract.methods
            .getNowAPY(tabIndexG, index, '0x5ED18b121382Ee7a1146d2801e54865d0e4ef825').call()
          }
          if((new RegExp('/pegasus').test(window.location.href)?0:1)===0){
            newtotalStaking = await NftStakingContract.methods.singlePegasusPools(index).call()
          } else {
            newtotalStaking = await NftStakingContract.methods.singleMDFPools(index).call()
          }
          return new Promise((resolve) => {
            resolve({
              apy:newApy,
              earend: newEarend,
              staked: nweStaked,
              id: index,
              total: newTotal,
              totalStaking: newtotalStaking,
              tabIndex: new RegExp('/pegasus').test(window.location.href)?0:1,
            })
          })
        })
      )
      // console.log(468, newArrAllData, !_.isEqual(newArrAllData,allDataG))
      if(!_.isEqual(newArrAllData,allDataG)) {
        setAllData(newArrAllData)
        allDataG=newArrAllData
        setAllDataCopy(changeAllData(newArrAllData))
      }
      
    } catch (error) {
      // setDataLodaing(false)
    }
    
  }

  useEffect(() => { 
    allDataG = allData
  }, [allData])
  useEffect(() => { 
    NFTLenghtG=NFTLenght
   }, [NFTLenght])

  const { run: runSinglePegasusIdx } = useDebounceFn(
    () => {
      singlePegasusIdx();
    },
    {
      wait: 300,
    },
  );


  useEffect(() => { 
    runSinglePegasusIdx()
  }, [tabIndex])

  useEffect(() => { 
    // getAllData(NFTLenght)
   }, [account, menuData])

   
   
   useDebounceEffect(() => { 
    getAllData(NFTLenghtG)
    let newTimer
    if(NFTLenght) {
      clearInterval(timer)
      newTimer = setInterval(() => { 
        // console.log(525, allDataG, tabIndexG, NFTLenght)
        getAllData(NFTLenghtG)
      }, 3000)
      setTimer(newTimer)
    }
    // console.log(535, newTimer)
    return () => { 
      // console.log(537, newTimer)
      clearTimeout(newTimer)
    }
  }, [NFTLenght, account, NFTLenghtG], {
    wait: 500,
  })

  const init = async () => { 
    getTotalStaking()
  }

  useEffect(() => { 
    // console.log(276)
    // init()
    tabIndexG=tabIndex
  },[tabIndex])

  // 质押 Pegasus 的 NFT do
  const stakePegasus = async (nftIDs: Array<number|string>) => {
    try {
      // console.log('获取 APY  奖励')
      const view = await NftStakingContract.methods.stakePegasus(tabIndex, nftIDs).send({
        from: account, 
        gasPrice: +result >= 5000000000 ? +result : +result * 1.5,
      })
      // console.log('APY 奖励', view)
    } catch (error) {
      // console.log(error)
    }
  }

  async function harvest(v) {
    try {
      if(tabIndex===0){
        setHarvest(true)
      } else {
        setHarvest1(true)
      }
      // console.log('harvest')
      const view = await NftStakingContract.methods.harvest(tabIndex, v).send({ from: account,
        gasPrice: +result >= 5000000000 ? +result : +result * 1.5, })
      // console.log('harvest', view)
      if(tabIndex===0){
        setHarvest(false)
      } else {
        setHarvest1(false)
      }
    } catch (error) {
      // console.log(error)
      if(tabIndex===0){
        setHarvest(false)
      } else {
        setHarvest1(false)
      }
    }

  }

  // setIsUnstake
  const unstake = (v) => { 
    setSelectType(v)
  }


  
  // 质押  stake
  const deposit = async (v) => { 
    try {
      setLodaing(true)
      if(tabIndex===0) {
        // console.log(`stakePegasus(${selectType},[${[...idvs?.ids]?.splice(0,v||0)}])`,[...idvs?.ids]?.splice(0,v||0))
        await NftStakingContract.methods.stakePegasus(selectType,idvs?.ids?.splice(0,v||0)).send({
          from: account,
          gasPrice: +result >= 5000000000 ? +result : +result * 1.5,
        })
      } else {
        // console.log(`MDF(${selectType},[${[...idvs?.ids]?.splice(0,v||0)}])`,selectType,[...idvs?.ids]?.splice(0,v||0))
        await NftStakingContract.methods.stakeMDF(selectType,[...idvs?.ids]?.splice(0,v||0)).send({
          from: account,
          gasPrice: +result >= 5000000000 ? +result : +result * 1.5,
        })
      }
      toastSuccess('Stake successful')
      getUserTokens()
      setIsStackToken(false)
      getAllData(NFTLenghtG)
    } catch (error:any) {
      setLodaing(false)
      toastWarning(error?.message ? error?.message : 'Stake failed')
    }
    setIsStackToken(false)
    getUserTokens()
    getAllData(NFTLenghtG)
    setLodaing(false)
  }

  
  const changeAllData = useCallback((newArrAllData) => {
    // console.log('menuData', menuData, newArrAllData);

    let newAllData = [...newArrAllData]

    if(menuData?.selectType==='APY') {
      newAllData.sort(function(a,b){ 
        // console.log(602, a)
        return b?.apy - a?.apy
       })
    }
    if(menuData?.selectType==="Staked") {
      newAllData.sort(function(a,b){ 
        return b?.staked?.[0] - a?.staked?.[0]
       })
    }
    if(menuData?.selectType==="Level") {
      newAllData.sort(function(a,b){ 
        // console.log(613, b)
        return a?.id - b?.id
       })
    }
    if(menuData?.staked) {
      newAllData = newAllData.filter((e) => { 
        return !!+e?.staked?.[0]
       })
    }

    return newAllData
  }, [menuData, tabIndex])

  useUpdateEffect(() => {
    setAllDataCopy(changeAllData(allData))
  }, [menuData])


    
  // 取回 Unstake
  const withdraw = async (v) => { 
    const arrv = [...new Array(+v)]?.map((item,index) => { 
      return 0
     })
    // console.log('取回参数',singleIds,arrv)
    try {
      setLodaing(true)
      if(tabIndex===0) {
        // console.log('withdrawPegasus取回参数', selectType,[...singleIds]?.splice(0,v), arrv)
        const state = await NftStakingContract.methods.withdrawPegasus(selectType,[...singleIds]?.splice(0,v),arrv).send({
          from: account, 
          gasPrice: +result >= 5000000000 ? +result : +result * 1.5,
        })
      } else {
        // console.log('withdrawMDF取回参数', selectType,[...singleIds]?.splice(0,v), arrv)
        await NftStakingContract.methods.withdrawMDF(selectType,[...singleIds]?.splice(0,v),arrv).send({
          from: account, 
          gasPrice: +result >= 5000000000 ? +result : +result * 1.5,
        })
      }
      toastSuccess('Unstake successful')
      getAllData(NFTLenghtG)
    } catch (error:any) {
      setLodaing(false)
      toastWarning(error?.message ? error?.message : 'Unstake failed')
    }
    getAllData(NFTLenghtG)
    setLodaing(false)
  }

  return ( 
    <Wrapper isMobile={matches}>
      <main className='roll'>
        <div className='main'>
          <TokenText isMobile={matches}>
            <main>
              <div className='main'>
                <div className='module1'>
                  <div className='text1'>Single Staking</div>
                  <div className='text2-box'>
                    <div className='text2-1'>Stake Nft to earn tokens</div>
                  </div>
                </div>
              </div>
            </main>
          </TokenText>
          <Wrapper1 style={{justifyContent: matches ? 'center' : 'center', marginTop: '45px'}}>
            <StyleContainer isMobile={matches}  style={{marginTop: matches? '0rem' : '60px', width: matches ? '400px' : '400px' }}>
              <ButtonMenu style={{ width: '100%', height: '41px', backgroundColor: '#232323', borderRadius: '9px' }} activeIndex={tabIndex} onItemClick={handleClick} scale="sm" >
                <ButtonMenuItem style={{ width: '50%', height: '41px',backgroundColor: tabIndex === 0 ? '#fff': '', borderRadius: '8px', color: tabIndex === 0 ? '#000': '#8A8A8A'}}>Pegasus Staking</ButtonMenuItem>
                <ButtonMenuItem style={{ width: '50%', height: '41px',backgroundColor: tabIndex === 1 ? '#fff': '', borderRadius: '8px', color: tabIndex === 1 ? '#000': '#8A8A8A'}}>MDF Staking</ButtonMenuItem>
              </ButtonMenu>
            </StyleContainer>
          </Wrapper1>
          
          <Menu changeData={getMenuData} isFarms={false}/>
          <Stake isMobile={matches}>
            <main>
              <div className='main'>
                {
              // dataLoading
                  dataLodaing ? 
                  <div style={{width:'100%',height:'400px',display:'flex',justifyContent:'center',alignItems:'center'}}>
                      loading
                  </div> : <>
                  {
                    (allDataCopy?.length===5&&tabIndex===0)||(allDataCopy?.length===2&&tabIndex===1) ? allDataCopy?.map((item) => { 
                      return (
                        <CardBox 
                          item={item} matches={matches} tabIndex={tabIndex} 
                          NftStakingContract={NftStakingContract}
                          pegasusNftContract={pegasusNftContract}
                          mdfNftContract={mdfNftContract}
                          account={account} isApproveNM={isApproveNM} 
                          nftApprove={nftApprove} getAllData={getAllData}
                          tabIndexG={tabIndexG}
                          lodaing={tabIndex===0?lodaing:lodaing1}
                        />
                       )
                    }) : 
                    <div style={{width:'100%',height:'400px',display:'flex',justifyContent:'center',alignItems:'center'}}>
                        No Data
                    </div>
                  }
                  </>
                }
              </div>
            </main>
          </Stake>
          
          <Modal className='buy-tickets-modal' title={null} footer={null} open={openHarvest} closable={false}>
              <HarvestModal isMobile={matches}>
                <div className='title'>
                  Harvest
                </div>
                <div className='token'>
                  <div>Token:</div>
                  <div>123.4567</div>
                </div>
                <div className='btn btn-change'>
                  <Button className='Cancle'>Cancel</Button>
                  <Button >Buy Now</Button>
                </div>
              </HarvestModal>
          </Modal>
        </div>
      </main>        
      <Modal centered className='buy-tickets-modal' title={null} footer={null} open={isStackToken} closable={false}>
        <Tokens tabIndex={tabIndex} staked={total} idx={selectType} isAdd={isAdd} onDismiss={()=>setIsStackToken(false)} 
          deposit={async (v)=>{
            await deposit(v)
            // 更新
          }}
          withdraw={async (v)=>{
            await withdraw(v)
            // 更新
          }}/>
      </Modal>
    </Wrapper>
  )
}
