import styled from 'styled-components';

const StyleBase = styled.div``

const StyledTitle = styled(StyleBase)<{isMobile?: boolean }>`
    margin: 10px 0;
    font-size: ${({isMobile}) => isMobile ?  '1rem' : '20px' };
    font-weight: normal;
    line-height: 20px;
    color: #F8E513;
    text-align: center;
`
const StyledWinNumsStyle = styled(StyleBase)<{isMobile?: boolean }>`
    display: flex;
    justify-content: center;
    padding-bottom: 32px;
    div{
        .square{
            display: inline-block;
            width: ${({isMobile}) => isMobile ?  '2rem' : '50px' };
            height: ${({isMobile}) => isMobile ?  '2rem' : '50px' };
            margin-right:12px;
            opacity: 1;
            border-radius: 10px;
            font-size:  ${({isMobile}) => isMobile ?  '1rem' : '24px' };
            font-weight: bold;
            color: #000000;
            line-height: ${({isMobile}) => isMobile ?  '2rem' : '50px' };
            text-align: center;
        }
        span:nth-of-type(1) {
            background: #A8FF89;
        }
        span:nth-of-type(2) {
            background: #E7FF89;
        }
        span:nth-of-type(3) {
            background: #FFC189;
        }
        span:nth-of-type(4) {
            background: #89FEFF;
        }
        span:nth-of-type(5) {
            background: #898CFF;
        }
        span:nth-of-type(6) {
            background: #FF89F3;
            margin-right: 0;
        }
    }
`
const StyledYourTickets = styled(StyleBase)<{isMobile?: boolean }>`
    margin-bottom: 20px;
    color: white;
    .title{
        margin-bottom: 20px;
        text-align:center;
        font-size: ${({isMobile}) => isMobile ?  '1rem' : '20px' };
        font-weight: 400;
    }
    .total_winning_tickets{
        display: flex;
        text-align: center;
        font-size: ${({isMobile}) => isMobile ?  '1rem' : '22px' };
        font-weight: bold;
        div{
            width: 50%;
            .sub-title{
                margin-bottom: 12px;
                font-weight: normal;
                font-size: ${({isMobile}) => isMobile ?  '0.8rem' : '16px' };
            }
            .number{
                font-size: ${({isMobile}) => isMobile ?  '0.8rem' : '16px' };
            }
        }
    }
    .total_winning_tickets-next-draw{
        display: flex;
        // justify-content: center;
        // text-align: center;
        font-size: ${({isMobile}) => isMobile ?  '1rem' : '22px' };
        font-weight: bold;
        .sub-title{
            color: #FFF;
            font-size: 20px;
        }
    }
`
const StyledTicketsNumRow = styled(StyleBase)<{isMobile?: boolean }>`
    padding-right: 12px;
    .index {
        margin-bottom: 6px;
        font-size: 16px;
        line-height: 20px;
        color: white;
    }
    .num-arr {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        height: 40px;
        margin-bottom: 20px;
        border: 1px solid white;
        border-radius: 15px;
        color:white;
        font-size: ${({isMobile}) => isMobile ?  '1rem' : '16px' };
    }
`
const StyledTicketsBox = styled(StyleBase)<{isMobile?: boolean }>`
    max-height:  ${({isMobile}) => isMobile ?  '15rem' : '420px' };
    overflow-y: auto;
    ::-webkit-scrollbar-thumb {
        background-color: #fff !important;
    }
    ::-webkit-scrollbar {
        width: 10px;
        height: 10px;
        border-radius: 5px;
        // border: 1px solid #fff !important;
        // box-shadow: 2px 2px 5px #fff;
    }
    ::-webkit-scrollbar-track {
        background: #000 !important;
        border-radius: 2px;
    }
`
 
export { StyledTitle, StyledWinNumsStyle, StyledYourTickets, StyledTicketsNumRow, StyledTicketsBox };