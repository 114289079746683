/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-cycle */
import React,{useEffect, useState} from 'react'
import { useHistory } from 'react-router-dom'
import Single from './Single'
import Minxed from './Minxed'

export default function Index() {
  const history = useHistory()
  const [isMixed,setIsMixed] = useState(!!new RegExp('/mixed').test(window.location.hash))

  useEffect(() => { 
    const unListen = history.listen((e) => { 
      if(new RegExp('/mixed').test(e.pathname) ) {
        setIsMixed(true)
      } else {
        setIsMixed(false)
      }
     })
    return ()=>{
      unListen()
    }
  }, [])
  return (
    <div>
      {
        isMixed ?
        <Minxed/> :
        <Single/>
      }
    </div>
  )
}
