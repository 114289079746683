/* eslint-disable import/prefer-default-export */
import Web3 from 'web3'
import web3NoAccount from 'utils/web3'
import lotteryAbi from 'config/abi/lottery.json'
import routerAbi from 'config/abi/IUniswapV2Router02.json'
import bep20Abi from 'config/abi/erc20.json'
import lpTokenAbi from 'config/abi/lpToken.json'
import { poolsConfig } from 'config/constants'
import dreamPoolAbi from 'config/abi/dreamPool.json'
import dreamFramAbi from 'config/abi/MasterChefABI.json'
import { AbiItem } from 'web3-utils'
// Addresses
import {
  getLotteryAddress,
  getAddress,
  getRouterAddress
} from 'utils/addressHelpers'

const getContract = (abi: any, address: string, web3?: Web3) => {
  const _web3 = web3 ?? web3NoAccount
  return new _web3.eth.Contract(abi as unknown as AbiItem, address)
}
export const getDcoinKyuryoPoolContract = (id: number, web3?: Web3) => {
  const config = poolsConfig.find((pool) => pool.sousId === id)
  return getContract(dreamPoolAbi, getAddress(config.contractAddress), web3)
}


export const getLotteryContract = (web3?: Web3) => {
  return getContract(lotteryAbi, getLotteryAddress(), web3)
}

export const getRouterContract = (web3?: Web3) => {
  return getContract(routerAbi, getRouterAddress(), web3)
}

export const getDcoinKyuryoFarmContract = (address: string, web3?: Web3) => {
  return getContract(dreamFramAbi, address, web3)
}


export const getBep20Contract = (address: string, web3?: Web3) => {
  return getContract(bep20Abi, address, web3)
}

export const getERC721AContract = (address: string, web3?: Web3) => {
  return getContract(bep20Abi, address, web3)
}

export const getLpContract = (address: string, web3?: Web3) => {
  return getContract(lpTokenAbi, address, web3)
}



