import { async } from 'q';
/* eslint-disable import/prefer-default-export */
import Web3 from 'web3'
import web3NoAccount from 'utils/web3'
import changeApi from 'config/abi/change.json'
import nftApi from 'config/abi/nft.json'
import Exchange from 'config/abi/Exchange.json'
import DFNFTchange from 'config/abi/DFNFT.json'
import NFTStakingApi from 'config/abi/NFTStaking.json'
import PokemonNFTStakingApi from 'config/abi/pokemonNFTStaking.json'
import farmApi from 'config/abi/farm.json'
import liquidity from 'config/abi/Liquidity.json'
import { AbiItem } from 'web3-utils'
import { ethers } from 'ethers'
import { Contract, Provider } from 'ethers-multicall'
// Addresses
import {
  getChangeAddress,
  getChangeDAddress,
  getChangeYAddress,
  getNftAddress,
  getFarmAddress,
  getPoolAddress,
  getLpAddress,
  getPegasusNftAddress,
  getExchangeAddress,
  getPokemonNftAddress,
  getMDFNftAddress,
  getNftStakingAddress,
  getPokemonNFTStakingAddress,
} from 'utils/addressHelpers'
import getNodeUrl from 'utils/getRpcUrl'


export const ethcallProviderFunction = async (func) => { 
  const provider = new ethers.providers.JsonRpcProvider(getNodeUrl());
  const ethcallProvider = new Provider(provider);
  await ethcallProvider.init();
  const view = await ethcallProvider.all(func)
  return view
}
// const _web3 = new Web3(getNodeUrl())
const getContract = (abi: any, address: string, web3?: Web3) => {
  const _web3 = web3 ?? web3NoAccount
  return new _web3.eth.Contract(abi as unknown as AbiItem, address)
}

const getMContract = (abi: any, address: string) => {
  return new Contract(
    address,
    abi,
  )
}

const getNDTidContract = (abi: any, address: string) => {
  return new Contract(
    address,
    abi,
  )
}

export const getNDTid = (v: number) => {
  return getNDTidContract(nftApi, v === 0 ? getPegasusNftAddress() : getMDFNftAddress())
}

export const getMChange = () => {
  return getMContract(changeApi, getChangeDAddress())
}

export const getChange = (web3?: Web3) => {
  return getContract(changeApi, getChangeAddress(), web3)
}


export const getChangeD = (web3?: Web3) => {
  return getContract(changeApi, getChangeDAddress(), web3)
}

export const getChangeY = (web3?: Web3) => {
  return getContract(changeApi, getChangeYAddress(), web3)
}


export const getNft = (web3?: Web3) => {
  return getContract(nftApi, getNftAddress(), web3)
}


export const getPegasusNft = (web3?: Web3) => {
  return getContract(nftApi, getPegasusNftAddress(), web3)
}


export const getExchange = (web3?: Web3) => {
  return getContract(Exchange, getExchangeAddress(), web3)
}

export const getPokemonNft = (web3?: Web3) => {
  return getContract(DFNFTchange, getPokemonNftAddress(), web3)
}

export const getSell = (web3: Web3, address: string) => {
  return getContract(DFNFTchange, address, web3)
}

export const getMarketMDF = (web3?: Web3) => {
  return getContract(DFNFTchange, getMDFNftAddress(), web3)
}
export const getMarketNft = (web3?: Web3) => {
  return getContract(DFNFTchange, getNftAddress(), web3)
}


export const getMDFNft = (web3?: Web3) => {
  return getContract(nftApi, getMDFNftAddress(), web3)
}


export const getNftStaking = (web3?: Web3) => {
  return getContract(NFTStakingApi, getNftStakingAddress(), web3)
}

export const getPokemonNFTStaking = (web3?: Web3) => {
  return getContract(PokemonNFTStakingApi, getPokemonNFTStakingAddress(), web3)
}



export const getFarm = (web3?: Web3) => {
  return getContract(farmApi, getFarmAddress(), web3)
}

export const getPool = (web3?: Web3) => {
  return getContract(farmApi, getPoolAddress(), web3)
}


export const getLp = (web3?: Web3) => {
  return getContract(liquidity, getLpAddress(), web3)
}


