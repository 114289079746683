/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react'
import { useTranslation } from "react-i18next";
import { useMatchBreakpoints } from '@dreamweb/uikit'
import {  formatDecimal } from 'utils/math';
import { usePriceDreamBusd } from 'state/hooks'
import { getViewLottery } from 'utils/lotteryUtils'
import { useLottery } from 'hooks/useContract'
import { StyleRecordContent, StyledContainer } from './LotteryDetailStyle'

type Arritem = {
    title: string | number,
    sum:string | number,
    price:string | number,
    winners: string | number,
    each: string | number
}

type propsType = {
    rewards: Array<Arritem>,
    source?: string,
    burnDcoin?: string | number,
    burnKyuryo?: string | number
}

const LotteryDetail  = (props: propsType) => {
    const { t } = useTranslation();
    const { isSm, isXs, isLg, isMd,isXl } = useMatchBreakpoints();
    const isMobile = isXs || isSm || isLg || isMd;
    const { rewards, source, burnDcoin, burnKyuryo } = props;
    const DreamPriceUsd = usePriceDreamBusd();    
    const lotteryContract = useLottery()


    const [isDcoinKyuruoPrice, setIsDcoinKyuruoPrice] = useState(0)

    const getDcoinKyuryoPrice = async () => { 
        const info = await getViewLottery(lotteryContract);
        const { priceTicketInDcoin, priceTicketInKyuryo } = info;
        setIsDcoinKyuruoPrice(Number(localStorage.getItem('isDcoinKyuruo')==='0'?priceTicketInDcoin:priceTicketInKyuryo));
     }

    useEffect(() => { 
        getDcoinKyuryoPrice()
    }, [])

    
    return (
        <StyledContainer isMobile={isMobile}>
            {
                ( !['lotteryDetail', 'allhistory', 'postDraw'].includes(source)) &&
                <p className='info'>{t("Match the winning number in the same order to share prizes. Current prizes up for grabs:")}</p>
            }
            <StyleRecordContent isMobile={isMobile}>
                {
                    rewards&& [...rewards].reverse().map((item,index) => {
                        if(+index===0||+index===1) {
                        return (
                            <div key={item.title} className="cell">
                                <div className="title">{item.title}</div>
                                <div className="sum">
                                    {
                                        +index===1?'Pegasus Silver * 1':'Pegasus Gold * 1'
                                    }
                                </div>
                                { (source !== 'nextdraw' && +item.winners > 0) && 
                                <div className="price">
                                    {
                                        +index===1?'Pegasus Silver * 1':'Pegasus Gold * 1'
                                    } each
                                </div>}
                                { source !== 'nextdraw' && <div className="winners">{item.winners} {t("Winning Tickets")}</div>}
                            </div>
                        )
                        }
                        if(+index!==5) {
                            return (
                                <div key={item.title} className="cell">
                                    <div className="title">{item.title}</div>
                                    <div className="sum">{item.sum?.[0]} DCOIN</div>
                                    <div className="sum">{item.sum?.[1]} KYURYO</div>
                                    { (source !== 'nextdraw' && +item.winners > 0) && <div className="price">{item.each?.[0]} DCOIN each</div>}
                                    { (source !== 'nextdraw' && +item.winners > 0) && <div className="price">{item.each?.[1]} KYURYO each</div>}
                                    { source !== 'nextdraw' && <div className="winners">{item.winners} {t("Winning Tickets")}</div>}
                                </div>
                            )
                            }
                    })
                }
                <div key="burn" className="cell">
                    <div className='title burn'>{t("Burn")} </div>
                    <div className="sum"> {formatDecimal(burnDcoin, 4)} DCOIN</div>
                    <div className="sum"> {formatDecimal(burnKyuryo, 4)} KYURYO</div>
                </div>
            </StyleRecordContent>
        </StyledContainer>
    )
}
export default React.memo(LotteryDetail);
 