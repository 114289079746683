import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import ApplicationUpdater from './state/application/updater'
import ListsUpdater from './state/lists/updater'
import MulticallUpdater from './state/multicall/updater'
import TransactionUpdater from './state/transactions/updater'
import Providers from './Providers'
import ToastListener from './components/ToastListener'

ReactDOM.render(
  <React.StrictMode>
    <Providers>
      <>
        <MulticallUpdater />
        <ApplicationUpdater />
        {/* <ListsUpdater />
        <TransactionUpdater /> */}
        <ToastListener />
      </>
      <App />
    </Providers>
  </React.StrictMode>,
  document.getElementById('root'),
)
