import styled from 'styled-components';

const StyleBase = styled.div``

const StyledCard = styled(StyleBase)<{isMobile?: boolean }>`
    display: flex;
    align-items: center;
    padding: 12px;
    width: ${({isMobile}) => isMobile ?  '100%' : '397px' };
    height: 170px;
    box-sizing: border-box;
    background: #232323;
    opacity: 1;
    border-radius: 11px;
    margin-bottom: 18px;
`
const StyledCircle = styled(StyleBase)<{isMobile?: boolean }>`
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    width: 66px;
    height: 66px;
    padding-top: 12px;
    border-radius: 50%;
    background: #FFFFFF;
    color: #000000;
    font-size: ${({isMobile}) => isMobile ?  '15px' : '20px' };
    font-family: Segoe UI-Bold, Segoe UI;
    font-weight: bold;
    color: #000000;
    line-height: 21px;
`
const StyledContent = styled(StyleBase)<{isMobile?: boolean }>`
    width: calc(100% - 78px);
    padding-top: 20px;
    padding-left: 16px;
`
const StyledTitle = styled(StyleBase)<{isMobile?: boolean }>`
    font-family: Nirmala UI-Bold,Nirmala UI;
    margin-bottom: 16px;
    font-size: ${({isMobile}) => isMobile ?  '1rem' : '24px' };
    color:white;
`
const StyledDesc = styled(StyleBase)<{isMobile?: boolean }>`
    display: -webkit-box;
    -webkit-box-orient: vertical;
    font-size: 16px;
    line-height: 22px;
    color: #FFFFFF;
`
export { StyledCard, StyledCircle, StyledContent, StyledTitle, StyledDesc}