import React, { ReactNode, useState } from 'react'
import styled from 'styled-components'
import { Heading, IconButton, Text, Flex, useModal, CogIcon, HistoryIcon } from '@dreamweb/uikit'
import useI18n from 'hooks/useI18n'
import {Modal} from 'antd'
import SettingsModal from './SettingsModal'
import RecentTransactionsModal from './RecentTransactionsModal'

interface PageHeaderProps {
  title: ReactNode
  description?: ReactNode
  children?: ReactNode
}

const StyledPageHeader = styled.div`
  // border-bottom: 1px solid ${({ theme }) => theme.colors.borderColor};
  /* border: 1px solid #2D3339; */
  padding: 24px;
`

const Details = styled.div`
  flex: 1;
`

const PageHeader = ({ title, description, children }: PageHeaderProps) => {
  const TranslateString = useI18n()
  const [openSettings, setOpenSettings] = useState(false)
  const [openRecent, setOpenRecent] = useState(false)
  const [onPresentSettings] = useModal(<SettingsModal translateString={TranslateString} />)
  const [onPresentRecentTransactions] = useModal(<RecentTransactionsModal translateString={TranslateString} />)


  return (
    <StyledPageHeader>
      <Flex alignItems="center">
        <Details>
          <Heading mb="8px" style={{ fontSize: '26px' }}>
            {title}
          </Heading>
          {description && (
            <Text color="text" fontSize="16px" style={{ color: '#fff' }}>
              {description}
            </Text>
          )}
        </Details>
        <IconButton variant="text" onClick={()=>{setOpenSettings(true)}} title={TranslateString(1200, 'Settings')}>
          <CogIcon width="24px" color="#ffffff" />
        </IconButton>

        
        <Modal centered className='buy-tickets-modal' title={null} footer={null} open={openSettings} closable={false}>
          <SettingsModal translateString={TranslateString} onDismiss={()=>{setOpenSettings(false)}}/>
        </Modal>
        
        <IconButton
          variant="text"
          onClick={()=>{setOpenRecent(true)}}
          title={TranslateString(1202, 'Recent transactions')}
        >
          <HistoryIcon width="24px" color="#ffffff" />
        </IconButton>
        
        <Modal centered className='buy-tickets-modal' title={null} footer={null} open={openRecent} closable={false}>
          <RecentTransactionsModal translateString={TranslateString}  onDismiss={()=>{setOpenRecent(false)}} />
        </Modal>
      </Flex>
      {children && <Text mt="16px">{children}</Text>}
    </StyledPageHeader>
  )
}

export default PageHeader
