/* eslint-disable import/prefer-default-export */
import i18n from 'i18next';
import { Decimal } from "decimal.js";
import {getReferenceNft} from 'api/api'

export const interceptAdd = (add) => {
  const l = 4;
  return add
    ? `${add.substr(0, l)
    }...${add.substring(add.length - l)}`
    : 'Connect Wallet';
};

function IsPC() {
 
  const userAgentInfo = navigator.userAgent;
   
  const Agents = ["Android", "iPhone",
   
  "SymbianOS", "Windows Phone",
   
  "iPad", "iPod"];
   
  let flagPc = true;
   
  for (let v = 0; v < Agents.length; v++) {
   
    if (userAgentInfo.indexOf(Agents[v]) > 0) {
      flagPc = false;
      
      break;
    
    }
   
  }
   
  return flagPc;
   
  }
   
   
   
  export const flagZt = IsPC();
   

  // 倒计时时间转换
export const formatDateWithSlash = (time) => {
  if (time > 0) {
    // 天为 / 24
    let d = parseInt((time / 60 / 60 / 24).toString()) as any;
    if (d >= 0) {
      d = d < 10 ? `0${d}` : d;
    } else {
      d = '00';
    }
    let h = parseInt((time / 60 / 60 % 24).toString()) as any;
    if (h >= 0) {
      h = h < 10 ? `0${h}` : h;
    } else {
      h = '00';
    }
    let m = parseInt((time / 60 % 60).toString()) as any;
    if (m >= 0) {
      m = m < 10 ? `0${m}` : m;
    } else {
      m = '00';
    }
    let s = parseInt((time % 60).toString()) as any;
    if (s >= 0) {
      s = s < 10 ? `0${s}` : s;
    } else {
      s = '00';
    }
    return {
      d,
      h,
      m,
      s
    };
  }
  return {
    d: '00',
    h: '00',
    m: '00',
    s: '00'
  };
};

export function initLangage() {
  // let lang = localStorage.getItem("language") || navigator.language // 获取浏览器的语言环境，兼容IE的写法
  let lang = localStorage.getItem("language") || "en"
  if (lang) {
    lang = lang.substr(0, 2).toLowerCase() // 截取前两位字符，并转化为小写
    return lang
  } 
    return "en"
}


// 千分位正则分割
export function percentileSegmentation(val, decimal = 4) {
  if(!val&&(val!==0)) {
    return ''
  }
  const numArr = new Decimal(val).mul(new Decimal(+`1e${decimal}`)).div(new Decimal(+`1e${decimal}`)).toFixed().toString()?.split('.')
  
  const result = numArr?.[0].replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => {
    return `${s},`
  })
  return +numArr?.[1] ? `${result}.${(`0.${numArr?.[1]}`)?.split('.')?.[1]}` : `${result}`
}

// 千分位字符转数字
function numFormat(val) {  
  if(!val) {
    return ''
  }
  return val.replace(/,/g, '');
}

// export async function getNFTs(contract: any, account: any) { 
//   // const balance = await contract.methods.balanceOf(account).call()
//   console.log('请求NFT开始')
//   const balance = 40
//   const term1Arr = await Promise.all([...new Array(Number(Math.ceil(balance/1)))].map(async (item, index)=>{
//     const termItem = await contract.methods.getUserTokens(account, 1, index+1).call()
//     return new Promise((resolve) => {
//       resolve(termItem)
//     })
//   })) as any
//   console.log('请求NFT开始')
//   let ids = []
//   let lvs = []
//   console.log(131, term1Arr)
//   for (let index = 0; index < term1Arr.length; index++) {
//     ids = ids?.concat(term1Arr[index]?.ids)
//     lvs = lvs?.concat(term1Arr[index]?.lvs)
//   }
//   return {
//     ids,
//     lvs
//   }

// }

export async function getNFTs(contract: any, account: any) { 
  let balance = await contract.methods.balanceOf(account).call()
  // console.log('持有数量', balance)
  // let balance = 4
  // console.log('请求NFT开始')
  balance = balance > 200 ? 200 : balance
  const forIn = 20
  let term1Arr = []
  // 同步请求 速度慢
  // for (let i = 0; i < balance / forIn; i++) {
  //   console.log(`开始：批次${i},每批张数${forIn},总批数${balance / forIn}`)
  //   // eslint-disable-next-line no-await-in-loop
  //   const term1ArrForIn = await Promise.all([...new Array(Number(Math.ceil(forIn/1)))].map(async (item, index)=>{
  //     const termItem = await contract.methods.getUserTokens(account, 1, forIn * i + index + 1).call()
  //     return new Promise((resolve) => {
  //       resolve(termItem)
  //     })
  //   }))
  //   console.log(`批次${i},每批张数${forIn},总批数${balance / forIn}`,term1ArrForIn, )
  //   term1Arr = [...term1Arr,...term1ArrForIn]
    
  // }

  // 异步请求 双层数据需要处理 双层异步请求
  const term1ArrBig: Array<Array<any>> = await Promise.all([...new Array(Number(Math.ceil(balance/forIn)))].map(async (item,i)=>{
    // eslint-disable-next-line no-return-await, no-async-promise-executor
    return await new Promise(async (resolveBig) => {
      // console.log(171, i, balance/forIn<=(i+1)?balance-forIn*(i):forIn)
      // eslint-disable-next-line @typescript-eslint/no-shadow
      const termItemBig = await Promise.all([...new Array(Number(
        // eslint-disable-next-line @typescript-eslint/no-shadow
        Math.ceil((balance/forIn<=(i+1)?balance-forIn*(i):forIn)/1)))].map(async (item, index)=>{
        const termItem = await contract.methods.getUserTokens(account, 1, forIn * i + index + 1).call()
        // eslint-disable-next-line @typescript-eslint/no-shadow
        return new Promise((resolve) => {
          resolve(termItem)
        })
      }))
      return resolveBig(termItemBig)
    })
  }))
  // 处理双层数据格式
  // eslint-disable-next-line array-callback-return
  term1ArrBig.map((item: Array<any>)=>{
      term1Arr = [...term1Arr,...item]
  })

  let ids = []
  let lvs = []
  // console.log(131, term1Arr)
  for (let index = 0; index < term1Arr.length; index++) {
    ids = ids?.concat(term1Arr[index]?.ids)
    lvs = lvs?.concat(term1Arr[index]?.lvs)
  }
  return {
    ids,
    lvs
  }

}

// 新获取方法
export async function getUserLevelTokens(contract: any, account: any) { 
  const balance = await contract.methods.balanceOf(account).call()
  const term1Arr = await Promise.all([...new Array(Number(Math.ceil(balance/50)))].map(async (item, index)=>{
    const termItem = await contract.methods.getUserLevelTokens(account, 0, 50, index+1).call()
    return new Promise((resolve) => {
      resolve(termItem)
    })
  })) as any
  let ids = []
  let lvs = []
  for (let index = 0; index < term1Arr.length; index++) {
    ids = ids?.concat(term1Arr[index]?.ids)
    lvs = lvs?.concat(term1Arr[index]?.lvs)
  }
  return {
    ids,
    lvs
  }

}

// NFT筛选逻辑注释
export async function getNFTsNoSearch(contract: any, account: any, pageIndex) { 
  const balanceAll = await contract.methods.balanceOf(account).call()
  const balance = 8
  console.log('请求NFT开始', (balanceAll/balance),pageIndex)
  const term1Arr = await Promise.all([...new Array(
    Number(Math.ceil(((balanceAll/balance)<=pageIndex?balanceAll-balance*(pageIndex-1):balance)/1)))].map(async (item, index)=>{
    const termItem = await contract.methods.getUserTokens(account, 1, ((pageIndex-1) * balance) + index+1).call()
    return new Promise((resolve) => {
      resolve(termItem)
    })
  })) as any
  console.log('请求NFT开始')
  let ids = []
  let lvs = []
  console.log(131, term1Arr)
  for (let index = 0; index < term1Arr.length; index++) {
    ids = ids?.concat(term1Arr[index]?.ids)
    lvs = lvs?.concat(term1Arr[index]?.lvs)
  }
  return {
    ids,
    lvs
  }

}

  
       

// 
export async function getNFTsStaking(contract: any, account: any, balance: number, page, pageIndex) { 
  // let balance = await contract.methods.balanceOf(account).call()
  // console.log('持有数量', balance)
  // let balance = 4
  // console.log('请求NFT开始')
  // balance = balance > 200 ? 200 : balance
  const forIn = 5
  let term1Arr = []
  // 同步请求 速度慢
  // for (let i = 0; i < balance / forIn; i++) {
  //   console.log(`开始：批次${i},每批张数${forIn},总批数${balance / forIn}`)
  //   // eslint-disable-next-line no-await-in-loop
  //   const term1ArrForIn = await Promise.all([...new Array(Number(Math.ceil(forIn/1)))].map(async (item, index)=>{
  //     const termItem = await contract.methods.getUserTokens(account, 1, forIn * i + index + 1).call()
  //     return new Promise((resolve) => {
  //       resolve(termItem)
  //     })
  //   }))
  //   console.log(`批次${i},每批张数${forIn},总批数${balance / forIn}`,term1ArrForIn, )
  //   term1Arr = [...term1Arr,...term1ArrForIn]
    
  // }

  // 异步请求 双层数据需要处理 双层异步请求
  const term1ArrBig: Array<Array<any>> = await Promise.all([...new Array(Number(Math.ceil(balance/forIn)))].map(async (item,i)=>{
    // eslint-disable-next-line no-return-await, no-async-promise-executor
    return await new Promise(async (resolveBig) => {
      // console.log(286, Math.ceil(balance/forIn)<=(i+1)?balance-forIn*(i):forIn)
      // eslint-disable-next-line @typescript-eslint/no-shadow
      const termItemBig = await Promise.all([...new Array(Number(
        // eslint-disable-next-line @typescript-eslint/no-shadow
        Math.ceil((Math.ceil(balance/forIn)<=(i+1)?balance-forIn*(i):forIn)/1)))].map(async (item, index)=>{
        const termItem = await contract.methods.getUserTokens(account, 1, page * pageIndex + forIn * i + index + 1).call()
        // eslint-disable-next-line @typescript-eslint/no-shadow
        return new Promise((resolve) => {
          resolve(termItem)
        })
      }))
      return resolveBig(termItemBig)
    })
  }))
  // 处理双层数据格式
  // eslint-disable-next-line array-callback-return
  term1ArrBig.map((item: Array<any>)=>{
      term1Arr = [...term1Arr,...item]
  })

  let ids = []
  let lvs = []
  // console.log(131, term1Arr)
  for (let index = 0; index < term1Arr.length; index++) {
    ids = ids?.concat(term1Arr[index]?.ids)
    lvs = lvs?.concat(term1Arr[index]?.lvs)
  }
  return {
    ids,
    lvs
  }

}

export async function getNFTsStakingForSynchronous(contract: any, account: any){
  const balance = await contract.methods.balanceOf(account).call()
  // const balance = 999
  const pageIndex = 100
  const forAll = Math.ceil(balance/pageIndex)
  let ids = []
  let lvs = []
  for (let index = 0; index < forAll; index++) {
    console.log(index+1)
    const pageBalance = forAll<=(index+1)?balance-pageIndex*(index):pageIndex
    // eslint-disable-next-line no-await-in-loop
    const term1 = await getNFTsStaking(contract, account, pageBalance, index, pageIndex) as any
    console.log(index+1, term1)
    ids = [...ids, ...term1?.ids]
    lvs = [...lvs, ...term1?.lvs]
  }
  return {
    ids,
    lvs
  }
}

export async function getNFTsStakingFor(contract: any, account: any){
  const balance = await contract.methods.balanceOf(account).call()
  // const balance = 999
  const pageIndex = 200
  const forAll = Math.ceil(balance/pageIndex)
  console.log(347, forAll)
  let ids = []
  let lvs = []
  // for (let index = 0; index < forAll; index++) {
  //   console.log(index+1)
  //   const pageBalance = forAll<=(index+1)?balance-pageIndex*(index):pageIndex
  //   // eslint-disable-next-line no-await-in-loop
  //   const term1 = await getNFTsStaking(contract, account, pageBalance, index, pageIndex) as any
  //   console.log(index+1, term1)
  //   ids = [...ids, ...term1?.ids]
  //   lvs = [...lvs, ...term1?.lvs]
  // }
  const termAll = await Promise.all([...new Array(Number(forAll))].map(async (item,index)=>{
    const pageBalance = forAll<=(index+1)?balance-pageIndex*(index):pageIndex
    console.log(361, pageBalance)
    const termItem = await getNFTsStaking(contract, account, pageBalance, index, pageIndex) as any
    return new Promise((resolve) => {
      resolve(termItem)
    })
  }))


  for (let index = 0; index < termAll?.length; index++) {
    const element = termAll[index] as never as any;
    console.log(371,element?.lvs)
    lvs = [...lvs, ...element?.lvs]
    ids = [...ids, ...element?.ids]
  }
  console.log(374, lvs, ids)

  return {
    ids: [...ids],
    lvs: [...lvs]
  }
}


// https 获取NFT 数据
export async function getNFTsHttp(account: string, nftAddress: string, balance: number, levelArr: string[]) {
  let termAll = []
  let cursor = ''
  const pageIndex = 100
  const forAll = Math.ceil(balance/pageIndex)
  
  // 异步
  // const termAll = await Promise.all([...new Array(Number(forAll))].map(async (item,index)=>{
  //   // const pageBalance = forAll<=(index+1)?balance-pageIndex*(index):pageIndex
  //   // console.log(361, pageBalance)
  //   const termItem = await getReferenceNft(`https://bnbapi.nftscan.com/api/v2/account/own/${account}`,{
  //     erc_type: 'erc721',
  //     contract_address: nftAddress,
  //     show_attribute: false,
  //     limit: pageIndex,
  //     cursor: index+1,
  //   })
  //   return new Promise((resolve) => {
  //     resolve(termItem)
  //   })
  // }))

  // 同步
  for (let index = 0; index < forAll; index++) {
    // eslint-disable-next-line no-await-in-loop
    const termItem = await getReferenceNft(`https://bnbapi.nftscan.com/api/v2/account/own/${account}`,{
      erc_type: 'erc721',
      contract_address: nftAddress,
      show_attribute: false,
      limit: pageIndex,
      cursor,
    })
    cursor = termItem.next
    termAll = [...termAll, ...termItem?.content]
  }
  
  return handleNFTsDataY(termAll, levelArr)
}


// https 获取NFT 数据
export async function getNFTsHttpNew(account: string, nftAddress: string, balance: number, levelArr: string[], cursor: string[], ) {
  let termAll = {}
  const pageIndex = 100
  const forAll = Math.ceil(balance/pageIndex)


    // eslint-disable-next-line no-await-in-loop
    const termItem = await getReferenceNft(`https://bnbapi.nftscan.com/api/v2/account/own/${account}`,{
      erc_type: 'erc721',
      contract_address: nftAddress,
      show_attribute: false,
      limit: pageIndex,
      cursor,
    })
    termAll = handleNFTsDataY(termItem?.content, levelArr)
  
  return {
    termAll,
    cursor: termItem.next
  }
}

// 处理为NFT可用数据
export function handleNFTsData(data: any[], lvs: Array<string>){
  
  // eslint-disable-next-line array-callback-return
  return data.map((item, index)=>{
    return {
      index,
      name: item?.name,
      token_id: item?.token_id,
      content_uri: item?.content_uri,
      content_type: item?.content_type,
      level: lvs.indexOf(item?.name)
    }
  })
}

// 处理为NFT可用数据
export function handleNFTsDataY(data: any[], lvsArr: Array<string>){

  const ids = []

  const lvs = []
  
  // eslint-disable-next-line array-callback-return
  data.map((item, index)=>{
    ids.push(item?.token_id)
    lvs.push(lvsArr.indexOf(item?.name))
  })
  return {
    ids,
    lvs
  }
}