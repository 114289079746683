

import styled from 'styled-components';

const StyleBase = styled.div``


const TokenText = styled(StyleBase)<{isMobile?: boolean}>`
  position: relative;
  display: flex;
  justify-content: start;
  main {
    width: 100%;
    .main {
      width: 100%;
      .module1 {
        .text1 {
          text-align: center;
          font-size: ${({isMobile}) => isMobile ?  '64px' : '32px' };
          font-family: Nirmala UI-Bold;
          font-weight: 400;
          color: #FFFFFF;
        }
        .text2-box {
          margin-bottom: 60px;
          display: flex;
          justify-content: space-evenly;
          align-items: center;
          margin-top: 29px;
          .text2-1 {
            font-size: ${({isMobile}) => isMobile ?  '20px' : '16px' };
            font-family: Nirmala UI-Bold;
            font-weight: 400;
            color: #FFFFFF;
          }
          .text2-2 {
            font-size:  ${({isMobile}) => isMobile ?  '80px' : '24px' };
            font-family: Nirmala UI-Bold;
            font-weight: 400;
            color: #FFFFFF;
          }
        }
      }
    }
  }
`
const ComingSoon = styled(StyleBase)<{isMobile?: boolean}>`
  margin-top: 43px;
  .main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    font-family: Nirmala UI-Regular, Nirmala UI;
    font-weight: 400;
    color: #E9E9E9;
    line-height: 24px;
    img {
      margin-bottom: 33px;
    }
  }
`

// eslint-disable-next-line import/prefer-default-export
export { TokenText, ComingSoon
}