/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react'
import { formatDecimal } from 'utils/math'
import { Decimal } from "decimal.js";
import { Button } from '@dreamweb/uikit'

const result = sessionStorage.getItem('gas') || 50000
export default function Earend({tabIndex,item,isLoading, NftStakingContract, account}: any) {
  const [earend, setEarend]=useState(0)
  const [timer,setTimer]=useState(null)
  const [isharvest,setHarvest]=useState<any>(false)
  const [isharvest1,setHarvest1]=useState<any>(false)
  const getEarend = async () => {
    const newEarend = await NftStakingContract.methods
    .earned(item.tabIndex, item.id, account).call()
    setEarend(newEarend)
  }
  
  async function harvest(v) {
    try {
      if(tabIndex===0){
        setHarvest(true)
      } else {
        setHarvest1(true)
      }
      // console.log('harvest')
      const view = await NftStakingContract.methods.harvest(tabIndex, v).send({ from: account,
        gasPrice: +result >= 5000000000 ? +result : +result * 1.5, })
      // console.log('harvest', view)
      if(tabIndex===0){
        setHarvest(false)
      } else {
        setHarvest1(false)
      }
    } catch (error) {
      // console.log(error)
      if(tabIndex===0){
        setHarvest(false)
      } else {
        setHarvest1(false)
      }
    }

  }

  useEffect(()=>{
    let newTimer
    if(account) {
      getEarend()
      newTimer = setInterval(() => { 
        getEarend()
      }, 3000)
      setTimer(newTimer)
    }
    return () => {
      clearInterval(newTimer)
    }
  },[])

  return <>
  <div className='text-box'>
      <div style={{color: '#EEEEEE'}}>~ {formatDecimal(new Decimal(earend||0).div(1e18).toNumber(), 4)}</div>
      <div className='flex items-center justify-end'>
        DCOIN
      </div>
    </div>
    <Button isLoading={
      (tabIndex===0)?isharvest:isharvest1
      } onClick={async ()=>{
        await harvest(item?.id)
      }} disabled={!+earend} style={{ width: '100%', height: '30px', background: isLoading||!+earend? '#6D6D6D':'#eee', color: isLoading||!+earend? '#B9B9B9':'#000', borderRadius: '4px',border:'none'}}>
      {
        (tabIndex===0?isharvest:isharvest1)?'Harvesting':'Harvest'
      }
    </Button>
  </>
}
