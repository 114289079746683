/* eslint-disable no-restricted-globals */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import { Modal, useMatchBreakpoints } from '@dreamweb/uikit'
import { useLottery } from 'hooks/useContract'
import { useWeb3React } from '@web3-react/core'
import { checkLotteryDetailById } from 'utils/lotteryUtils'
import { useTranslation } from "react-i18next";
import { red } from 'bn.js';
import { checkLotteryStatus } from '../../utils'
import { StyledTitle, StyledWinNumsStyle, StyledYourTickets, StyledTicketsNumRow, StyledTicketsBox} from './LotteryDetailMoalStyle'

const LotteryDetailMoal = ({ onDismiss, round, winningNumbers, totalTickets}) => {
    const { t } = useTranslation()
    const lotteryContract = useLottery();
    const { account } = useWeb3React();
    const [boughtLotterys, setBoughtLotterys] = useState([]);
    const [winNumber, setWinNumber] = useState(0);
    const { isSm, isXs, isLg, isMd,isXl } = useMatchBreakpoints();
    const isMobile = isXs || isSm || isLg || isMd;

    const formatIndex = (num: number): string => {
        if (num < 10) {
            return `#00${num}`;
        }
        if (num < 100 && num > 9) {
            return `#0${num}`;
        }
        return `#${num}`;
    }
 
    useEffect(()=>{
        const getLotteryInfo = async() => {
            const res = await checkLotteryDetailById(lotteryContract, account, round );
            const { lotteryIds, brackets } = checkLotteryStatus(res, winningNumbers);
            if (lotteryIds) {
                setWinNumber(lotteryIds?.length);
            }
            if (res) {
                setBoughtLotterys(res[1]);
            }
        }  
        if (account) {
            getLotteryInfo();
        } 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <Modal title={`Round ${round}`} onDismiss={onDismiss} style={{ width: isMobile ? '100%' : "450px", 
        background: "#232323" }}>
            <StyledTitle isMobile={isMobile}>{t('Winning Number')}</StyledTitle>
            <StyledWinNumsStyle isMobile={isMobile}>
                <div>
                    {
                        winningNumbers.map(item=>{
                            return <span key={Math.random()} className='square'>{item}</span>
                        })
                    }
                </div>
            </StyledWinNumsStyle>
                <StyledYourTickets isMobile={isMobile}>
                    <section className='title'>{t('Your Tickets')}</section>
                    <section className='total_winning_tickets'>
                        <div>
                            <p className='sub-title'>{t("Total tickets")}</p>
                            <p className='number'>{totalTickets}</p>
                        </div>
                        <div>
                            <p className='sub-title'>{t("Winning tickets")}</p>
                            <p className='number'>{winNumber}</p>
                        </div>
                    </section>
                </StyledYourTickets>
                <StyledTicketsBox isMobile={isMobile}>
                    {
                        boughtLotterys.map((item, index) => {
                            return (
                                // eslint-disable-next-line react/no-array-index-key
                                <StyledTicketsNumRow key={`${index}`}>
                                    <div className='index'>{formatIndex(index + 1)}</div>
                                    <div className='num-arr'>
                                        {
                                            item.substring(1).split('').map((number) => {
                                            return <span>{number}</span>
                                            })
                                        }
                                    </div>
                                </StyledTicketsNumRow>
                            )
                        })
                    }
                </StyledTicketsBox>
        </Modal>
    )
}

export default React.memo(LotteryDetailMoal);
