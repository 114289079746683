/* eslint-disable import/prefer-default-export */
import { useEffect, useState } from 'react'
import BigNumber from 'bignumber.js'
import { useSelector } from 'react-redux'
import useRefresh from 'hooks/useRefresh'
import { useAppDispatch } from 'state'
import { getLpContract } from 'utils/contractHelpers'
import { BIG_ZERO } from 'utils/bigNumber'
import { fetchPoolsUserDataAsync } from './actions'
import { transformPool } from './pools/helpers'
import { State, FarmsState, PriceState, Farm, Pool } from './types'



export const useFarmFromPid = (pid): Farm => {
  const farm = useSelector((state: State) => {
    return state.farms?.data.find((f) => f.pid === pid)
  })

  return farm
}

// Pools

export const usePools = (account): Pool[] => {
  const { fastRefresh } = useRefresh()
  const dispatch = useAppDispatch()
  useEffect(() => {
    if (account) {
      dispatch(fetchPoolsUserDataAsync(account))
    }
  }, [account, dispatch, fastRefresh])

  const pools = useSelector((state: State) => state.pools.data)
  return pools.map(transformPool)
}


export const useFarmUser = (pid) => {
  const farm = useFarmFromPid(pid)
  return {
    allowance: farm.userData ? new BigNumber(farm.userData.allowance) : BIG_ZERO,
    tokenBalance: farm.userData ? new BigNumber(farm.userData.tokenBalance) : BIG_ZERO,
    stakedBalance: farm.userData ? new BigNumber(farm.userData.stakedBalance) : BIG_ZERO,
    earnings: farm.userData ? new BigNumber(farm.userData.earnings) : BIG_ZERO,
  }
}

// Farms

export const useFarms = (): FarmsState => {
  const farms = useSelector((state: State) => {
    return state.farms
  })
  return farms
}


// 页面上多数地方都使用的这个方法
export const usePriceDreamBusd = (): BigNumber => {
  const price = useSelector((state: State) => {
    return state.dreamPrice.price
  })
  return price
}


export const useGetApiPrices = () => {
  const prices: PriceState['data'] = useSelector((state: State) => state.prices?.data)
  return prices
}

export const useLpTokenPrice = (address: string) => {
  const [pric, setPric] = useState(0)
  const lpContract = getLpContract(address)
  const dreamPrice = usePriceDreamBusd().toNumber()
 
  const tokenPriceCumulative = Math.sqrt(1 * dreamPrice)

  useEffect(() => {
    const getLpTokenPrice = async () => {
      const totalReserve = await lpContract.methods.getReserves().call()
      const totalSupply = await lpContract.methods.totalSupply().call()
      const totalSupply_ = new BigNumber(totalSupply)

      const tokenReserveCumulative = Math.sqrt(Number(new BigNumber(totalReserve[0]).times(totalReserve[1])))
      const tokenReserveCumulative_ = new BigNumber(tokenReserveCumulative)

      const tokenPriceCumulative_ = new BigNumber(tokenPriceCumulative)
      
      const lpTokenPrice = tokenReserveCumulative_.times(tokenPriceCumulative_).times(2).div(totalSupply_)
      setPric(Number(lpTokenPrice))
    }
    getLpTokenPrice()
  }, [lpContract, tokenPriceCumulative])
  return pric
  // const farm = useFarmFromLpSymbol(symbol)
  // const farmTokenPriceInUsd = useBusdPriceFromPid(farm.pid)
  // let lpTokenPrice = BIG_ZERO

  // if (farm.lpTotalSupply && farm.lpTotalInQuoteToken) {
  //   // Total value of base token in LP
  //   const valueOfBaseTokenInFarm = farmTokenPriceInUsd.times(farm.tokenAmountTotal)
  //   // Double it to get overall value in LP
  //   const overallValueOfAllTokensInFarm = valueOfBaseTokenInFarm.times(2)
  //   // Divide total value of all tokens, by the number of LP tokens
  //   const totalLpTokens = getBalanceAmount(farm.lpTotalSupply)
  //   lpTokenPrice = overallValueOfAllTokensInFarm.div(totalLpTokens)
  // }

  // return lpTokenPrice
}
