import styled from 'styled-components';

const StyleBase = styled.div``

const StyledTicketsNumInput =  styled(StyleBase)<{isMobile?: boolean }>`
    .title{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        color: white;
        margin-bottom: 8px;
        span:first-child{
            font-size: ${({isMobile}) => isMobile ?  '1rem' : '22px' };
            line-height: 20px;
        }
        span:last-child{
            font-size: ${({isMobile}) => isMobile ?  '0.8rem' : '18px' };
            font-weight: bold;
            line-height: 20px;
        }
    }
    input {
        background: #010101;
        border-radius: 12px 12px 12px 12px;
        opacity: 1;
        border: 1px solid #8B8B8B;
    }
`
const StyledCostDetail = styled(StyleBase)<{isMobile?: boolean }>`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: ${({isMobile}) => isMobile ?  '0.4rem 0 0' : '10px' };
    font-size: ${({isMobile}) => isMobile ?  '0.6rem' : '16px' };
    font-weight: 400;
    color: white;
    .question {
        display: inline-block;
        width: 20px;
        height: 20px;
        margin-left: 10px;
        border-radius: 12px;
        text-align: center;
        line-height: 20px;
        border: 1px solid white;
        color: white;
    }
`
const StyledShouldPay = styled(StyleBase)<{isMobile?: boolean }>`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: ${({isMobile}) => isMobile ?  '0.6rem 0 1rem' : '18px 0 26px' };
    font-size: ${({isMobile}) => isMobile ?  '0.6rem' : '16px' };
    color: white;
    line-height: 25px;
    span:first-child{
        font-weight: 400;
    }
    span:last-child{
        font-weight: 900;
    }
`
const StyleInfoText = styled(StyleBase)<{isMobile?: boolean }>`
    margin-top: 20px;
    margin-bottom: 12px;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    color: white
`
const StyledTicketsNum = styled(StyleBase)<{isMobile?: boolean }>`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin:  ${({isMobile}) => isMobile ?  '0 0 0.6rem' : '26px 0 16px' };
    .num-btn{
        display: flex;
        justify-content: center;
        align-items: center;
        width: ${({isMobile}) => isMobile ?  '3.6rem' : '110px' };
        opacity: 1;
        color: white;
        font-size:  ${({isMobile}) => isMobile ?  '0.6rem' : '16px' };
        text-align: center;
        font-weight: 900;
        line-height: 26px;
        cursor: pointer;
        height: 46px;
        background: #232323;
        border-radius: 12px 12px 12px 12px;
        border: 1px solid #8B8B8B;
    }
 
`
const StyledContainer = styled(StyleBase)<{isMobile?: boolean }>`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding-top: 125px;
  font-size: 22px;
  font-weight: 300;
`
const StyledBalance = styled(StyleBase)<{isMobile?: boolean }>`
    display: flex;
    justify-content: flex-end;
    margin: 8px 0 26px;
    font-size: 12px;
    color: white;

`
const StyleDivdeLine = styled(StyleBase)<{isMobile?: boolean }>`
    margin: 16px 0 0;
    height: 1px;
    border: 1px solid #CBCBCB;
    border-radius: 15px;
`

const StyledTitle = styled(StyleBase)<{isMobile?: boolean }>`
  font-size: 18px;
  color: white;
  margin-bottom: 40px;
  `
const StyledSum =  styled(StyleBase)<{isMobile?: boolean }>`
  margin-bottom: 20px;
  font-size: 60px;
  font-family: AXIS;
  font-weight: normal;
  color: #FFF;
`
const StyledDesc = styled(StyleBase)<{isMobile?: boolean }>`
  margin-bottom: 50px;
  font-size: 20px;
  font-family: AXIS;
  font-weight: normal;
  line-height: 20px;
  color: #FFFFFF;
`
const StyledAlert = styled(StyleBase)<{isMobile?: boolean }>`
    position: fixed;
    top: ${({isMobile}) => isMobile ?  '1rem' : '20px' };
    right: ${({isMobile}) => isMobile ?  '10px' : '20px' };
    width: ${({isMobile}) => isMobile ?  'calc(100% - 20px)' : '600px' };

`
 

export {StyledTicketsNumInput, StyledTicketsNum, StyledBalance, StyledCostDetail,StyledShouldPay, StyledAlert, StyleInfoText, StyleDivdeLine, StyledContainer, StyledTitle, StyledSum, StyledDesc}