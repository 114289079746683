/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-cycle */
/* eslint-disable import/order */
/* eslint-disable no-restricted-globals */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable-next-line react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef, useMemo } from 'react';
import { Button, Modal, Input , useMatchBreakpoints, useTooltip, Alert, Text , ButtonMenu, ButtonMenuItem } from '@dreamweb/uikit'
import { useTokenBalance, useGetBnbBalance } from 'hooks/useTokenBalance'
import { getDcoinAddress, getKyuruoAddress, getIsDcoinKyuruoAddress } from 'utils/addressHelpers'
import { useTranslation } from "react-i18next";
import Login from 'components/Login'
import styled from 'styled-components'
import { useLotteryAllowance } from 'hooks/useAllowance'
import { useLotteryApprove } from 'hooks/useApprove'
import { useLottery } from 'hooks/useContract'
import BigNumber from 'bignumber.js'
import { useHistory } from "react-router-dom";
import { DEFAULT_TOKEN_DECIMAL } from 'config'
import { interceptAdd } from 'utils/utils'
import EventBus from 'utils/eventBus'
import { StyleContainer, GYTN } from '../../LotteryStyle'
import { buyTickets, getViewLottery, getMax } from 'utils/lotteryUtils'
import { formatDecimal } from 'utils/math'
import useToast from 'hooks/useToast'
import { useWeb3React } from '@web3-react/core'
import { debounce } from 'lodash';
import SelectNumbers from './SelectNumbers';
import { usePriceDreamBusd } from 'state/hooks'
import { StyledTicketsNum, StyledTicketsNumInput, StyledAlert, StyledBalance, StyledShouldPay, StyledCostDetail, StyleInfoText, StyleDivdeLine } from './BuyTicketsModalStyle'

const ticketNumArr = [1, 3, 6, 'MAX'];

 
const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0px;
`

const BuyTicketsModal = ({ onDismiss }) => {
    // localStorage.setItem('isDcoinKyuruo', "0")
    // console.log(31, localStorage.getItem('isDcoinKyuruo'))  
    // G
    const [isDcoinKyuruo,setIsDcoinKyuruo]=useState(localStorage.getItem('isDcoinKyuruo')||"0")
    const { toastError, toastSuccess, toastInfo, toastWarning } = useToast()
    const history = useHistory()
    
    useEffect(() => { 
        if((localStorage.getItem('isDcoinKyuruo')||'0')==='0') {
            setIsDcoinKyuruo('0')
        } else {
            setIsDcoinKyuruo('1')
        }
    },[localStorage.getItem('isDcoinKyuruo')])
    // G
    const isDcoinKyuruoAddress = getIsDcoinKyuruoAddress(isDcoinKyuruo)
    // const kuruoAddress =  getKyuruoAddress()
    const { t } = useTranslation()
    const { onApprove } = useLotteryApprove(isDcoinKyuruo)
    const { account } = useWeb3React();
    const [modalTitle, setModalTitle] = useState('Buy Tickets');
    const [tabCoinIndex, setTabCoinIndex] = useState((localStorage.getItem('isDcoinKyuruo')||'0')==='0'?0:1)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isDcoinKyuruoPrice, setIsDcoinKyuruoPrice] = useState(0)
    const [allowedToSelectNumber, setAllowedToSelectNumber] = useState(false)
    const [ticketsNumArr, setTicketsNumArr] = useState([])
    const [needApprove, setNeedApprove] = useState(true);
    const [toSelectTicketNum, setToSelectTicketNum] = useState(false);
    const lotteryContract = useLottery()
    const [update, setUpdate] = useState(false)
    // G
    const allowance = useLotteryAllowance(isDcoinKyuruo, update)
    const [buyTicketsNumber, setBuyTicketsNumber] = useState<string | number>(0);
    // G
    const balance = useTokenBalance(isDcoinKyuruoAddress, update);
    const [noBalance, setNoBalance] = useState(false);
    const [discount, setDiscount] = useState(0);
    const costCake = useRef<string>('0.00000');
    const actuallyNeedTopay = useRef<string>('0.00000');
    const discountCake =  useRef<string>('0.00000');
    const discountRate = useRef<string>('0.00000');
    const inputRef = useRef<HTMLInputElement>(null);
    const inpRef = useRef(null)
    const checkInputReg = new RegExp("^[0-9]*[1-9][0-9]*$");
    const [maxNum , setMaxNum] = useState(0);
    const [affordable, setAfforable ] = useState(false);
    const [pendingEnable, setPendingEnable] = useState(false);
    const [pendingEnable1, setPendingEnable1] = useState(false);
    const [pendingBuyTicket, setPendingBuyTicket] = useState(false);
    const [upDatebalance,setUpDatebalance] = useState(false);
    const {balance: BnbBalance} = useGetBnbBalance(upDatebalance);
    const currentAccount = useRef('');
    const { isSm, isXs, isLg, isMd,isXl } = useMatchBreakpoints();
    const isMobile = isXs || isSm || isLg || isMd;
    const DreamPriceUsd = usePriceDreamBusd();
    
    const getLotteryInfo = async () => {
        const info = await getViewLottery(lotteryContract);
        setAllowedToSelectNumber(true);
        const { discountDivisor, priceTicketInDcoin, priceTicketInKyuryo } = info;
        setDiscount(Number(discountDivisor));
        // G
        setIsDcoinKyuruoPrice(Number(isDcoinKyuruo==="0"?priceTicketInDcoin:priceTicketInKyuryo));
    }


    const checkAfordable = () => {
        const canAfford = Number( actuallyNeedTopay.current) > Number(balance.toNumber() / 1e18);
        if (canAfford) {
            setAfforable(true);
        } else {
            setAfforable(false);
        }
    }

    const getAllowance = async () => {
        // 先查询用户的授权额度，等于0才需要授权，大于0就不用
        // 购买之前 需要查询 是否已经授权
        if (!allowance.toNumber()) {
            setNeedApprove(true)
        } else {
            setNeedApprove(false)
        }
    }

    const buyTicket = async () => {
        try {
            setPendingBuyTicket(true)
            await buyTickets(lotteryContract, ticketsNumArr, account, !+isDcoinKyuruo)
            setToSelectTicketNum(false)
            setUpdate(pre=>!pre)
            setBuyTicketsNumber(0)
            setPendingBuyTicket(false)
            EventBus.commit("reFetchDataWhenBuyTicketsSuccessfully")
            toastSuccess('Buy successful')
            setUpDatebalance(pre=>!pre)
            onDismiss()
        } catch (error: any) {
            setPendingBuyTicket(false);
            setUpDatebalance(pre=>!pre)
            toastWarning(error?.message ? error?.message : 'Buy failed')
        }
    }

    const calculateTotalPrice= (_priceTicket, _numberTickets, _discountDivisor) => {
        if (_discountDivisor === 0 || Number(_numberTickets) === 1) return 0;
        return new BigNumber(_priceTicket).times(_numberTickets).times(_discountDivisor + 1 - _numberTickets).div(_discountDivisor)
    }

    const resetInput=() => {
        setBuyTicketsNumber(0);
        discountRate.current = '0'
        discountCake.current = '0'
        costCake.current = '0'
        actuallyNeedTopay.current =  '0';
    }
    
    const getMaxByCalculate = (T: number, p: number, d: number) => {
        const r1 = Number(balance) / 1e18;
        const r2 = Number(isDcoinKyuruoPrice) / 1e18
        const maxNumber = r1 / r2;
        if (maxNumber >= 100 ) {
            setBuyTicketsNumber(100);
        } else if (maxNumber === 0 ) {
            resetInput();
        } else {
            setBuyTicketsNumber(Math.floor(maxNumber));
        }
    }

    const createNumbers = () => {
        const arr = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
        const out = [];
        for (let i = 0; i < 6; i++) {
            out.push(arr[Math.floor(Math.random() * 10)])
        }
        return out;
    }

    const checkSelectTicketsNumOrNot = () => {
        setToSelectTicketNum(true)
        setModalTitle("Edit numbers")
    }

    const createTicketsNumberArr = (num: number | string) => {
        const ticketNumberArr = [];
        for (let i = 0; i < +buyTicketsNumber; i++) {
            ticketNumberArr.push(createNumbers());
        }
        return ticketNumberArr;
    }

    const setBuyTicketsNum = async (num: number | string) => {
        if (num === 'MAX') {
            getMaxByCalculate(Number(balance) / 1e18, Number(isDcoinKyuruoPrice) / 1e18, Number(discount))
        } else {
            setBuyTicketsNumber(Number(num));
        }
    }

    const setBuyTicketsNumRandomly = () => {
        setTicketsNumArr(createTicketsNumberArr(buyTicketsNumber));
    }

    const approve = async () => {
        if (BnbBalance.toNumber() === 0) {
            setNoBalance(true);
            setTimeout(()=>{
                setNoBalance(false);
            }, 3000)
            return;
        }
        try {
            setPendingEnable(true);
            await onApprove();
            toastSuccess('Approve successful')
            setUpdate(pre=>!pre)
            setPendingEnable(false);
            setPendingBuyTicket(false);
        } catch (error: any) {
            toastWarning(error?.message ? error?.message : 'Approve failed')
            setNeedApprove(true);
            setPendingEnable(false);
        }
    }    
    const approve1 = async () => {
        if (BnbBalance.toNumber() === 0) {
            setNoBalance(true);
            setTimeout(()=>{
                setNoBalance(false);
            }, 3000)
            return;
        }
        try {
            setPendingEnable1(true);
            await onApprove();
            setUpdate(pre=>!pre)
            setPendingEnable1(false);
            setPendingBuyTicket(false);
        } catch (error) {
            setNeedApprove(true);
            setPendingEnable1(false);
        }
    }

    const goBack = () => {
        setPendingEnable(false);
        setPendingEnable1(false);
        setPendingBuyTicket(false);
        setBuyTicketsNumRandomly();
        setToSelectTicketNum(false);
        setModalTitle("Buy Tickets");
    }
    
    const debouncedOnChange =  debounce((e: React.ChangeEvent<HTMLInputElement>) => {
        const inputNumber = inputRef.current.value
        setBuyTicketsNumber(Number(inputNumber))
    }, 800);
    
    const cloaseErrorModal = () => {
        setNoBalance(false)
    }

    const getMaxInputAllowed =async () => {
        const max = await getMax(lotteryContract);
        setMaxNum(max)
    }
 
    const setTicketNum =  (e) => {
        const inputNumber = e?.target?.value?.replace(/[^\d]/g,'').replace(/^0{1,}/g,'')
        if(e?.nativeEvent?.data === ".") {
            setBuyTicketsNumber('')
            setTimeout(() => {
                inpRef?.current?.focus()
            }, 50);
            return
        }
        if(e?.nativeEvent?.data === "+" || e?.nativeEvent?.data === "-" || e?.nativeEvent?.data === "e") return
        if (e.target.value === '' || e.target.value === '0') {
            resetInput();
            setTimeout(() => {
                inpRef?.current?.focus()
            }, 50);
            return;
        }
        if (inputNumber === '') {
            setBuyTicketsNumber('')
        }
        // if (!checkInputReg.test(inputNumber)) {
        //     e.current.value = '';
        //     setBuyTicketsNumber('')
        //     return;
        // } 
        if (Number(inputNumber) > maxNum) {
            e.target.value = `${maxNum}`;
            setBuyTicketsNumber(Number(maxNum));
        } else if(Number(inputNumber) <= 0) {
            setBuyTicketsNumber('')
        } else {
            setBuyTicketsNumber(Number(inputNumber))
        }
        setTimeout(() => {
            inpRef?.current?.focus()
        }, 50);
    }

    const numIptKeyUp = (e) => {
        if(e?.key === "Enter"){
            inpRef?.current?.blur()
        }
    }

    const focusIpt = (e) => {
        if(e?.target?.value === "") {
            setBuyTicketsNumber(0)
            setTimeout(() => {
                inpRef?.current?.focus()
            }, 50);
        }
    }
    const calculateCost = async () => {
        setTicketsNumArr(createTicketsNumberArr(buyTicketsNumber));
        costCake.current = (isDcoinKyuruoPrice * Number(buyTicketsNumber) / 1e18).toString();
        if (buyTicketsNumber === 1) {
            discountRate.current = '0'
            discountCake.current = '0'
            actuallyNeedTopay.current = `${isDcoinKyuruoPrice / 1e18}`;
        } else {
            actuallyNeedTopay.current = new BigNumber(calculateTotalPrice(isDcoinKyuruoPrice, buyTicketsNumber, discount )).div(DEFAULT_TOKEN_DECIMAL).toString();
            discountCake.current = (new BigNumber(costCake.current).minus(new BigNumber(actuallyNeedTopay.current))).toString();
            discountRate.current =  (new BigNumber(1).minus(new BigNumber(actuallyNeedTopay.current).div(new BigNumber(costCake.current))).times(new BigNumber(100))).toString()
        }
    }

    useEffect(() => {
        setToSelectTicketNum(false)
        if ((!account || (account !== currentAccount.current))) {
            setNeedApprove(true)
            setBuyTicketsNumber(0);
            currentAccount.current = account;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [account, isDcoinKyuruo])

    useEffect(()=>{
        getMaxInputAllowed();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    useEffect(() => {
        getAllowance()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allowance, isDcoinKyuruo, update]);

    useEffect(() => {
        if (lotteryContract) {
            getLotteryInfo()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lotteryContract, isDcoinKyuruo])

    useEffect(() => {
        if (buyTicketsNumber) {
            calculateCost()
        }
        checkAfordable();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [buyTicketsNumber, isDcoinKyuruoPrice, isDcoinKyuruo]);

    
  const handleTabClick = (index) => { 
    if(index===0) {
      localStorage.setItem('isDcoinKyuruo','0')
      setTabCoinIndex(0)
    //   history.push('/lottery/dcoin')
    }else {
      setTabCoinIndex(1)
      localStorage.setItem('isDcoinKyuruo','1')
    //   history.push('/lottery/kyuryo')
    }
  }

  
//   useEffect(() => { 
//     if(new RegExp('/dcoin').test(window.location.href)){
//       localStorage.setItem('isDcoinKyuruo','0')
//       setTabCoinIndex(0)
//     } else {
//       localStorage.setItem('isDcoinKyuruo','1')
//       setTabCoinIndex(1)
//     }
//     const unListen = history.listen((e) => { 
//       if(new RegExp('/dcoin').test(e.pathname) ) {
//         localStorage.setItem('isDcoinKyuruo','0')
//         setTabCoinIndex(0)
//       } else {
//         localStorage.setItem('isDcoinKyuruo','1')
//         setTabCoinIndex(1)
//       }
//      })
//     return ()=>{
//       unListen()
//     }
//   }, [account])

    const BuyTicket = (): JSX.Element => {
        const { targetRef, tooltip, tooltipVisible } = useTooltip(
            t(
              'Buying multiple tickets in a single transaction gives a discount. The discount increases in a linear way, up to the maximum of 100 tickets.',
            ),
            { placement: 'top-end'},
        );
        return (
            <>
                <Wrapper style={{justifyContent: isMobile ? 'center' : 'center'}}>
                    <StyleContainer isMobile={isMobile}  style={{marginBottom: isMobile? '30px' : '30px', width: isMobile ? '400px' : '400px', padding: '0 14px' }}>
                    <ButtonMenu style={{ width: '100%', height: '41px', backgroundColor: '#000', borderRadius: '9px', border: '1px solid #fff' }} activeIndex={tabCoinIndex} onItemClick={handleTabClick} scale="sm" >
                        <ButtonMenuItem style={{ width: '50%', height: '41px',backgroundColor: tabCoinIndex === 0 ? '#fff': '', borderRadius: '8px', color: tabCoinIndex === 0 ? '#000': '#fff'}}>DCOIN</ButtonMenuItem>
                        <ButtonMenuItem style={{ width: '50%', height: '41px',backgroundColor: tabCoinIndex === 1 ? '#fff': '', borderRadius: '8px', color: tabCoinIndex === 1 ? '#000': '#fff'}}>KYURYO</ButtonMenuItem>
                    </ButtonMenu>
                    </StyleContainer>
                </Wrapper>
                {
                    noBalance && <StyledAlert isMobile={isMobile}>
                        <Alert  title="Error:" onClick={()=> {cloaseErrorModal()}} variant="warning">
                            <Text as="p">Please try again. Confirm the transaction and make sure you are paying enough gas!</Text>
                        </Alert>
                    </StyledAlert>
                }
                <StyledTicketsNumInput isMobile={isMobile}>
                    <div className='title'>
                        <span>Buy:</span>
                        <span>Tickets</span>
                    </div>
                    <Input 
                        ref={inpRef} 
                        type="number"
                        value={buyTicketsNumber} 
                        onKeyUp={(e) => numIptKeyUp(e)}
                        onFocus={(e)=> focusIpt(e)}
                        onInput={(e)=> setTicketNum(e)}
                    />
                </StyledTicketsNumInput>
                 
                
                {
                    account &&
                    <StyledBalance>{isDcoinKyuruo==="0"?"Balance":"Balance"}: {formatDecimal((new BigNumber(balance).div(DEFAULT_TOKEN_DECIMAL).toNumber()), 4)}</StyledBalance>
                }
                {
                    account &&
                    <StyledTicketsNum isMobile={isMobile}>
                        {
                            // eslint-disable-next-line jsx-a11y/no-static-element-interactions
                            ticketNumArr.map((num, index) => (<div key={num}  onClick={() => setBuyTicketsNum(num)} className='num-btn'>{num}</div>))
                        }
                    </StyledTicketsNum>
                }
                <StyledCostDetail isMobile={isMobile}>
                    <div>{isDcoinKyuruo==="0"?t("Cost (DCOIN)"):t("Cost (KYURYO)")}</div>
                    <div><span>{ parseFloat(costCake.current) }</span> {isDcoinKyuruo==="0"?t("DCOIN"):t("KYURYO")}</div>
                </StyledCostDetail>
                <StyledCostDetail isMobile={isMobile}>
                    <div>
                        <span>{ parseFloat(discountRate.current) }% </span>{t("Bulk discount")}
                        <span className='question' ref={targetRef}>?</span>
                    </div>
                    {tooltipVisible && tooltip}
                    <div><span>{ parseFloat(discountCake.current) } </span>{isDcoinKyuruo==="0"?t("DCOIN"):t("KYURYO")}</div>
                </StyledCostDetail>
                <StyleDivdeLine />
                <StyledShouldPay isMobile={isMobile}>
                    <span>{t("You pay")}</span>
                    <span>{ parseFloat(actuallyNeedTopay.current) } {isDcoinKyuruo==="0"?t("DCOIN"):t("KYURYO")}</span>
                </StyledShouldPay>
                
                {!account ? 
              <Login
                  setIsModalOpen={setIsModalOpen}
                  isModalOpen={isModalOpen}
              >
                <div style={{width: '340px',height: '56px',background: '#FFFFFF'}} className={account ? 'styledHeaderTextC corsor-pointer' : 'styledHeaderTextC-hover styledHeaderTextC corsor-pointer'}>{interceptAdd(account)}</div>
              </Login> : (
                    <>
                        {
                            needApprove ? 
                            <Button 
                                style={{ marginBottom: "10px", borderRadius: '12px',  color: '#000' }}  
                                onClick={() => {
                                    if(isDcoinKyuruo==='0') {
                                        approve()
                                    }else {
                                        approve1()
                                    }
                                }} 
                                isLoading={isDcoinKyuruo==='0'?pendingEnable:pendingEnable1} 
                                disabled={isDcoinKyuruo==='0'?pendingEnable:pendingEnable1}
                            >
                                {isDcoinKyuruo==='0'?'Enable DCOIN':'Enable KYURUO'}
                            </Button> :
                            <Button 
                                style={{ marginBottom: "10px", borderRadius: '12px', color: '#000' }} 
                                isLoading={pendingBuyTicket} 
                                disabled={pendingBuyTicket || !buyTicketsNumber || affordable} 
                                onClick={() => buyTicket()}
                            >
                                {t("Buy Instantly")}
                            </Button>
                        }
                        
                        <Button style={{ borderRadius: '12px', color: '#000' }}  disabled={!buyTicketsNumber || needApprove || pendingBuyTicket || affordable} onClick={() => checkSelectTicketsNumOrNot()}>{t("View/Edit Numbers")}</Button>
                    </>
                )}
                <StyleInfoText>
                    {t(`'Buy Instantly' chooses random numbers, with no duplicates among your tickets. Prices are set before each round starts.`)}
                </StyleInfoText>
            </>
        )
    }
    return (
        <Modal title={modalTitle} onDismiss={onDismiss} padding="0" style={{   width: isMobile ? '96%' : "600px", background: '#232323' }}>
            {
                toSelectTicketNum ?
                <SelectNumbers
                    ticketNumberArr={ticketsNumArr}
                    ticketsNum={buyTicketsNumber}
                    totalCost = {actuallyNeedTopay.current}
                    setRandomTicketsNumarr={setBuyTicketsNumRandomly}
                    confirmToBuy={buyTicket}
                    goBack={() => goBack()}
                /> :
                <BuyTicket />
            }
        </Modal>
    )
}
export default React.memo(BuyTicketsModal);
