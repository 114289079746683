import styled from 'styled-components';

const StyleBase = styled.div``

const StyleContainer = styled(StyleBase)<{isMobile?: boolean }>`
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: left;
`
const StyleDesc = styled(StyleBase)<{isMobile?: boolean }>`
    width: ${({isMobile}) => isMobile ?  '100%' : '560px' };
`

const StyledTitle = styled.div`
    font-family: Nirmala UI-Bold,Nirmala UI;
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: normal;
    line-height: 21px;
    color: #ffffff;
    text-align: left;
`
const StyleContentShallow = styled.div`
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    color: #A4A4A4;
    margin-bottom: 32px;
`

const StyleSubTitle = styled.div`
    font-weight: bold;
    line-height: 21px;
    color: #ffffff;
    font-size: 18px;
`

const StyleImage = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 46px;
    img{
        width: 80%;
    }
`
const StyleContent = styled.div`
    font-size: 14px;
    color: #333;
`
const StyledMore =  styled(StyleBase)<{isMobile?: boolean }>`
    font-size: ${({isMobile}) => isMobile ?  '1rem' : '16px' };
    font-weight: bold;
    line-height: 21px;
    color: #FFFFFF;
    text-align: center;
    cursor: pointer;
    img{
        margin-right: 12px;
        width:  ${({isMobile}) => isMobile ?  '1rem' : '30px' };
    }
`
export { StyledTitle, StyleContainer, StyleImage, StyleSubTitle, StyleContent, StyledMore, StyleContentShallow, StyleDesc }