/* eslint-disable import/order */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable import/no-cycle */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-nested-ternary */
import React, { useCallback, useContext, useMemo, useState, useEffect } from 'react'
import styled from 'styled-components'
import { Decimal  } from "decimal.js";
import { splitSignature } from '@ethersproject/bytes'
import { Contract } from '@ethersproject/contracts'
import { approve } from 'utils/callHelpers'
import { getBep20Contract } from 'utils/contractHelpers'
import {useUpdateEffect} from 'ahooks'
import { useTokenBalance } from 'hooks/useTokenBalance'
import { 
  getLpAddress
} from 'utils/addressHelpers'
import { TransactionResponse } from '@ethersproject/providers'
import { Currency, currencyEquals, ETHER, Percent, WETH } from '@dreamfinance-libs/sdk'
import { Flex, Text } from '@dreamweb/uikit'
import {Button} from 'antd'
import { ArrowDown, Plus } from 'react-feather'
import LpCurrencyInputPanel from 'components/LpCurrencyInputPanel'
import { RouteComponentProps } from 'react-router'
import { BigNumber } from '@ethersproject/bignumber'
import ConnectWalletButton from 'components/ConnectWalletButton'
import Login from 'components/Login'
import useI18n from 'hooks/useI18n'
import useWeb3 from 'hooks/useWeb3'
import { getLp } from 'utils/change'
import { useActiveWeb3React } from 'hooks/index'
import { AutoColumn, ColumnCenter } from '../../components/Column'
import TransactionConfirmationModal, { ConfirmationModalContent } from '../../components/TransactionConfirmationModal'
import CurrencyInputPanel from '../../components/CurrencyInputPanel'
import DoubleCurrencyLogo from '../../components/DoubleLogo'
import { AddRemoveTabs } from '../../components/NavigationTabs'
import { MinimalPositionCard } from '../../components/PositionCard'
import { RowBetween, RowFixed } from '../../components/Row'
import useToast from 'hooks/useToast'
import Slider from '../../components/Slider'
import CurrencyLogo from '../../components/CurrencyLogo'
import { ROUTER_ADDRESS } from '../../constants'
import { useCurrency } from '../../hooks/Tokens'
import { usePairContract } from '../../hooks/useContract'

import { useTransactionAdder } from '../../state/transactions/hooks'
import { StyledInternalLink } from '../../components/Shared'
import { calculateGasMargin, calculateSlippageAmount, getRouterContract } from '../../utils'
import { currencyId } from '../../utils/currencyId'
import useDebouncedChangeHandler from '../../utils/useDebouncedChangeHandler'
import { wrappedCurrency } from '../../utils/wrappedCurrency'
import AppBody from '../AppBody'
import { ClickableText, Wrapper } from '../Liquidity/styleds'
import { useApproveCallback, ApprovalState } from '../../hooks/useApproveCallback'
import { Dots } from '../../components/swap/styleds'
import { useBurnActionHandlers, useDerivedBurnInfo, useBurnState } from '../../state/burn/hooks'

import { Field } from '../../state/burn/actions'
import { useUserDeadline, useUserSlippageTolerance } from '../../state/user/hooks'

const OutlineCard = styled.div` 
  border-radius: 16px;
  padding: 24px;
  background: #323232;
`

const Body = styled.div`
  padding-left: 24px;
  padding-right: 24px;
`

const result = sessionStorage.getItem('gas') || 50000
export default function RemoveLiquidity({
  history,
  match: {
    params: { currencyIdA, currencyIdB },
  },
}: RouteComponentProps<{ currencyIdA: string; currencyIdB: string }>) {
  const [currencyA, currencyB] = [useCurrency(currencyIdA) ?? undefined, useCurrency(currencyIdB) ?? undefined]
  const { account, chainId, library } = useActiveWeb3React()
  const [isModalOpen, setIsModalOpen] = useState(false);
  const web3 = useWeb3()
  const lpContract =  getLp(web3)
  const { toastError, toastSuccess, toastInfo, toastWarning } = useToast()
  const [token0Num, setToken0Num] = useState('')
  const [token1Num, setToken1Num] = useState('')
  const [tokenNum, setsetTokenNum] = useState('')
  const [isEmpower,setIsEmpower] = useState(0)
  const [isLoading,setIsLoading]=useState(false)
  const [isLoading1,setIsLoading1]=useState(false)
  const TranslateString = useI18n()
  const [tokenAdd,setTokenAdd]=useState('')
  const [balance, setBalance] = useState(0)
  const lpBalance = useTokenBalance(tokenAdd,balance)
  const [tokenA, tokenB] = useMemo(() => [wrappedCurrency(currencyA, chainId), wrappedCurrency(currencyB, chainId)], [
    currencyA,
    currencyB,
    chainId,
  ])




  // burn state
  const { independentField, typedValue } = useBurnState()
  const { pair, parsedAmounts, error } = useDerivedBurnInfo(currencyA ?? undefined, currencyB ?? undefined)
  const { onUserInput: _onUserInput } = useBurnActionHandlers()
  const isValid = !error

  // modal and loading
  const [showConfirm, setShowConfirm] = useState<boolean>(false)
  const [showDetailed, setShowDetailed] = useState<boolean>(false)
  const [attemptingTxn, setAttemptingTxn] = useState(false) // clicked confirm

  // txn values
  const [txHash, setTxHash] = useState<string>('')
  const [deadline] = useUserDeadline()
  const [allowedSlippage] = useUserSlippageTolerance()

  const formattedAmounts = {
    [Field.LIQUIDITY_PERCENT]: parsedAmounts[Field.LIQUIDITY_PERCENT].equalTo('0')
      ? '0'
      : parsedAmounts[Field.LIQUIDITY_PERCENT].lessThan(new Percent('1', '100'))
        ? '<1'
        : parsedAmounts[Field.LIQUIDITY_PERCENT].toFixed(0),
    [Field.LIQUIDITY]:
      independentField === Field.LIQUIDITY ? typedValue : parsedAmounts[Field.LIQUIDITY]?.toSignificant(6) ?? '',
    [Field.CURRENCY_A]:
      independentField === Field.CURRENCY_A ? typedValue : parsedAmounts[Field.CURRENCY_A]?.toSignificant(6) ?? '',
    [Field.CURRENCY_B]:
      independentField === Field.CURRENCY_B ? typedValue : parsedAmounts[Field.CURRENCY_B]?.toSignificant(6) ?? '',
  }

  const atMaxAmount = parsedAmounts[Field.LIQUIDITY_PERCENT]?.equalTo(new Percent('1'))

  // pair contract
  const pairContract: Contract | null = usePairContract(pair?.liquidityToken?.address)
  // allowance handling
  const [signatureData, setSignatureData] = useState<{ v: number; r: string; s: string; deadline: number } | null>(null)
  const [approval, approveCallback] = useApproveCallback(parsedAmounts[Field.LIQUIDITY], ROUTER_ADDRESS)

  // 查询是否授权
  const paycoinAllowance = async () => { 
    if(!account) {
      return
    }
    // 查询是否授权
    // console.log('查询是否授权1')
    const maxNum = await getBep20Contract(tokenAdd, web3).methods.allowance(account, getLpAddress()).call()
    // console.log('查询是否授权2', maxNum)
    setIsEmpower(maxNum)
  }

  useEffect(() => { 
    paycoinAllowance()
  }, [account, tokenAdd])

  // 授权
  async function onAttemptToApprove() {
    // 
    // console.log('授权地址', tokenAdd)
    // 授权
    try {
      setIsLoading(true)
      await approve(getBep20Contract(tokenAdd, web3), getLpAddress(), account)
      setIsLoading(false)
      paycoinAllowance()
    } catch (error) {
      setIsLoading(false)
    }
  }

  // 获取 Tokens 数量 view
  const allTokensLength = async () => {
    try {
      // console.log('获取 Tokens 数量 view' )
      const view = await lpContract.methods.allTokensLength().call()
      // console.log('amount1', view)
    } catch (error) {
      // console.log(error)
    }
  }

  // 获取tokens 合约地址 view
  const tokens = async () => {
    try {
      // console.log('获取tokens 合约地址 view',currencyA?.address||'0x55d398326f99059fF775485246999027B3197955', currencyB?.address||'0xB84c120c7B96A08fAB69087B289729E453503E82')
      const view = await lpContract.methods.tokens(currencyA?.address||'0x55d398326f99059fF775485246999027B3197955', currencyB?.address||'0xB84c120c7B96A08fAB69087B289729E453503E82').call()
      // console.log('tokens 合约地址', view)
      setTokenAdd(view)
    } catch (error) {
      // console.log(error)
    }
  }

  // 预估通过 amount0 需要多少 amount1
  const getPerAmount0ToExchangeAmount1 = async (v) => {
    try {
      // console.log('预估通过 amount0 需要多少 amount1', {
      //   token0: currencyA?.address||'0x55d398326f99059fF775485246999027B3197955',
      //   amount0: v,
      //   token1: currencyB?.address||'0xB84c120c7B96A08fAB69087B289729E453503E82',
      // })
      const view = await lpContract.methods.getPerAmount0ToExchangeAmount1(
        currencyA?.address||'0x55d398326f99059fF775485246999027B3197955',
        v,
        currencyB?.address||'0xB84c120c7B96A08fAB69087B289729E453503E82',
      ).call()
      // console.log('token11111',view)
      setToken1Num(new Decimal(view).div(1e18).toFixed())
    } catch (error) {
      // console.log(error)
    }
  }

  // 计算token出以拆到多少 token0 view
  const calcReveseTokenAmount = async (v) => {
    try {
      // console.log('calcReveseTokenAmount:计算token出以拆到多少 token0参数',tokenAdd, v)
      const view = await lpContract.methods.calcReveseTokenAmount(tokenAdd, v).call()
      // console.log('token出以拆到', view)
      setToken0Num(new Decimal(view).div(1e18).toFixed())
      getPerAmount0ToExchangeAmount1(view)
    } catch (error) {
      // console.log(error)
    }
  }

  useEffect(() => { 
    // allTokensLength()
    tokens()
  }, [currencyA?.address, currencyB?.address])

  useUpdateEffect(() => { 
    // console.log('测试', tokenAdd)

    if(currencyB?.address&&currencyA?.address&&tokenAdd) {
      calcReveseTokenAmount(new Decimal(tokenNum||0).mul(1e18).toFixed())
    }
  }, [tokenNum, currencyB?.address, currencyA?.address, tokenAdd])

  // wrapped onUserInput to clear signatures
  const onUserInput = useCallback(
    (field: Field, val: string) => {
      setSignatureData(null)
      return _onUserInput(field, val)
    },
    [_onUserInput]
  )

  const onLiquidityInput = useCallback((val: string): void => onUserInput(Field.LIQUIDITY, val), [onUserInput])
  const onCurrencyAInput = useCallback((val: string): void => onUserInput(Field.CURRENCY_A, val), [onUserInput])
  const onCurrencyBInput = useCallback((val: string): void => onUserInput(Field.CURRENCY_B, val), [onUserInput])

  // tx sending
  const addTransaction = useTransactionAdder()

  async function onRemove() {
    // console.log('burnLPs', tokenAdd, new Decimal(tokenNum||0).mul(1e18).toFixed())
    // burnLPs
    try {
      setIsLoading1(true)
      const view = await lpContract.methods.burnLPs(tokenAdd, new Decimal(tokenNum||0).mul(1e18).toFixed()).send({
        from: account, 
        gasPrice: +result >= 5000000000 ? +result : +result * 1.5,
      })
      toastSuccess('Success', 'Remove Liquidity Success')
      setIsLoading1(false)
      handleDismissConfirmation()
    } catch (error:any) {
      toastWarning('Prompt', error?.message ? error?.message : 'Remove Liquidity fail')
      setIsLoading1(false)
      handleDismissConfirmation()
    }
  }

  function modalHeader() {
    return (
      <AutoColumn gap="md" style={{ marginTop: '20px' }}>
        <RowBetween align="flex-end">
          <Text style={{ color: '#fff' }} color="textSubtle" fontSize="24px">{token0Num}</Text>
          <RowFixed gap="4px">
            <CurrencyLogo currency={currencyA} size="24px" />
            <Text color="card" fontSize="24px" style={{ marginLeft: '10px', color: '#fff' }}>
              {currencyA?.symbol}
            </Text>
          </RowFixed>
        </RowBetween>
        <RowFixed>
          <Plus style={{ color: '#fff' }} size="16" />
        </RowFixed>
        <RowBetween align="flex-end">
          <Text  style={{ color: '#fff' }} color="textSubtle" fontSize="24px">{new Decimal(token0Num||0).mul(10).toFixed()}</Text>
          <RowFixed gap="4px">
            <CurrencyLogo currency={currencyB} size="24px" />
            <Text color="card" fontSize="24px" style={{ marginLeft: '10px', color: '#fff' }}>
              {currencyB?.symbol}
            </Text>
          </RowFixed>
        </RowBetween>

        {/* <Text small color="card" textAlign="left" padding="12px 0 0 0" style={{ fontStyle: 'italic', color: '#fff' }}>
          {`Output is estimated. If the price changes by more than ${allowedSlippage / 100
            }% your transaction will revert.`}
        </Text> */}
      </AutoColumn>
    )
  }

  function modalBottom() {
    return (
      <>
      <Button loading={isLoading1} disabled={+tokenNum<0} 
          style={{height:'38px',color: '#000',background:'#fff',border:'none',opacity:+tokenNum<0?'0.7':''}} onClick={onRemove}>
        {TranslateString(1136, 'Confirm')}
      </Button>
      </>
    )
  }

  const pendingText = `Removing ${parsedAmounts[Field.CURRENCY_A]?.toSignificant(6)} ${currencyA?.symbol
    } and ${parsedAmounts[Field.CURRENCY_B]?.toSignificant(6)} ${currencyB?.symbol}`

  const liquidityPercentChangeCallback = useCallback(
    (value: number) => {
      onUserInput(Field.LIQUIDITY_PERCENT, value.toString())
    },
    [onUserInput]
  )

  const oneCurrencyIsETH = currencyA === ETHER || currencyB === ETHER
  const oneCurrencyIsWETH = Boolean(
    chainId &&
    ((currencyA && currencyEquals(WETH[chainId], currencyA)) ||
      (currencyB && currencyEquals(WETH[chainId], currencyB)))
  )

  const handleSelectCurrencyA = useCallback(
    (currency: Currency) => {
      if (currencyIdB && currencyId(currency) === currencyIdB) {
        history.push(`/remove/${currencyId(currency)}/${currencyIdA}`)
      } else {
        history.push(`/remove/${currencyId(currency)}/${currencyIdB}`)
      }
    },
    [currencyIdA, currencyIdB, history]
  )
  const handleSelectCurrencyB = useCallback(
    (currency: Currency) => {
      if (currencyIdA && currencyId(currency) === currencyIdA) {
        history.push(`/remove/${currencyIdB}/${currencyId(currency)}`)
      } else {
        history.push(`/remove/${currencyIdA}/${currencyId(currency)}`)
      }
    },
    [currencyIdA, currencyIdB, history]
  )

  
  // 获取tokens 合约地址 view
  const tokensn = async () => {
    try {
      // console.log(135,
      //   currencyA?.address||'0x55d398326f99059fF775485246999027B3197955',
      //   currencyB?.address||'0xB84c120c7B96A08fAB69087B289729E453503E82',)
      const view = await lpContract.methods.tokens(
        currencyA?.address||'0x55d398326f99059fF775485246999027B3197955',
        currencyB?.address||'0xB84c120c7B96A08fAB69087B289729E453503E82',).call()
      // console.log('tokensn', view)
      setTokenAdd(view)
      if(account) {
        const res1 = await lpContract.methods.balanceOf(account).call()
        setBalance(new Decimal(res1||0).div(1e18).toNumber())
        // console.log(138, res1)
      }
    } catch (error) {
      // console.log(error)
    }
  }

  useEffect(() => { 
    tokensn()
  }, [account, tokenA?.address, tokenB?.address])

  const handleDismissConfirmation = useCallback(() => {
    setShowConfirm(false)
    setSignatureData(null) // important that we clear signature data to avoid bad sigs
    // if there was a tx hash, we want to clear the input
    setsetTokenNum('')
    setBalance((pre) => pre +1)
    // setTxHash('')
  }, [onUserInput])

  const [innerLiquidityPercentage, setInnerLiquidityPercentage] = useDebouncedChangeHandler(
    Number.parseInt(parsedAmounts[Field.LIQUIDITY_PERCENT].toFixed(0)),
    liquidityPercentChangeCallback
  )  
  
  const handleChange = (e, set) => {
		let val = e.replace(/[^\d.]/g, ''); // 清除“数字”和“.”以外的字符
		val = val.replace(/\.{18,}/g, '.'); // 只保留第一个. 清除多余的
		val = val.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.');
		val = val.replace(/^(-)*(\d+)\.(\d\d\d\d\d\d\d\d\d\d\d\d\d\d\d\d\d\d).*$/, '$1$2.$3'); // 只能输入四个小数
    if(val.indexOf('.')===0) {
      return
    }
    let num = val
    if (num.indexOf('.') < 0 && num !== '') {
			// 以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
			num = parseFloat(num);
		}
    if (+val < 0) {
        num = 0
    }
    // if(num!==''&&num!==null&&+num<new BigNumber(allTermData?.term1?.minAmount || 0).div(DEFAULT_TOKEN_DECIMAL).toNumber()) {
    //   num = new BigNumber(allTermData?.term1?.minAmount || 0).div(DEFAULT_TOKEN_DECIMAL).toNumber()
    // }
    set(num)
  }

  return (
    <div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
      <AppBody>
        <AddRemoveTabs adding={false} />
        <Wrapper>
          <TransactionConfirmationModal
            isOpen={showConfirm}
            onDismiss={handleDismissConfirmation}
            attemptingTxn={attemptingTxn}
            hash={txHash || ''}
            content={() => (
              <ConfirmationModalContent
                title={TranslateString(1156, 'You will receive')}
                onDismiss={handleDismissConfirmation}
                topContent={modalHeader}
                bottomContent={modalBottom}
              />
            )}
            pendingText={pendingText}
          />
          <AutoColumn gap="md">
            <Body style={{ paddingTop: '30px' }}>
              <LpCurrencyInputPanel
                  value={tokenNum}
                  address={tokenAdd}
                  onUserInput={(e)=>{
                    setsetTokenNum(e)
                  }}
                  onMax={() => {
                    onUserInput(Field.LIQUIDITY_PERCENT, '100')
                  }}
                  LpNum={balance}
                  showMaxButton
                  disableCurrencySelect
                  currency={pair?.liquidityToken}
                  pair={pair}
                  id="liquidity-amount"
                />
            </Body>
            {!showDetailed && (
              <>
                <ColumnCenter>
                  <ArrowDown size="16" />
                </ColumnCenter>
                <Body>
                  <OutlineCard>
                    <AutoColumn gap="10px">
                      <RowBetween>
                        <Text fontSize="24px">{token0Num || '-'}</Text>
                        <RowFixed>
                          <CurrencyLogo currency={currencyA} style={{ marginRight: '12px' }} />
                          <Text fontSize="24px" id="remove-liquidity-tokena-symbol">
                            {currencyA?.symbol}
                          </Text>
                        </RowFixed>
                      </RowBetween>
                      <RowBetween>
                        <Text fontSize="24px">{token0Num ? new Decimal(token0Num).mul(10).toFixed() : '-'}</Text>
                        <RowFixed>
                          <CurrencyLogo currency={currencyB} style={{ marginRight: '12px' }} />
                          <Text fontSize="24px" id="remove-liquidity-tokenb-symbol">
                            {currencyB?.symbol}
                          </Text>
                        </RowFixed>
                      </RowBetween>
                      {chainId && (oneCurrencyIsWETH || oneCurrencyIsETH) ? (
                        <RowBetween style={{ justifyContent: 'flex-end' }}>
                          {oneCurrencyIsETH ? (
                            <StyledInternalLink
                              to={`/remove/${currencyA === ETHER ? WETH[chainId].address : currencyIdA}/${currencyB === ETHER ? WETH[chainId].address : currencyIdB
                                }`}
                            >
                              {TranslateString(1188, 'Receive WBNB')}
                            </StyledInternalLink>
                          ) : oneCurrencyIsWETH ? (
                            <StyledInternalLink
                              to={`/remove/${currencyA && currencyEquals(currencyA, WETH[chainId]) ? 'BNB' : currencyIdA
                                }/${currencyB && currencyEquals(currencyB, WETH[chainId]) ? 'BNB' : currencyIdB}`}
                            >
                              {TranslateString(1190, 'Receive BNB')}
                            </StyledInternalLink>
                          ) : null}
                        </RowBetween>
                      ) : null}
                    </AutoColumn>
                  </OutlineCard>
                </Body>
              </>
            )}
            <Body style={{ paddingBottom: '24px' }}>
              {showDetailed && (
                <>
                  <CurrencyInputPanel
                    value={formattedAmounts[Field.LIQUIDITY]}
                    onUserInput={onLiquidityInput}
                    onMax={() => {
                      onUserInput(Field.LIQUIDITY_PERCENT, '100')
                    }}
                    showMaxButton={!atMaxAmount}
                    disableCurrencySelect
                    currency={pair?.liquidityToken}
                    pair={pair}
                    id="liquidity-amount"
                  />
                  <ColumnCenter style={{ padding: '30px 0' }}>
                    <ArrowDown size="22" color='#FF9100' />
                  </ColumnCenter>
                  <CurrencyInputPanel
                    hideBalance
                    value={formattedAmounts[Field.CURRENCY_A]}
                    onUserInput={onCurrencyAInput}
                    onMax={() => onUserInput(Field.LIQUIDITY_PERCENT, '100')}
                    showMaxButton={!atMaxAmount}
                    currency={currencyA}
                    label="Output"
                    onCurrencySelect={handleSelectCurrencyA}
                    id="remove-liquidity-tokena"
                  />
                  <ColumnCenter style={{ padding: '30px 0' }}>
                    <Plus size="16" />
                  </ColumnCenter>
                  <CurrencyInputPanel
                    hideBalance
                    value={formattedAmounts[Field.CURRENCY_B]}
                    onUserInput={onCurrencyBInput}
                    onMax={() => onUserInput(Field.LIQUIDITY_PERCENT, '100')}
                    showMaxButton={!atMaxAmount}
                    currency={currencyB}
                    label="Output"
                    onCurrencySelect={handleSelectCurrencyB}
                    id="remove-liquidity-tokenb"
                  />
                </>
              )}
              {pair && (
                <div style={{ padding: '24px' }}>
                  <Flex justifyContent="space-between" mb="8px">
                    Price:
                    <div>
                      1 {currencyA?.symbol} = {tokenA ? pair.priceOf(tokenA).toSignificant(6) : '-'} {currencyB?.symbol}
                    </div>
                  </Flex>
                  <Flex justifyContent="space-between">
                    <div />
                    <div>
                      1 {currencyB?.symbol} = {tokenB ? pair.priceOf(tokenB).toSignificant(6) : '-'} {currencyA?.symbol}
                    </div>
                  </Flex>
                </div>
              )}
              <div style={{ position: 'relative' }}>
                {!account ? (
                  // <ConnectWalletButton width="100%" />
                  <Login
                  setIsModalOpen={setIsModalOpen}
                  isModalOpen={isModalOpen}
              >
                <div style={{color: '#000', background: '#fff', width: '340px', height: '56px' }} className={account ? 'styledHeaderTextC corsor-pointer' : 'styledHeaderTextC-hover styledHeaderTextC corsor-pointer'}>Connect Wallet</div>
              </Login>
                ) : (
                  <RowBetween>
                    <Button loading={isLoading}
                      disabled={(+isEmpower?!(+isEmpower<new Decimal(tokenNum||0).mul(1e18).toNumber()):false)}
                      style={{height: '38px',color: '#000',background:'#fff',border:'none', opacity: (+isEmpower?!(+isEmpower<new Decimal(tokenNum||0).mul(1e18).toNumber()):false)? '0.7':''}}
                      onClick={onAttemptToApprove}
                    >
                      Approve
                    </Button>
                    <Button loading={isLoading1}
                      style={{height: '38px',color: '#000',background:'#fff',border:'none',opacity:(+isEmpower?(+isEmpower<new Decimal(tokenNum||0).mul(1e18).toNumber()):true)||(+lpBalance?(+lpBalance<new Decimal(tokenNum||0).mul(1e18).toNumber()):true)||+tokenNum===0?'0.7':''}}
                      onClick={() => {
                        setShowConfirm(true)
                      }}
                      disabled={
                        (+isEmpower?(+isEmpower<new Decimal(tokenNum||0).mul(1e18).toNumber()):true)||(+lpBalance?(+lpBalance<new Decimal(tokenNum||0).mul(1e18).toNumber()):true)||+tokenNum===0
                      }
                    >
                      Remove
                    </Button>
                  </RowBetween>
                )}
              </div>
            </Body>
          </AutoColumn>
        </Wrapper>
      </AppBody>

      {pair ? (
        <AutoColumn style={{ minWidth: '20rem', marginTop: '1rem' }}>
          <MinimalPositionCard showUnwrapped={oneCurrencyIsWETH} pair={pair} />
        </AutoColumn>
      ) : null}
    </div>
  )
}
