import styled from 'styled-components';

const StyleBase = styled.div``

const StyledCardContainer = styled(StyleBase)<{isMobile?: boolean }>`
    display: flex;
    flex-direction: column;
    width: ${({isMobile}) => isMobile ?  '100%' : '580px' };
    border-radius: ${({isMobile}) => isMobile ?  '0.8rem' : '25px' };
    background: #232323;
`
const StyledCardHeader = styled(StyleBase)<{isMobile?: boolean }>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: ${({isMobile}) => isMobile ?  '0.8rem 1rem' : '20px 40px ' };
    height:  ${({isMobile}) => isMobile ?  '' : '85px ' };
    border-bottom: 1px solid #707070;
    flex-direction: ${({isMobile}) => isMobile ?  'column' : '' };
    color: white;
    h2{
        margin-bottom: 6px;
        font-size: 22px;
        font-family: Nirmala UI-Bold;
        font-weight: 400;
        color: #FFFFFF;
        width: ${({isMobile}) => isMobile ?  '100%' : '' };
        text-align: ${({isMobile}) => isMobile ?  'start' : '' };
    }
    .draw-time{
        font-size: ${({isMobile}) => isMobile ?  '20px' : '14px' };
        font-family: Nirmala UI-Bold;
        font-weight: 400;
        color: #FFFFFF;
        margin-bottom: 3px;
    }
`
const StyledTitle = styled(StyleBase)<{isMobile?: boolean }>`
    // margin-right: 20px;
    font-size: ${({isMobile}) => isMobile ?  '0.8rem' : '20px' };
    font-weight: bold;
    line-height: 20px;
    color: #FFFFFF;
    text-align: center;
`
const StyledBigNum = styled(StyleBase)<{isMobile?: boolean }>`
    margin-top: 12px;
    margin-bottom: 12px;
    font-size: ${({isMobile}) => isMobile ?  '1.2rem' : '28px' };
    font-weight: bold;
    color: #FFF;
    text-align: center;
    white-space: nowrap;
`

const StyledSum = styled(StyleBase)<{isMobile?: boolean }>`
    font-size: ${({isMobile}) => isMobile ?  '0.8rem' : '16px' };
    font-weight: normal;
    color: #999999;
    text-align: center;
`
const StyledNodata = styled.div`
    height: 120px;
    text-align: center;
    font-size: 16px;
    color: white;
    line-height: 120px;
`
const StyledMore = styled(StyleBase)<{isMobile?: boolean }>`
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
    height: 54px;
    line-height: 54px;
    font-weight: bold;
    font-size: ${({isMobile}) => isMobile ?  '1rem' : '16px' };
    line-height: 21px;
    color: #EEEEEE;
    text-align: center;
    cursor: pointer;
    img {
        width: 30px;
    }
`

const StyledPrize = styled(StyleBase)<{isMobile?: boolean }>`
    padding-top: 20px;
    .flex{
        display: flex;
        justify-content: center;
        font-weight: normal;
        align-items: center;
    }
`

const StyledTickets = styled(StyleBase)<{isMobile?: boolean }>`
    .your-tickets{
        align-items: center;
        padding-bottom: 30px;
        color: white;
        .title{
            margin-right: ${({isMobile}) => isMobile ?  '1rem' : '60px' };
            font-size: ${({isMobile}) => isMobile ?  '1rem' : '18px' };
            font-weight: bold;
        }
        .button{
            .desc{
                font-size: ${({isMobile}) => isMobile ?  '16px' : '16px' };
                border-radius: 12px 12px 12px 12px;
                opacity: 1;
                font-family: Nirmala UI-Regular, Nirmala UI;
                font-weight: 400;
                line-height: 32px;
            }
            .view-ticket{
                margin-top: 10px;
                cursor: pointer;
                font-size: ${({isMobile}) => isMobile ?  '0.8rem' : '16px' };
                color: #FFF;
            }
        }
    }
`

const StyledPrizePotContainer = styled(StyleBase)<{isMobile?: boolean }>`
    .post-container{
        display: flex;
        flex-direction: ${({isMobile}) => isMobile ?  'column' : 'row' };
        justify-content: space-between;
        align-items: ${({isMobile}) => isMobile ?  'flex-start' : 'center' };
        padding:  ${({isMobile}) => isMobile ?  '0.8rem' : '26px 0 0 32px' };
        background: #232323;
        border-bottom: 1px solid #355595;
        .your-tickets{
            display: block;
        }
        .title{
            margin-bottom: 10px;
        }
        
        .right{
            margin-top: ${({isMobile}) => isMobile ?  '1rem' : '20px' };
            width: ${({isMobile}) => isMobile ?  '100%' : '300px' };
            padding-right: ${({isMobile}) => isMobile ?  '1rem' : '20px' };
            color: white;
            .info{
                margin-bottom: ${({isMobile}) => isMobile ?  '1rem' : '42px' };
                font-size: 14px;
                text-align: left;
            }
        }
    }
    .next-container{
        .your-tickets{
            display : flex;
        }
    }
`

export { StyledCardContainer, StyledCardHeader, StyledTitle, StyledTickets, StyledBigNum, StyledPrize,  StyledSum, StyledMore, StyledNodata, StyledPrizePotContainer }