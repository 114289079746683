import { Currency, ETHER, Token } from '@dreamfinance-libs/sdk'
import React, { useMemo } from 'react'
import styled from 'styled-components'
import Logo from '../Logo'
import CoinLogo from '../pancake/CoinLogo'

const getTokenLogoURL = (address: string) => `https://dreamfinance.s3.ap-northeast-1.amazonaws.com/assets/images/coins/${address}.png`

const StyledBnbLogo = styled.img<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.075);
  border-radius: 24px;
`

const StyledLogo = styled(Logo) <{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
`

export default function CurrencyLogo({
  currency,
  size = '24px',
  style,
}: {
  currency?: Currency
  size?: string
  style?: React.CSSProperties
}) {

  const srcs: string[] = useMemo(() => {
    if (currency === ETHER) return []
    if (currency instanceof Token) {
      return [`https://dreamfinance.s3.ap-northeast-1.amazonaws.com/assets/images/coins/${currency?.symbol?.toLowerCase()}.png`, `https://dreamfinance.s3.ap-northeast-1.amazonaws.com/assets/images/coins/${currency?.address.toLowerCase() ?? 'token'}.png`, getTokenLogoURL(currency.address)]
    }
    return []
  }, [currency])
  if (currency === ETHER) {
    return <StyledBnbLogo src="https://dreamfinance.s3.ap-northeast-1.amazonaws.com/assets/images/coins/bnb.png" size={size} style={style} />
  }
  return (currency as any)?.symbol ? (

    <CoinLogo size={size} srcs={srcs} alt={`${currency?.symbol ?? 'token'} logo`} style={style} />
  ) : (
    <StyledLogo size={size} srcs={srcs} alt={`${currency?.symbol ?? 'token'} logo`} style={style} />
  )
}
