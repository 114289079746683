/* eslint-disable react/self-closing-comp */
/* eslint-disable react/no-unknown-property */
/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/ban-types */

import React, { FC, useState, forwardRef, useImperativeHandle, useEffect } from "react";
import { DivideSquare } from "react-feather";
import { useHistory } from "react-router-dom";

interface MenuProps {
  changeData: Function
  isFarms: any
}

const Menu: FC<MenuProps> = (props) => {
  const { changeData, isFarms } = props
  const [staked, setSteaked] = useState<boolean>(false) // Staked only
  const [pageType, setPageType] = useState<number>(2) // 1 card 2 list
  const [selectType, setSelectType] = useState<string>('Level')  // Sort By
  const [status, setStatus] = useState<boolean>(true) // true: live false: finished
  const [showSelect, setShowSelect] = useState<boolean>(false)
  const [searchValue, setsearchValue] = useState<string>('') // search
  const history = useHistory();

  const searchChaneg = (e: React.ChangeEvent<HTMLInputElement>) => {
    setsearchValue(e.target.value)
  }
  useEffect(
    () => {
      changeData({ staked, pageType, selectType, status, searchValue})
    },
    [staked, pageType, selectType, status]
  )
  const selectFunc = (e: React.MouseEvent) => {
    stopPropagation(e)
    setShowSelect(!showSelect)
  }
  const setShowSelectFun = () => setShowSelect(false)
  useEffect(() => {
    window.addEventListener("click", setShowSelectFun);
    return () => window.removeEventListener("click", setShowSelectFun);
  });
  const stopPropagation = (e: React.MouseEvent) => {
    e.nativeEvent.stopImmediatePropagation();
  }

  const keyDown = (event: React.KeyboardEvent) => {
    if (event.keyCode == 13) {
      changeData({ staked, pageType, selectType, status, searchValue })
    }
  }

  // useEffect(() => { 
  //   if(status) {
  //     history.push('/farms')
  //   } else {
  //     history.push('/farms/archived')
  //   }
  // }, [status])

  return (
    <div>
      <div className="hidden lg:block">
        <div className="container max-w-screen-xl mx-auto pool-select h-24 flex justify-start items-center ">
          <div className="flex justify-center items-center">
            <div style={{width:200}} className="staked-box flex justify-center items-center">
              <div className="text-text1 text-4xl mr-2">Staked only</div>
              <div className={`cursor-pointer staked relative ${staked ? 'staked-check' : ''}`} onClick={() => setSteaked(!staked)}>
                <span className="staked-round transition-all duration-200" />
              </div>
            </div>
            <div style={{width:200}} className="staked-box flex justify-center items-center">         
              <span className="text-text1 text-4xl mr-2">Sort By</span>
              <div style={{border: '1px solid #323232'}}  className="select-box relative flex justify-start items-center cursor-pointer px-4 h-8 border border-line rounded-90" onClick={(e) => selectFunc(e)}>
                <span className="w-24 text-text2 text-4xl">{selectType}</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg" width="6" height="3.667" viewBox="0 0 6 3.667" fill="#777e90">
                  <g transform="translate(-5.001 -6.334)">
                    <path className="a" d="M3.471-.2a.667.667,0,0,0,0-.943L1.609-3,3.471-4.862a.667.667,0,0,0,0-.943.667.667,0,0,0-.943,0L.2-3.471a.667.667,0,0,0,0,.943L2.529-.2A.667.667,0,0,0,3.471-.2Z" transform="translate(11.001 10.001) rotate(-90)" />
                  </g>
                </svg>

                {
                  showSelect && (
                    <div className="flex flex-col justify-around items-start absolute left-0 top-8 bg-black2 py-4 px-5 text-4xl text-text1 rounded-8 z-10 w-full">
                    <span className="hover:text-text2 cursor-pointer" style={{whiteSpace: 'nowrap'}} onClick={() => setSelectType('Level')}>Level</span>
                      <span className="hover:text-text2 cursor-pointer mt-2" onClick={() => setSelectType('APY')}>APY</span>
                      <span className="hover:text-text2 cursor-pointer mt-2" style={{whiteSpace: 'nowrap'}} onClick={() => setSelectType('Staked')}>Staked</span>
                    </div>
                  )
                }
              </div>
            </div>
            {/* <div className="text-text3 radio-box flex justify-start items-center">
              <span className={`w-20 h-7 text-3xl flex justify-center items-center rounded-tl-90 rounded-bl-90 border border-solid border-btn cursor-pointer ${status ? 'bg-btn' : ''}`} onClick={() => setStatus(true)}>Live</span>
              <span className={`w-20 h-7 text-3xl flex justify-center items-center rounded-tr-90 rounded-br-90 border border-solid border-btn cursor-pointer ${!status ? 'bg-btn' : ''}`} onClick={() => setStatus(false)}>Finished</span>
            </div> */}
          </div>
          {
            isFarms && localStorage.getItem('add') &&
            <div onClick={()=> {
              if(localStorage.getItem('add')) {
                history.push(localStorage.getItem('add'))
              }
            }} className="flex justify-start items-center">
                <div style={{
                  color: '#fff',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '137px',
                  height: '35px',
                  borderRadius: '6px',
                  background: '#0072ED',
                  fontSize:'14px',
                  cursor: 'pointer'
                }}>Add Liquidity →</div>
              </div>
          }
        </div>
      </div>
      <div className="block lg:hidden px-8 py-10">
        <div className="flex justify-between items-center w-full my-8">
          <div className="staked-box flex justify-start items-center">
            <div className="text-text1 text-4xl mr-2">Staked only</div>
            <div className={` staked relative ${staked ? 'staked-check' : ''}`} onClick={() => setSteaked(!staked)}>
              <span className="staked-round transition-all duration-200" />
            </div>
          </div>
        </div>
        <div className="flex justify-between items-center w-full">
          <div className="staked-box flex justify-start items-center">
            <div className="text-text1 text-4xl mr-2">Sort By</div>
            <div style={{border: '1px solid #323232'}}  className="select-box mr-16 relative flex justify-start items-center cursor-pointer px-4 h-8 border border-line rounded-90" onClick={(e) => selectFunc(e)}>
              <span className="w-24 text-text2 text-4xl">{selectType}</span>
              <svg
                xmlns="http://www.w3.org/2000/svg" width="6" height="3.667" viewBox="0 0 6 3.667" fill="#777e90">
                <g transform="translate(-5.001 -6.334)">
                  <path className="a" d="M3.471-.2a.667.667,0,0,0,0-.943L1.609-3,3.471-4.862a.667.667,0,0,0,0-.943.667.667,0,0,0-.943,0L.2-3.471a.667.667,0,0,0,0,.943L2.529-.2A.667.667,0,0,0,3.471-.2Z" transform="translate(11.001 10.001) rotate(-90)" />
                </g>
              </svg>

              {
                showSelect && (
                  <div className="flex flex-col justify-around items-start absolute left-0 top-8 bg-black2 py-4 px-5 text-4xl text-text1 rounded-8 z-10 w-full">
                    <span className="hover:text-text2 cursor-pointer" onClick={() => setSelectType('Level')}>Level</span>
                    <span className="hover:text-text2 cursor-pointer mt-2" style={{whiteSpace: 'nowrap'}} onClick={() => setSelectType('APY')}>APY</span>
                    <span className="hover:text-text2 cursor-pointer mt-2" style={{whiteSpace: 'nowrap'}} onClick={() => setSelectType('Staked')}>Staked</span>
                  </div>
                )
              }
            </div>
          </div>
        </div>
      </div>
      <div className="border-solid border-b border-line" />
    </div>
  )
}

export default Menu