

import styled from 'styled-components';

const StyleBase = styled.div``


const NewModalInput = styled(StyleBase)<{isMobile?: boolean}>`
`


// eslint-disable-next-line import/prefer-default-export
export { NewModalInput
}