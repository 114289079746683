/* eslint-disable no-extra-boolean-cast */
/* eslint-disable no-nested-ternary */
import styled from 'styled-components';

const StyleBase = styled.div``


const StyleContainer =  styled(StyleBase)<{isMobile?: boolean }>`
    padding: 14px;
    button {
        border-radius: ${({isMobile}) => isMobile ?  '24px' : '16px' };
    }
`
const TabMain =  styled(StyleBase)<{isMobile?: boolean }>`

`
const NFTs =  styled(StyleBase)<{isMobile?: boolean }>`
    .card-box {
        margin-top: ${({isMobile}) => isMobile ?  '62px' : '24px' };
        display: flex;
        flex-direction: ${({isMobile}) => isMobile ?  'row' : 'column' };
        justify-content: space-between;
        align-items: center;
        .card {
            .img {
                display: flex;
                justify-content: center;
                align-items: center;
                img {
                    width: 282px;
                    height: 282px;
                }
                img {
                    width: 282px;
                    height: 282px;
                }
            }
            .title {
                text-align: center;
                margin-top: 24px;
                margin-bottom: ${({isMobile}) => isMobile ?  '0' : '40px' };
                font-size: 16px;
                font-family: Nirmala UI-Regular, Nirmala UI;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 21px;
            }
        }
    }
    .describe {
        margin-top: ${({isMobile}) => isMobile ?  '80px' : '24px' };
        .title {
            text-align: center;
            font-size: 20px;
            font-family: Nirmala UI-Regular, Nirmala UI;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 21px;

        }
        .content {
            margin-top: ${({isMobile}) => isMobile ?  '32x' : '45px' };
            font-size: 14px;
            font-family: Nirmala UI-Regular, Nirmala UI;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 21px;
        }
    }
    .buy-box {
        margin-top: ${({isMobile}) => isMobile ?  '62px' : '24px' };
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: ${({isMobile}) => isMobile ?  'column' : 'column' };
        .img {
            display: flex;
            justify-content: center;
            align-items: center;
            video {
                width: ${({isMobile}) => isMobile ?  '480px !important' : '100%' };
                height: ${({isMobile}) => isMobile ?  '480px !important' : 'auto' };
                max-width: ${({isMobile}) => isMobile ?  '' : '343px' };
            }
            img {
                width: ${({isMobile}) => isMobile ?  '480px !important' : '100%' };
                height: ${({isMobile}) => isMobile ?  '480px !important' : 'auto' };
                max-width: ${({isMobile}) => isMobile ?  '' : '343px' };
            }
        }
        .pb-box {
            display: flex;
            justify-content: space-between;        
            margin-top: ${({isMobile}) => isMobile ?  '32px' : '20px' };
            width: ${({isMobile}) => isMobile ?  '480px' : '100%' };
            font-size: 14px;
            font-family: Nirmala UI-Regular, Nirmala UI;
            font-weight: 400;
            color: #EEEEEE;
            line-height: 32px;
        }
        .ipt-box {
            position: relative;
            display: flex;
            justify-content: space-between;   
            margin-top: ${({isMobile}) => isMobile ?  '65px' : '24px' };
            width: ${({isMobile}) => isMobile ?  '480px' : '100%' };
            font-size: 14px;
            font-family: Nirmala UI-Regular, Nirmala UI;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 32px;
            input {
                width: ${({isMobile}) => isMobile ?  '425px' : '293px' };
                height: 46px;
                background: #232323;
                border-radius: 12px 12px 12px 12px;
                opacity: 1;
                border: 1px solid #8B8B8B;
                color: #FFFFFF;
            }
            .ipt-USDT {
                position: absolute;
                top: ${({isMobile}) => isMobile ?  '7px' : '7px' };
                right: 70px;
                color: #8A8A8A;
            }
            .nfts {
                display: flex;
                justify-content: center;
                align-items: center;
            }

            input::-webkit-input-placeholder {
              color: #8A8A8A;
            }
            input:-moz-placeholder {
              color: #8A8A8A;
            }
            input::-moz-placeholder {
              color: #8A8A8A;
            }
            input:-moz-input-placeholder {
              color: #8A8A8A;
            }
        }
        .btn {
            margin-top: ${({isMobile}) => isMobile ?  '32px' : '24px' };
            button {
                width: 243px;
                height: 56px;
                background: #FFFFFF;
                border-radius: 12px 12px 12px 12px;
                font-size: 16px;
                font-family: Nirmala UI-Regular, Nirmala UI;
                font-weight: 400;
                color: #000000;
                line-height: 32px;
            }
        }
    }
`

const BOXs =  styled(StyleBase)<{isMobile?: boolean }>`
    margin-top: ${({isMobile}) => isMobile ?  '62px' : '24px' };
    display: flex;
    flex-direction: ${({isMobile}) => isMobile ?  'row' : 'column' };
    .left {
       .img {
        display: flex;
        justify-content: center;
        align-items: center;
        video {
            width: ${({isMobile}) => isMobile ?  '480px' : '100%' };
            height: ${({isMobile}) => isMobile ?  '480px' : 'auto' };
            max-width: ${({isMobile}) => isMobile ?  '' : '343px' };
        }
        img {
            width: ${({isMobile}) => isMobile ?  '480px' : '100%' };
            height: ${({isMobile}) => isMobile ?  '480px' : 'auto' };
            max-width: ${({isMobile}) => isMobile ?  '' : '343px' };
        }
       } 
       .title {
            text-align: center;
            margin-top: ${({isMobile}) => isMobile ?  '24px' : '20px' };
            font-size: 14px;
            font-family: Nirmala UI-Regular, Nirmala UI;
            font-weight: 400;
            color: #EEEEEE;
            line-height: 32px;
       }
    }
    .right {
        flex: ${({isMobile}) => isMobile ?  '1' : '' };
        display: flex;
        flex-direction: ${({isMobile}) => isMobile ?  'column' : 'column' };
        .describe {
            margin-left: ${({isMobile}) => isMobile ?  '80px' : '0' };
            margin-top: ${({isMobile}) => isMobile ?  '' : '24px' };
            flex: ${({isMobile}) => isMobile ?  '1' : '' }; 
        }
        .btn {
            display: flex;
            justify-content: center;
            flex-direction: ${({isMobile}) => isMobile ?  'row' : 'column' };
            margin-bottom: ${({isMobile}) => isMobile ?  '55px' : '' };
            button {
                color: #EFEFEF;
                margin-top: ${({isMobile}) => isMobile ?  '' : '32px' };
                width: ${({isMobile}) => isMobile ?  '170px' : '100%' };
                height: ${({isMobile}) => isMobile ?  '56px' : '40px' };
                margin-right: ${({isMobile}) => isMobile ?  '35px' : '0' };
            }
        }
    }
`
const Gallery =  styled(StyleBase)<{isMobile?: boolean }>`
    margin-top: ${({isMobile}) => isMobile ?  '22px' : '24px' };
    height: ${({isMobile}) => isMobile ?  '1028px' : '1328px' };
    .n-box {
        width: 100px;
        display: flex;
        justify-content: space-between;
        align-items: center;    
        font-size: 14px;
        font-family: Nirmala UI-Bold;
        font-weight: 400;
        color: #494747;
        >div {
            display: flex;
            justify-content: center;
            align-items: center;  
            width: 37px;
            height: 22px;
            border-radius: 4px 4px 4px 4px;
            opacity: 1;
            border: 1px solid #494747;
        }
        .action {
            color: #E6E6E6;
            border: 1px solid #FFFFFF;
        }
    }
    .tab {
        display: flex;
        justify-content: space-between;
        align-items: center;
        >div {
            // width: 86px;
            height: 40px;
            border-radius: 4px 4px 4px 4px;
        }  
        .top-8 {
            top: 2.6rem;
        }
        .mr-16 {
            margin-right: 10px;
        }
    }
    .content {
        margin-top: ${({isMobile}) => isMobile ?  '24px' : '12px' };
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        .img {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-bottom: ${({isMobile}) => isMobile ?  '24px' : '24px' };
            width: ${({isMobile}) => isMobile ?  '25%' : '50%' };
            min-height: ${({isMobile}) => isMobile ?  '421px' : '284px' };
            video {
                width: ${({isMobile}) => isMobile ?  '282px' : '90%' };
                max-width: ${({isMobile}) => isMobile ?  '282px' : '166px' };
                height: 282px;
            }
            img {
                width: ${({isMobile}) => isMobile ?  '282px' : '90%' };
                max-width: ${({isMobile}) => isMobile ?  '282px' : '166px' };
                height: auto;
            }
        }
    }
    .no-Data {
        text-align: center;
        height: 500px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .page {
        margin-top: ${({isMobile}) => isMobile ?  '42px' : '12px' };
        display: flex;
        justify-content: center;
        align-items: center;
        .ant-pagination-item-link {
            opacity: 1;
            border: 1px solid #707070;
            color: #707070 !important;
        }
        .ant-pagination-item {
            opacity: 1;
            border: 1px solid #707070;
            a {
                color: #707070 !important;
            }
        }
        .ant-pagination-disabled {
            button {
                color: #6d6d6d !important;
                border: 1px solid #6d6d6d !important;

            }
        }
    }
    .buy-box {
        border-radius: 20px;
        padding-top: ${({isMobile}) => isMobile ?  '' : '50px' };
        padding-bottom: ${({isMobile}) => isMobile ?  '24px' : '24px' };
        display: flex;
        justify-content: center;
        align-items: center;
        background: #232323;
        position: relative;
        flex-direction: ${({isMobile}) => isMobile ?  'column' : 'column' };
        .title {
            position: ${({isMobile}) => isMobile ?  '' : '' };
            top: ${({isMobile}) => isMobile ?  '' : '-34px' };
            left: ${({isMobile}) => isMobile ?  '' : '0' };
            padding-bottom: ${({isMobile}) => isMobile ?  '' : '15px' };
            margin-top: ${({isMobile}) => isMobile ?  '24px' : '' };
            font-size: 16px;
            font-family: Nirmala UI-Bold;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 32px;
        }
        .img {
            margin-top: ${({isMobile}) => isMobile ?  '32px' : '' };
            display: flex;
            justify-content: center;
            align-items: center;
            video {
                width: ${({isMobile}) => isMobile ?  '480px !important' : '100%' };
                height: ${({isMobile}) => isMobile ?  '480px !important' : 'auto' };
                max-width: ${({isMobile}) => isMobile ?  '' : '343px' };
            }
            img {
                width: ${({isMobile}) => isMobile ?  '480px !important' : '100%' };
                height: ${({isMobile}) => isMobile ?  '480px !important' : 'auto' };
                max-width: ${({isMobile}) => isMobile ?  '' : '343px' };
            }
        }
        .btn {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction:  ${({isMobile}) => isMobile ?  'row' : 'column' };
            margin-top: ${({isMobile}) => isMobile ?  '44px' : '24px' };
            width:  ${({isMobile}) => isMobile ?  '' : '100%' };
            button {
                margin-top: ${({isMobile}) => isMobile ?  '' : '32px' };
                width:  ${({isMobile}) => isMobile ?  '220px' : '90%' };
                height: ${({isMobile}) => isMobile ?  '56px' : '40px' };
                border-radius: 12px 12px 12px 12px;
                opacity: 1;
                border: 1px solid #EFEFEF;
                font-size: 16px;
                font-family: Nirmala UI-Regular, Nirmala UI;
                font-weight: 400;
                color: #EFEFEF;
                line-height: 32px;
            }
        }
    }
`

const BuyConfirm =  styled(StyleBase)<{isMobile?: boolean }>`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: ${({isMobile}) => isMobile ?  '544px' : '100%' };
    padding:  ${({isMobile}) => isMobile ?  '' : ' 24px 16px' };
    height: ${({isMobile}) => isMobile ?  '' : '' };
    background: #232323;
    color: #EEEEEE;
    padding-bottom: 55px;
    input {
        background: #1A1A1A;
        border-radius: 12px 12px 12px 12px;
        border: 1px solid #323232 !important;
        color: #8A8A8A;
    }
    input::-webkit-input-placeholder {
      color: #8A8A8A;
    }
    input:-moz-placeholder {
      color: #8A8A8A;
    }
    input::-moz-placeholder {
      color: #8A8A8A;
    }
    input:-moz-input-placeholder {
      color: #8A8A8A;
    }
    .input-box {
      margin-top: ${({isMobile}) => isMobile ?  '22px' : '15px' };
      width: ${({isMobile}) => isMobile ?  '' : '100%' };
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: ${({isMobile}) => isMobile ?  'column' : 'row' };

    }
    .title{
        margin-top: ${({isMobile}) => isMobile ?  '68px' : '' };
        text-align: center;
        font-size: 20px;
        font-family: Nirmala UI-Regular, Nirmala UI;
        font-weight: 400;
        color: #EEEEEE;
        line-height: 32px;
    }
    .nft-box {
        margin-top: ${({isMobile}) => isMobile ?  '40px' : '25px' };
        width: ${({isMobile}) => isMobile ?  '360px' : '100%' };
        display: flex;
        justify-content: space-between;
        font-size: 16px;
        font-family: Nirmala UI-Regular, Nirmala UI;
        font-weight: 400;
        color: #EEEEEE;
        line-height: 32px;
    }
    .amount-box {
        margin-top: ${({isMobile}) => isMobile ?  '24px' : '14px' };
        width: ${({isMobile}) => isMobile ?  '360px' : '100%' };
        display: flex;
        justify-content: space-between;
        font-size: 16px;
        font-family: Nirmala UI-Regular, Nirmala UI;
        font-weight: 400;
        color: #EEEEEE;
        line-height: 32px;
    }
    .btn {
      width: ${({isMobile}) => isMobile ?  '' : '100%' };
      margin-top: 16px;
      button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: ${({isMobile}) => isMobile ?  '340px' : '100%' };
        height: 56px;
        background: #FFFFFF;
        border-radius: 12px 12px 12px 12px;
        font-size: 16px;
        font-family: Nirmala UI-Regular, Nirmala UI;
        font-weight: 400;
        color: #000000;
      }
    }
    .btn-change {
      margin-top: ${({isMobile}) => isMobile ?  '72px' : '40px' };
      display: flex;
      width: ${({isMobile}) => isMobile ?  '400px' : '100%' };
      justify-content: space-between;
      flex-direction: ${({isMobile}) => isMobile ?  'row' : 'row' };      
      button {
        width: ${({isMobile}) => isMobile ?  '170px' : '128px' };
        height: 56px;
        border-radius: 12px 12px 12px 12px;
        opacity: 1;
      }
      .Cancle {
        background: #F8C6C6;
        color: #B11213;
        margin-bottom: ${({isMobile}) => isMobile ?  '' : '15px' };
      }
    }
`
const MdfBuyConfirm =  styled(StyleBase)<{isMobile?: boolean }>`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: ${({isMobile}) => isMobile ?  '544px' : '100%' };
    padding:  ${({isMobile}) => isMobile ?  '' : ' 24px 16px' };
    height: ${({isMobile}) => isMobile ?  '393px' : '' };
    background: #232323;
    color: #EEEEEE;
    .title{
        margin-top: ${({isMobile}) => isMobile ?  '68px' : '' };
        text-align: center;
        font-size: 20px;
        font-family: Nirmala UI-Regular, Nirmala UI;
        font-weight: 400;
        color: #EEEEEE;
        line-height: 32px;
    }
    .nft-box {
        margin-top: ${({isMobile}) => isMobile ?  '40px' : '25px' };
        width: ${({isMobile}) => isMobile ?  '360px' : '100%' };
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 16px;
        font-family: Nirmala UI-Regular, Nirmala UI;
        font-weight: 400;
        color: #EEEEEE;
        line-height: 32px;
        input {
            background: rgba(0,0,0,0);
            width: ${({isMobile}) => isMobile ?  '279px' : '100%' };
            height: ${({isMobile}) => isMobile ?  '46px' : '40px' };
            border-radius: 12px 12px 12px 12px;
            opacity: 1;
            border: 1px solid #8B8B8B;
            color: #B2B2B2;
        }
    }
    .amount-box {
        margin-top: ${({isMobile}) => isMobile ?  '24px' : '14px' };
        width: ${({isMobile}) => isMobile ?  '360px' : '100%' };
        display: flex;
        justify-content: space-between;
        font-size: 16px;
        font-family: Nirmala UI-Regular, Nirmala UI;
        font-weight: 400;
        color: #EEEEEE;
        line-height: 32px;
    }
    .btn {
      width: ${({isMobile}) => isMobile ?  '' : '100%' };
      margin-top: 16px;
      button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: ${({isMobile}) => isMobile ?  '340px' : '100%' };
        height: 56px;
        background: #FFFFFF;
        border-radius: 12px 12px 12px 12px;
        font-size: 16px;
        font-family: Nirmala UI-Regular, Nirmala UI;
        font-weight: 400;
        color: #000000;
      }
    }
    .btn-change {
      margin-top: ${({isMobile}) => isMobile ?  '72px' : '40px' };
      display: flex;
      width: ${({isMobile}) => isMobile ?  '400px' : '100%' };
      justify-content: space-between;
      flex-direction: ${({isMobile}) => isMobile ?  'row' : 'row' };      
      button {
        width: ${({isMobile}) => isMobile ?  '170px' : '128px' };
        height: 56px;
        border-radius: 12px 12px 12px 12px;
        opacity: 1;
      }
      .Cancle {
        background: #F8C6C6;
        color: #B11213;
        margin-bottom: ${({isMobile}) => isMobile ?  '' : '15px' };
      }
    }
`
const BoxBuyConfirm =  styled(StyleBase)<{isMobile?: boolean }>`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: ${({isMobile}) => isMobile ?  '544px' : '100%' };
    padding:  ${({isMobile}) => isMobile ?  '' : ' 24px 16px' };
    height: ${({isMobile}) => isMobile ?  '393px' : '' };
    background: #232323;
    color: #EEEEEE;
    .title {
        margin-top: ${({isMobile}) => isMobile ?  '132px' : '68px' };
        font-size: 16px;
        font-family: Nirmala UI-Regular, Nirmala UI;
        font-weight: 400;
        color: #EEEEEE;
        line-height: 32px;
    }
    .btn {
      width: ${({isMobile}) => isMobile ?  '' : '100%' };
      margin-top: 16px;
      button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: ${({isMobile}) => isMobile ?  '340px' : '100%' };
        height: 56px;
        background: #FFFFFF;
        border-radius: 12px 12px 12px 12px;
        font-size: 16px;
        font-family: Nirmala UI-Regular, Nirmala UI;
        font-weight: 400;
        color: #000000;
      }
    }
    .btn-change {
      margin-top: ${({isMobile}) => isMobile ?  '120px' : '81px' };
      display: flex;
      width: ${({isMobile}) => isMobile ?  '400px' : '100%' };
      justify-content: space-between;
      flex-direction: ${({isMobile}) => isMobile ?  'row' : 'row' };      
      button {
        width: ${({isMobile}) => isMobile ?  '170px' : '128px' };
        height: 56px;
        border-radius: 12px 12px 12px 12px;
        opacity: 1;
      }
      .Cancle {
        background: #F8C6C6;
        color: #B11213;
        margin-bottom: ${({isMobile}) => isMobile ?  '' : '15px' };
      }
    }
`


// eslint-disable-next-line import/prefer-default-export
export { StyleContainer, TabMain, NFTs, BOXs, Gallery, BuyConfirm, BoxBuyConfirm, MdfBuyConfirm }