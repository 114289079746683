/* eslint-disable import/named */
import React from 'react'
import { Web3ReactProvider, createWeb3ReactRoot } from '@web3-react/core'
import { Provider } from 'react-redux'
import { ThemeContextProvider } from 'contexts/ThemeContext'
import { getLibrary, getLibraryB } from 'utils/web3React'
import store from 'state'
import { NetworkContextName } from './constants/index'

const Web3ProviderNetwork = createWeb3ReactRoot(NetworkContextName)

const Providers = ({ children }) => {
  return (
    <Web3ReactProvider getLibrary={getLibraryB}>
      <Web3ProviderNetwork getLibrary={getLibraryB}>
        <Provider store={store}>
          <ThemeContextProvider>
            {children}
          </ThemeContextProvider>
        </Provider>
      </Web3ProviderNetwork>
    </Web3ReactProvider>
  )
}

export default Providers
