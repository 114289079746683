import React from 'react'
import { useTranslation } from "react-i18next";
import { useMatchBreakpoints } from '@dreamweb/uikit'
import { StyledContainer,  StyledTitle, StyleIntro} from './PlayRulesStyle'
import PlaySteps from './PlaySteps';

const cardsList = [
    {
        title: "BUY TICKET",
        desc: "Prices are set when the round starts, equal to 10 USD in DCOIN / KYURYO per ticket."
    },
    {
        title: "WAIT FOR THE DRAW",
        desc: "There are two draws every day: one every 24 hours."
    },
    {
        title: "CHECK FOR PRIZES",
        desc: "Once the round's over, come back to the page and check to see if you've won!"
    }
]

const PlayRules:React.FC = () => {
    const { isSm, isXs, isLg, isMd,isXl } = useMatchBreakpoints();
    const isMobile = isXs || isSm || isLg || isMd;
    const { t } = useTranslation();

    return (
        <>
           <StyledContainer >
                <StyledTitle style={{marginBottom: '8px'}}>{t("HOW TO PLAY")}</StyledTitle>
                <StyleIntro isMobile={isMobile}>{t("If the digits on your tickets match the winning numbers in the correct order, you win a portion of the prize pool.")}</StyleIntro>
                <PlaySteps cardsList ={cardsList}/>
           </StyledContainer>
        </>
    )
}
export default PlayRules;