/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/order */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable import/named */
import React, {useEffect, useState, useRef} from 'react'
import {  useModal, useMatchBreakpoints, Button } from '@dreamweb/uikit'
import { getViewLottery, checkHistoryLotteryInfoByLotteryId, checkTotalPlayers, getLastDrawedLottoryId, claimTickets } from 'utils/lotteryUtils'
import { useLottery } from 'hooks/useContract'
import { useTranslation } from "react-i18next";
import { formatDecimal } from 'utils/math';
import moment from "moment"
import { useWeb3React } from '@web3-react/core'
import { StyleTable, StyledDetail } from './HistoryTableStyle'
import { StyledCardHeader, StyledNodata } from '../HistoryPanelStyle'
import { handleRewardsData, checkLotteryStatus } from '../../../utils/index'
import { StyledWinNumsStyle, StylePrizeContainer, StylePrizePot, StyledMore, StyledHistoryList} from '../AllHistory/AllHistoryStyle'
import LotteryDetailNextDraw  from '../../LotteryDetailModal/LotteryDetailNextDraw'
import LotteryDetailMoal from '../../LotteryDetailModal/LotteryDetailMoal'
import LotteryDetail from '../../LottertDetail/LotteryDetail'
import { Modal } from 'antd'
import useToast from 'hooks/useToast'
import { usePriceDreamBusd } from 'state/hooks'

 
interface HistoryTableProps{
    headerList: Array<{name: string}>,
    bodyList:Array<any>
    setUpdate?: any
    update?: any
}

const HistoryTable:React.FC<HistoryTableProps> = ({headerList, bodyList, setUpdate, update})=>{
    const handleKeyDown = ()=>{
        // 
    }
    const { t } = useTranslation();
    const { account } = useWeb3React();
    const [ flopFlag, setFlipFlag] = useState(false);
    const [rewards, setRewards] = useState([]);
    const lastDrawedLottoryId = useRef(null)
    const [winningNumbers, setWinningNumbers] = useState([])
    const [tickets, setTickets ] = useState(0);
    const [noReward, setNoReward] = useState(false);
    const [noRewardText, setNoRewardText] = useState(false);
    const [claimLoding, setClaimLoding] = useState(false);
    const lotteryContract = useLottery();
    const [ showDetail, setShowDetail] = useState(false);
    const [ lotteryInfo, setLotteryInfo ] = useState({finalNumber: ''})
    const newLotteryIds = useRef([])
    const [totalPlayers, setTotalplayers] = useState(0)
    const [lotteryIdsModal, setLotteryIdsModal] = useState([])
    const [bracketsModal, setBracketsModal] = useState([])
    const [lotteryIdModal, setLotteryIdModal] = useState(0)
    const mountDcoin = useRef(0);
    const mountKyuryo = useRef(0);
    const burnDcoin = useRef(0);
    const burnKyuryo = useRef(0);
    const currentLotteryId = useRef(0)
    const [ startTimeOfLottery, setStartTimeOfLottery] = useState(0);
    const currentAccount = useRef('');
    const { toastError, toastSuccess, toastInfo, toastWarning } = useToast()
  
    const { isSm, isXs, isLg, isMd,isXl } = useMatchBreakpoints();
    const isMobile = isXs || isSm || isLg || isMd;
    const DreamPriceUsd = usePriceDreamBusd();
    const [isDcoinKyuruoPrice, setIsDcoinKyuruoPrice] = useState(0)
    const [openView,setOpenView]=useState(false)

    const formatIndex = (num: number): string => {
        if (num < 10) {
            return `#00${num}`;
        }
        if (num < 100 && num > 9) {
            return `#0${num}`;
        }
        return `#${num}`;
    }

    const getDcoinKyuryoPrice = async () => { 
        const info = await getViewLottery(lotteryContract);
        const { priceTicketInDcoin, priceTicketInKyuryo } = info;
        setIsDcoinKyuruoPrice(Number(localStorage.getItem('isDcoinKyuruo')==='0'?priceTicketInDcoin:priceTicketInKyuryo));
     }



    const handleCheckReward = async(info2) => {
        try {
            const data = info2
            const { lottery, user, lotteryId } = data;
            const {finalNumber} = lottery as any;
            if (finalNumber) {
                const { lotteryIds, brackets, winNumbers } = checkLotteryStatus(user, finalNumber.substring(1).split(''));
                // console.log(92, lotteryIds, user, winNumbers, brackets)
                setBracketsModal(brackets)
                setLotteryIdsModal(winNumbers)
                setLotteryIdModal(lotteryId)
                if (!lotteryIds?.length) {
                    // 没有中奖
                    setNoReward(false)
                    setNoRewardText(false)
                } else {
                    // 中奖了 
                    setNoReward(true)
                    setNoRewardText(true)
                    newLotteryIds.current = lotteryIds
                    for (let index = 0; index < user?.[2].length; index++) {
                        const element = user?.[2]?.[index];
                        if(element) {
                            // 已领取
                            setNoReward(false)
                        }
                    }
                }
            }
        } catch (error) {
            console.error(error)
        }
    }

    // 根据lotteryId查询是否领取
    const checkCanClamin = async () => {
        if (!account) return false;
        const { lottery, user, lotteryId } = lastDrawedLottoryId.current;
        const userInfo = await lotteryContract.methods.viewUserInfoForLotteryId(account, lotteryId, 0, 1000).call();
        const {finalNumber} = lottery as any;
        const status = []
        if (finalNumber) {
            const { lotteryIds, brackets } = checkLotteryStatus(user, finalNumber.substring(1).split(''));
            userInfo[1].forEach((item,index) => {
                const reverseId = `1${String(item).substring(1).split('').reverse().join('')}`;
                if (lotteryIds.includes(reverseId)) {
                    status.push(userInfo[2][index])
                }
            })
        }
        return status.every(item => item)
    }


    useEffect(() => { 
        setClaimLoding(!!+localStorage.getItem('claimLoding'))
    }, [update])
    
    const handleClaim =  async () => {
        try {
            const allReward = await checkCanClamin();
            if (allReward) {
                localStorage.setItem('claimLoding', '0')
                setClaimLoding(false)
                setUpdate(pre=>!pre)
            } else {
                localStorage.setItem('claimLoding', '1')
                setClaimLoding(true)
                setUpdate(pre=>!pre)
                await claimTickets(lotteryContract, lotteryIdsModal, bracketsModal, account, lotteryIdModal);
                setClaimLoding(false)
                localStorage.setItem('claimLoding', '0')
                toastSuccess('successful')
                setNoReward(false)
                setUpdate(pre=>!pre)
            }
        } catch (error:any) {
            toastWarning(error?.message ? error?.message : 'failed')
            setClaimLoding(false)
            localStorage.setItem('claimLoding', '0')
            setUpdate(pre=>!pre)
        }
    }

    const getCurrentLotteryInfo = async (number, lotteryId) => {
        currentLotteryId.current = lotteryId;
        lastDrawedLottoryId.current = await getLastDrawedLottoryId(lotteryContract, account, lotteryId); 
        const info = await checkHistoryLotteryInfoByLotteryId(lotteryId, lotteryContract);
        await handleCheckReward(lastDrawedLottoryId.current)
        const players = await checkTotalPlayers(lotteryContract, lotteryId);
        const { rewardsBreakdown, countWinnersPerBracket, finalNumber, endTime, treasuryFee, amountCollectedInDcoin, amountCollectedInKyuryo } = info;
        burnDcoin.current = amountCollectedInDcoin * treasuryFee / 10000 /1e18;
        burnKyuryo.current = amountCollectedInKyuryo * treasuryFee / 10000 /1e18;
        mountDcoin.current = Number(amountCollectedInDcoin) / 1e18;
        mountKyuryo.current = Number(amountCollectedInKyuryo) / 1e18;
        
        setStartTimeOfLottery(Number(endTime) * 1000)
        setTotalplayers(players)
        setWinningNumbers(finalNumber.substring(1).split(''));
        setTickets(number);
        // console.log(100, info, lotteryId)
        setRewards(handleRewardsData(rewardsBreakdown, countWinnersPerBracket, mountDcoin.current - burnDcoin.current, mountKyuryo.current - burnKyuryo.current))
        setLotteryInfo(info);
        setClaimLoding(false)
        localStorage.setItem('claimLoding', '0')
        setShowDetail(true);
    }

    const backToList = () => {
        setShowDetail(false);
    }

    
    const gradeSilver = (preElement) => { 
        let num = 0;
        for (let i = 0; i < 6; i++) {
            const element = preElement;
            if(element?.slice(i,i+1) === winningNumbers?.[i]) {
                num += 1
            } else {
                break
            }
        }
        if(num===5) {
            return 1
        }
        return 0
    }

    const gradeGold = (preElement) => { 
        let num = 0;
        for (let i = 0; i < 6; i++) {
            const element = preElement;
            if(element?.slice(i,i+1) === winningNumbers?.[i]) {
                num += 1
            } else {
                break
            }
        }
        if(num===6) {
            return 1
        }
        return 0
    }

    const gradeDcoin = (preElement) => { 
        let num = 0;
        for (let i = 0; i < 6; i++) {
            const element = preElement;
            if(element?.slice(i,i+1) === winningNumbers?.[i]) {
                num += 1
            } else {
                break
            }
        }
        return rewards?.[num-1]?.each?.[0]
    }
    const gradeKyuryo = (preElement) => { 
    let num = 0;
    for (let i = 0; i < 6; i++) {
        const element = preElement;
        if(element?.slice(i,i+1) === winningNumbers?.[i]) {
            num += 1
        } else {
            break
        }
    }
    return rewards?.[num-1]?.each?.[1]
    }

    // 中奖总金额
    const totalAmount = () => { 
        let numDcoin = 0;
        for (let index = 0; index < newLotteryIds.current.length; index++) {
            const element = newLotteryIds.current?.[index];
            numDcoin += gradeDcoin(element?.substring(1))
        }
        let numKyuryo = 0;
        for (let index = 0; index < newLotteryIds.current.length; index++) {
            const element = newLotteryIds.current?.[index];
            numKyuryo += gradeKyuryo(element?.substring(1))
        }
        let numGold = 0;
        for (let index = 0; index < newLotteryIds.current.length; index++) {
            const element = newLotteryIds.current?.[index];
            numGold += gradeGold(element?.substring(1))
        }
        let numSilver = 0;
        for (let index = 0; index < newLotteryIds.current.length; index++) {
            const element = newLotteryIds.current?.[index];
            numSilver += gradeSilver(element?.substring(1))
        }
        return [formatDecimal(numDcoin, 4), formatDecimal(numKyuryo, 4), numGold, numSilver]
    }

    useEffect(() => { 
        getDcoinKyuryoPrice()
    }, [])

    const LotteryInfoJSX = (): JSX.Element => {
        return <StyledDetail isMobile={isMobile}>
            <section className='winning-number'>
                <div className='title'>{t("Winning Number")}</div>
                <StyledWinNumsStyle isMobile={isMobile}>
                    <div>
                        {winningNumbers.map(item => {
                            return <span key={Math.random()} className='square'>{item}</span>
                        })}
                    </div>
                </StyledWinNumsStyle>
            </section>
            <section className='your-tickets'>
                <div className='title'>{t("Your tickets")}</div>
                <div className='desc'>
                    <p><span>{t(`You had ${tickets} ${tickets > 1 ? 'tickets' : 'ticket'} this round`)}</span></p>
                    { tickets && <p style={{color: '#3232F8'}} className='view-tickets' onKeyDown={handleKeyDown} onClick={ ()=>{setOpenView(true)} }>{t("View your tickets")}</p> }
                    {
                      noRewardText &&  
                      <>
                        <div style={{ marginBottom: '10px'}}>You win: </div>
                        {
                            totalAmount()?.[2]>0?
                            <div style={{ marginBottom: '10px',textAlign:'end'}}>
                                Pegasus Gold * 1
                            </div>:''
                        }
                        {
                            totalAmount()?.[3]>0?
                            <div style={{ marginBottom: '10px',textAlign:'end'}}>
                                Pegasus Silver * 1
                            </div>:''
                        }
                        {
                            totalAmount()?.[0] > 0 ?
                            <div style={{ marginBottom: '10px',textAlign:'end'}}>
                            {totalAmount()?.[0]} DCOIN
                            </div> : ''
                        }
                        {
                            totalAmount()?.[1] > 0 ?
                            <div style={{ marginBottom: '10px',textAlign:'end'}}>
                                {totalAmount()?.[1]} KYURYO
                            </div> : ''
                        }
                      </>
                    }
                    
                    { 
                        // noReward &&
                        <Button style={{ height: '32px', color: '#000'}} isLoading={claimLoding} onClick={handleClaim}>
                        <span>{t('Claim')}</span>
                    </Button>
                    }
                </div>
            </section>
         
            <StylePrizeContainer>
                <StylePrizePot isMobile={isMobile}>
                    <section className='left'>
                        <div className='title'>{t("Prize pot")}</div>
                        <div className='prize'> {formatDecimal(mountDcoin.current, 4)} DCOIN</div>
                        <div className='prize'> {formatDecimal(mountKyuryo.current, 4)} KYURYO</div>
                    </section>
                    <section className='right'>
                        <div className='info'>{t("Match the winning number in the same order to share prizes.")}</div>
                        <div className='info'>{t("Total players this round: ")} {totalPlayers}</div>
                    </section>
                </StylePrizePot>  
            </StylePrizeContainer>
            { flopFlag &&  <LotteryDetail  rewards={rewards} source="lotteryDetail" burnDcoin={burnDcoin.current} burnKyuryo={burnKyuryo.current} /> }
            <StyledMore  onClick={() => { setFlipFlag( flag => !flag) }}>
                <img style={{ transform: flopFlag? 'rotate(180deg) scale(0.5)' : 'rotate(0deg) scale(0.5)'}} alt="arrow" src='https://dreamfinance.s3.ap-northeast-1.amazonaws.com/assets/images/lottery/down_arrow.png' />  {flopFlag ? "Hide" : "Details"}
            </StyledMore>
            
            <Modal centered className='buy-tickets-modal' title={null} footer={null} open={openView} closable={false}>
                <LotteryDetailMoal 
                    round={currentLotteryId.current} 
                    winningNumbers={winningNumbers} 
                    totalTickets={tickets} 
                    onDismiss={()=>setOpenView(false)} />
            </Modal>
        </StyledDetail>
    }

    const TicketsListTable = (): JSX.Element => {
        return <StyledHistoryList isMobile={isMobile}>
            {
                !bodyList?.length && <StyledNodata isMobile={isMobile} >
                    <p>No lottery history found.  </p>
                    <p>Buy tickets for the next round!</p>
                </StyledNodata>
            }
            {
                Boolean(bodyList?.length) && <div className='tickets-display-table'>
                    <table>
                        <thead>
                            <tr>
                                {
                                    headerList&&headerList.map(item => <th key={Math.random()}> {item.name} </th>)
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                bodyList && bodyList.map(item => {
                                    return <tr key={Math.random()}>
                                        <td className="td">{formatIndex(Number(item.lotteryId))}</td>
                                        <td className="td">{item.date}</td>
                                        <td className="td">{item.tickets}</td>
                                        <td onKeyDown={handleKeyDown} onClick={ ()=>{getCurrentLotteryInfo(item.tickets, item.lotteryId)} } >{">"}</td>
                                    </tr>
                                })
                            }
                        </tbody>
                    </table>
                </div>
            }
        </StyledHistoryList>
    }



    return (
        <>  
            <StyledCardHeader isMobile={isMobile}>
                {
                    showDetail ? 
                    <div className='your-history-go-back'>
                        <img className='back-arrow' onClick={() => backToList()} alt='back' src="https://dreamfinance.s3.ap-northeast-1.amazonaws.com/assets/images/lottery/next.png" />
                        <section className='rounds'>
                            <div className='title'>Round <span className='label'>{currentLotteryId.current}</span></div>
                            <div className='date'>{moment(startTimeOfLottery).format('MMM D, YYYY, h:mm A')}</div>
                        </section>
                    </div>
                    :
                    <div style={{paddingTop: isMobile? '1.2rem': '14px', textAlign: 'left'}}>{t("ROUNDS")}</div>
                }
            </StyledCardHeader>
            <StyleTable isMobile={isMobile}>
                {
                    showDetail ?  LotteryInfoJSX() : TicketsListTable()
                }
            </StyleTable>
        </>
    )
}
export default HistoryTable;