import styled from 'styled-components';

const StyleBase = styled.div``

const StyledHeader = styled(StyleBase)<{isMobile?: boolean }>`
  height:  ${({isMobile}) => isMobile ?  '14rem' : '492px' };
  padding-bottom: 40px;
  padding-top: 40px;
`
const StyledContainer =  styled(StyleBase)<{isMobile?: boolean }>`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding-top: ${({isMobile}) => isMobile ?  '0rem' : '25px' };
  font-size: 22px;
  font-weight: 300;
`

const StyledTitle = styled(StyleBase)<{isMobile?: boolean }>`
  font-family: Nirmala UI-Bold,Nirmala UI;
  font-size: ${({isMobile}) => isMobile ?  '1rem' : '40px' };
  margin-bottom:${({isMobile}) => isMobile ?  '1rem' : '31px' };
  color: white;
`
const StyledSum =  styled(StyleBase)<{isMobile?: boolean }>`
  margin-bottom: ${({isMobile}) => isMobile ?  '0rem' : '0px' };
  font-size: ${({isMobile}) => isMobile ?  '2rem' : '60px' };
  font-family: AXIS;
  font-weight: normal;
  color: #FFFFFF;
  font-family: Nirmala UI-Bold,Nirmala UI;
`
const StyledDesc = styled(StyleBase)<{isMobile?: boolean }>`
  margin-bottom: ${({isMobile}) => isMobile ?  '1.4rem' : '50px' };
  font-size: ${({isMobile}) => isMobile ?  '1rem' : '20px' };
  font-family: AXIS;
  font-weight: normal;
  line-height: ${({isMobile}) => isMobile ?  '1rem' : '20px' };
  color: #FFFFFF;
`

const StyledButton = styled(StyleBase)<{isMobile?: boolean }>`
  button {
    width: ${({isMobile}) => isMobile ?  '16rem' : '360px' };
    font-size: ${({isMobile}) => isMobile ?  '1rem' : '22px' };
  }
`

export {StyledHeader, StyledContainer, StyledTitle, StyledSum, StyledDesc, StyledButton}