/* eslint-disable import/no-cycle */
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from "react-i18next";
import { Button, useModal, useMatchBreakpoints, useTooltip} from '@dreamweb/uikit'
import { formatDecimal } from 'utils/math';
import { getViewLottery } from 'utils/lotteryUtils'
import Login from 'components/Login'
import { useWeb3React } from '@web3-react/core'
import { useLotteryCheckAndClaim } from 'hooks/useLottery';
import { usePriceDreamBusd } from 'state/hooks'
import { useLottery } from 'hooks/useContract'
import { Modal } from 'antd'
import BuyTicketsModal from './BuyTicketsModal'
import { StyledHeader, StyledContainer, StyledTitle, StyledSum, StyledDesc, StyledButton } from './HeaderStyle';

type propsType = {
    latestedDraw: boolean,
    amount: any,
    endTime: string,
    oneStartTimer: any
}
const Header = (props: propsType) => {
    const { amount, endTime, latestedDraw, oneStartTimer } = props;
    const [canBuy, setCanBuy] = useState(false);
    const { t } = useTranslation()
    const { account } = useWeb3React()
    const lotteryContract = useLottery()
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [openModal] = useModal(<BuyTicketsModal  onDismiss />)
    const { handleCheck } = useLotteryCheckAndClaim();
    const [isStart, setIsStart] = useState(false);
    const DreamPriceUsd = usePriceDreamBusd();
    const [openBuy, setOpenBuy] = useState(false)
    const [newAmountCollectedInDcoin, setNewAmountCollectedInDcoin] = useState(0)
    const [newAmountCollectedInKyuryo, setNewAmountCollectedInKyuryo] = useState(0)
    const [newPriceTicketInDcoin, setPriceTicketInDcoin] = useState(0)
    const [newPriceTicketInKyuryo, setPriceTicketInKyuryo] = useState(0)
    const timerStart = useRef(null)
    const timerGetDcoinKyuryoPrice = useRef(null)
    const { isSm, isXs, isLg, isMd,isXl } = useMatchBreakpoints();
    const isMobile = isXs || isSm || isLg || isMd;
   
    const checkCanBuyTicketOrNot = (): void =>{
        const now = Date.now();
        const lotteryEndTime = Number(`${endTime}000`);
        if (now > lotteryEndTime) {
            setCanBuy(true)
        } else {
            setCanBuy(false)
        }
    }

    const getDcoinKyuryoPrice = async () => { 
        const info = await getViewLottery(lotteryContract);
        const { priceTicketInDcoin,priceTicketInKyuryo, amountCollectedInDcoin, amountCollectedInKyuryo } = info;
        setNewAmountCollectedInDcoin(Number(amountCollectedInDcoin));
        setNewAmountCollectedInKyuryo(Number(amountCollectedInKyuryo));
        setPriceTicketInDcoin(Number(priceTicketInDcoin));
        setPriceTicketInKyuryo(Number(priceTicketInKyuryo));
     }

    useEffect(()=>{
        const initalFunc = async() => {
            checkCanBuyTicketOrNot();
            const result = await handleCheck();
        }
        
        getDcoinKyuryoPrice()
        clearInterval(timerGetDcoinKyuryoPrice.current)
        timerGetDcoinKyuryoPrice.current = setInterval(() => {
            getDcoinKyuryoPrice()
        }, 2000); 
        initalFunc();
        return () => { 
            clearInterval(timerGetDcoinKyuryoPrice.current)
         }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => { 
        if(oneStartTimer * 1000 > new Date().valueOf()) {
            setIsStart(false)
            clearInterval(timerStart.current)
            timerStart.current = setInterval(() => {
                if(oneStartTimer * 1000 <= new Date().valueOf()) {
                    setIsStart(true)
                }
            }, 2000); 
        } else {
            setIsStart(true)
        }
        return () => { 
            clearInterval(timerStart.current)
         }
    }, [oneStartTimer])

    const openM = () => { 
        setOpenBuy(true)
    }
    const onDismiss = () => { 
        setOpenBuy(false)
     }
    return (
        <StyledHeader isMobile={isMobile}>
            <StyledContainer isMobile={isMobile}>
                <StyledTitle isMobile={isMobile}>{t("DREAM FINANCE LOTTERY")}</StyledTitle>
                {/* <StyledSum isMobile={isMobile}>{latestedDraw ? 0 : formatDecimal((formatDecimal(amount?.[0].toNumber(6), 4) * newPriceTicketInDcoin * newPriceTicketInDcoin / 1e18), 4)}  DCOIN</StyledSum>
                <StyledSum isMobile={isMobile}>{latestedDraw ? 0 : formatDecimal((formatDecimal(amount?.[1].toNumber(6), 4) * newPriceTicketInKyuryo * newPriceTicketInKyuryo / 1e18), 4)} KYURYO</StyledSum> */}
                <StyledSum style={{color: '#FACE9C'}} isMobile={isMobile}>{latestedDraw ? 0 : formatDecimal((newAmountCollectedInDcoin / 1e18), 4)}  DCOIN</StyledSum>
                <StyledSum style={{color: '#FACE9C'}} isMobile={isMobile}>{latestedDraw ? 0 : formatDecimal((newAmountCollectedInKyuryo / 1e18), 4)} KYURYO</StyledSum>
                <StyledDesc style={{marginTop:'18px'}} isMobile={isMobile}>in prizes!!</StyledDesc>
                {/* <StyledButton isMobile={isMobile}>
                    <Button disabled={!canBuy || !isStart} onClick={openModal} >{!isStart ? t("on sale soon!"):  t("Buy Tickets")}</Button>
                </StyledButton> */}
                <StyledButton isMobile={isMobile}>
                    {
                        account ? <Button style={{ padding: !isMobile ? '30px' : '0px', backgroundColor: '#fff', borderRadius: '12px', color: '#000'}} disabled={!canBuy || !isStart} onClick={openM} >{!isStart ? t("on sale soon!"):  t("Buy Tickets")}</Button> :
                        <Login
                            setIsModalOpen={setIsModalOpen}
                            isModalOpen={isModalOpen}
                        >
                          <div style={{width: '360px',height: '60px',background: 'rgb(255, 255, 255)'}} className={account ? 'styledHeaderTextC corsor-pointer' : 'styledHeaderTextC-hover styledHeaderTextC corsor-pointer'}>{!isStart ? t("on sale soon!"):  t("Buy Tickets")}</div>
                        </Login>
                    }
                    
                </StyledButton>
                <Modal centered className='buy-tickets-modal' title={null} footer={null} open={openBuy} closable={false}>
                    <BuyTicketsModal  onDismiss={onDismiss} />
                </Modal>
            </StyledContainer>
        </StyledHeader>
    )
}
export default React.memo(Header);
          