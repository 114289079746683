
/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { formatDecimal } from 'utils/math';
import BigNumber from 'bignumber.js'
import { DEFAULT_TOKEN_DECIMAL } from 'config'

export const setLotteryLevel = (compareTicketArr, targetTicketArr) => {
    if ((Number(compareTicketArr[0]) !== Number(targetTicketArr[0]))) return -1;
    let index = 0;
    let flag = true;
    while ((Number(compareTicketArr[index]) === Number(targetTicketArr[index])) && flag) {
        if (index === 6) {
            flag = false;
        } else {
            index++
        }
    }
    return index - 1;
}

export const checkLotteryStatus = (ticketsInfo: Array<any>, lotteryTicket: Array<string>) => {
    const lotteryIds = [];
    const brackets = [];
    const winNumbers = [];
    const ticketsList = ticketsInfo[1].map(item => item.substring(1).split(''));
    const ticketsIds = ticketsInfo[1];
    ticketsList.forEach((arr, index) => {
        const level = setLotteryLevel(arr, lotteryTicket);
        if (level > 0) {
            brackets.push(level)
            lotteryIds.push(ticketsIds[index])
            winNumbers.push(ticketsInfo[0][index])
        }
    })
    return { lotteryIds, brackets, winNumbers }
};

export const handleRewardsData = (records: Array<any>, countWinnersPerBracket: Array<number | string>, amountDcoin: number, amountKyuryo: number, ratio = 1) => {
    if (records === undefined) {
        return []
    }
    const div = records.reduce((pre, cur) => Number(pre) + Number(cur), 0)
    const res = records.map((item, index) => {
        const dcoin = new BigNumber(amountDcoin).times((new BigNumber(item).div(new BigNumber(div)))).toNumber();
        const kyuryo = new BigNumber(amountKyuryo).times((new BigNumber(item).div(new BigNumber(div)))).toNumber();
        const winners = Number(countWinnersPerBracket[index]);
        return {
            title: index < 5 ? `Match first  ${index + 1}` : `Match all  ${index + 1}`,
            sum: [formatDecimal(dcoin, 4), formatDecimal(kyuryo, 4)],
            each: [winners !== 0 ? formatDecimal((dcoin/winners), 4) : 0,winners !== 0 ? formatDecimal((kyuryo/winners), 4) : 0],
            price: [formatDecimal(dcoin * ratio, 4), formatDecimal(kyuryo * ratio, 4)],
            winners,
        }
    })
    return res;
}

export const replaceChToEn = (str) => {
    return str.includes('上午') ? str.replace(/上午/, "AM  ") : str.replace(/下午/, "PM  ");
}