/* eslint-disable react/no-unknown-property */
import React, { useState } from 'react'
import { useTranslation } from "react-i18next";
import { useMatchBreakpoints } from '@dreamweb/uikit'
import { StyledTitle, StyleSubTitle, StyleContainer, StyleImage, StyleContentShallow, StyleContent, StyledMore, StyleDesc } from './MoreDetailStyle';
 

const MoreDetail: React.FC = () => {
    const [flopFlag, setFlipFlag] = useState(false);
    const { isSm, isXs, isLg, isMd,isXl } = useMatchBreakpoints();
    const isMobile = isXs || isSm || isLg || isMd;
    const { t } = useTranslation()
    return (
        <StyleContainer isMobile={isMobile}>
            {flopFlag && (
                <StyleDesc isMobile={isMobile}>
                    <StyledTitle>{t("WINNING CRITERIA")}</StyledTitle>
                    <StyleSubTitle>{t("The digits on your ticket must match in the correct order to win.")}</StyleSubTitle>
                    <StyleContentShallow>
                        {t("Here's an example lottery draw, with three tickets, A and B and C.")}
                    </StyleContentShallow>
                    <StyleContentShallow>
                        <p>{t("Ticket A: The first 3 digits and the last 2 digits match, but the 4th digit is wrong, so this ticket only wins a 'Match all 3' prize.")}</p>
                        <p>{t("Ticket B: Even though the last 5 digits match, the first digit is wrong, so this ticket doesn't win a prize.")}</p>
                        <p>{t("Ticket C: Even though the  first 1 digits and the last 4 digits match, but the 2th digit is wrong, so this ticket doesn't win a prize.")}</p>
                    </StyleContentShallow>
                    <StyleContentShallow>
                        {t("The first 5 digits match, you'lll win prizes from the 'Match 5' bracket: a Pegasus Silver NFT.")}
                    </StyleContentShallow>
                    <StyleContentShallow>
                        {`Match all the numbers and you win the "Match 6" prize: a Pegasus Gold NFT.`}
                    </StyleContentShallow>
                    <StyleContentShallow>
                        {`Prize brackets don't 'stack': if you match the first 3 digits in order, you'lll only win prizes from the 'Match 3' bracket, and not from 'Match 2'.`}
                    </StyleContentShallow>
                    <StyleImage>
                        <img alt="rules" src="https://dreamfinance.s3.ap-northeast-1.amazonaws.com/assets/images/lottery/number_rules.png" />
                    </StyleImage>

                    <StyledTitle>{t("PRIZE FUNDS")}</StyledTitle>
                    <StyleSubTitle style={{ marginBottom: "20px" }}>
                        {t("The prizes for each lottery round come from two sources:")}
                    </StyleSubTitle>
                    <StyleSubTitle>
                        {t("Ticket Purchases")}
                    </StyleSubTitle>
                    <StyleContentShallow>
                        <p>{t("100% of the DCOIN、KYURYO paid by people buying tickets that round goes back into the prize pools.")}</p>
                    </StyleContentShallow>
                    <StyleSubTitle>
                        {t("Rollover Prizes")}
                    </StyleSubTitle>
                    <StyleContentShallow>
                        <p>{t("After every round, if nobody wins in one of the prize brackets(Matches first 2、Matches first 3、Matches first 4), the unclaimed DCOIN、KYURYO for that bracket rolls over into the next round and are redistributed among the prize pools.")}</p>
                    </StyleContentShallow>
                    <StyleImage>
                        <img alt="rules" src="https://dreamfinance.s3.ap-northeast-1.amazonaws.com/assets/images/lottery/rule_detail.png" />
                    </StyleImage>
                </StyleDesc>
            )}
            <StyledMore isMobile={isMobile} onClick={() => { setFlipFlag(flag => !flag) }}>
                <p>{flopFlag ? "Hide" : "Read More"}</p>
                <div style={flopFlag ? {transform: 'rotateZ(180deg)'} : {}}><svg  className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2353" width="42" height="24"><path d="M966.4 323.2c-9.6-9.6-25.6-9.6-35.2 0l-416 416-425.6-416c-9.6-9.6-25.6-9.6-35.2 0-9.6 9.6-9.6 25.6 0 35.2l441.6 432c9.6 9.6 25.6 9.6 35.2 0l435.2-432C976 345.6 976 332.8 966.4 323.2z" p-id="2354" fill="#ffffff" /></svg></div>
            </StyledMore>
        </StyleContainer>
    )
}
export default MoreDetail;
