/* eslint-disable import/no-named-as-default */
/* eslint-disable import/order */
/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable import/no-mutable-exports */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { lazy, useState, useEffect, useRef } from 'react'
import { Router, Redirect, Route, Switch, BrowserRouter, useHistory  } from 'react-router-dom'
import { useWeb3React } from '@web3-react/core'
import useEagerConnect from 'hooks/useEagerConnect'
import styled from 'styled-components'; 
import Login from 'components/Login'
import { Dropdown, Drawer, Tooltip, Button, Popover, message, Input, Modal } from 'antd'
import { interceptAdd } from 'utils/utils'
import useAuth from 'hooks/useAuth'
import Web3ReactManager from 'components/Web3ReactManager'
import { useUpdateEffect } from 'ahooks';
import history from './routerHistory'
import Home from './views/Home'
import Swap from './views/Swap'
import { RedirectDuplicateTokenIds, RedirectOldAddLiquidityPathStructure } from './views/AddLiquidity/redirects'
import Liquidity from './views/Liquidity'
import Lottery from './views/Lottery/Lottery'
import PoolFinder from './views/PoolFinder'
import Change from './views/Change'
import Staking from './views/Staking'
import NFT from './views/NFT'
import MarketPlace from './views/MarketPlace'
import BigNumber from 'bignumber.js'
import AddLiquidity from './views/AddLiquidity'
import useWeb3 from 'hooks/useWeb3'
import { RedirectOldRemoveLiquidityPathStructure } from './views/RemoveLiquidity/redirects'
import RemoveLiquidity from './views/RemoveLiquidity'
import Farms from './views/Pool/index'
import { LoginHover, StyledHeader, StyledFooter, ShowMenu } from './views/Home/indexStyles'
import "i18n/i18n"
import 'antd/dist/reset.css'
import './font.css'
import './globle.css'
import './assets/css/globle.css'
import { nanoid } from 'nanoid';



// const Lottery = lazy(() => import('./views/Lottery'))

const StyleBase = styled.div``

const MintMessage = styled(StyleBase)<{isMobile?: boolean}>` 
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  height: 100%;
  >.t-cmcp {
    margin-top: ${({isMobile}) => isMobile ?  '28px' : '10px' };
    font-size: ${({isMobile}) => isMobile ?  '26px' : '18px' };
    max-width: ${({isMobile}) => isMobile ?  '466px' : '90%' };
    max-height: ${({isMobile}) => isMobile ?  '350px' : '350px' };
    overflow: hidden;
    color: #FFFFFF;
  }
  >.cmcp {
    width: ${({isMobile}) => isMobile ?  '90%' : '98%' };
    color: #FFFFFF;
    line-height: ${({isMobile}) => isMobile ?  '107%' : '124%' };
    margin-top: ${({isMobile}) => isMobile ?  '28px' : '10px' };
  }
  .eds {
    margin-top: ${({isMobile}) => isMobile ?  '20px' : '8px' };
    color: #fff;
  }
  >.vmp {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: ${({isMobile}) => isMobile ?  '40px' : '15px' };
    width: ${({isMobile}) => isMobile ?  '340px' : '80%' };
    height: 60px;
    border-radius: 37px;
    background: #F8C6C6;
    color: #B11213;
    // border: 1px solid #35B7FF;
    margin-bottom: ${({isMobile}) => isMobile ?  '40px' : '15px' };
  }
`

const Main = styled(StyleBase)<{isMobile?: boolean}>` 
  padding-top: ${({isMobile}) => isMobile ?  '157px' : '120px' };
  background: url('https://dreamfinance.s3.ap-northeast-1.amazonaws.com/assets/images/bg.png');
  min-width:  ${({isMobile}) => isMobile ?  '1220px' : '100%' };
`
let newUseMessage: any
export {
  newUseMessage
}
const App: React.FC = (props) => {
  const { account } = useWeb3React()
  const web3 = useWeb3()
  const temer = useRef(null)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [openMenu,setOpenMenu] = useState(false)
  const [hash, setHash] = useState(window.location.hash);
  const [update,setUpdate]=useState(true)
  const nftRef = useRef(null)
  const stakingRef = useRef(null)
  const earnRef = useRef(null)
  const [openMessage, useOpenMessage] = useState({
    isShow: false,
    isMf: false,
    t: false,
    isSetTimeout: false,
    timeout: 3000,
    message: ""
  }) 
  const { login, logout } = useAuth()
  const [showSelect, setShowSelect] = useState<boolean>(false)
  const [matches, setMatches] = useState(
    window.matchMedia('(min-width: 769px)').matches,
  );
  const [selectType, setSelectType] = useState<string>(localStorage.getItem('chain') || (matches ? 'BNB Smart Chain' : 'BSC')) 

  
  const stopPropagation = (e: React.MouseEvent) => {
    e.nativeEvent.stopImmediatePropagation();
  }
  const selectFunc = (e: React.MouseEvent) => {
    stopPropagation(e)
    setShowSelect(!showSelect)
  }

  // h5菜单按钮
  const onClose = () => {
    setOpenMenu(false);
  };

  useEffect(() => { 
    if(matches && selectType === 'BSC') {
      setSelectType('BNB Smart Chain')
    }
    if(!matches && selectType === 'BNB Smart Chain') {
      setSelectType('BSC')
    }
  }, [matches])

  useUpdateEffect(() => { 
    localStorage.setItem('chain',selectType)
    // logout()
  }, [selectType])

  const mesClick = () => { 
    clearTimeout(temer.current)
    if(openMessage?.isMf) {
      const url = localStorage.getItem("i18nextLng") === 'en' ? `https://testnets.opensea.io/${account}` : `https://testnets.opensea.io/${localStorage.getItem("i18nextLng")}/${account}`
      window.open(url)
      useOpenMessage((pre)=>({...pre,isShow:false, t: false, isSetTimeout: false}))
    } else {
      useOpenMessage((pre)=>({...pre,isShow:false, t: false, isSetTimeout: false}))
    }
  }

  useEffect(() => {
    newUseMessage = useOpenMessage
    window
      .matchMedia('(min-width: 769px)')
      .addEventListener('change', (e) => {
        setMatches(e.matches)
      });
  }, []);
  
  if (window.ethereum) {
    // getdreamPrice()
    useEagerConnect()
    // useFetchPublicData()
    // useFetchPriceList();
  }
  
  useEffect(() => { 
    if(openMessage?.isSetTimeout) {
      clearTimeout(temer.current)
      temer.current = setTimeout(() => {
        useOpenMessage({
          isShow: false,
          isMf: false,
          t: false,
          isSetTimeout: false,
          timeout: 3000,
          message: ""
        })
      }, 3000);
    }
    return () => { 
      clearTimeout(temer.current)
    }
  }, [openMessage])

  const isAction = (v) => {
    return new RegExp(v).test(window.location.hash)
  }

  useEffect(() => { 
    const unListen = history.listen((e) => { 
      setOpenMenu(false)
      setUpdate((pre)=>!pre)
      document.documentElement.scrollTop = 0;
      if (e.pathname === '/Liquidity' || new RegExp('/add').test(e.pathname)  || new RegExp('/Liquidity').test(e.pathname)  || new RegExp('/remove').test(e.pathname)) {
        setHash('/earn')
        return
      }
      setHash(e.pathname)
    })
    if (window.location.href === '/Liquidity' || new RegExp('/add').test(window.location.href)  || new RegExp('/Liquidity').test(window.location.href)  || new RegExp('/remove').test(window.location.href)) {
      setHash('/earn')
      return
    }
    setHash(window.location.href)
    return ()=>{
      unListen()
    }
  }, [account])


  useEffect(()=>{
    web3.eth.getGasPrice().then((result: any) => {
      sessionStorage.setItem('gas', result)
    })
    const timer = setInterval(()=>{
      web3.eth.getGasPrice().then((result: any) => {
        sessionStorage.setItem('gas', result)
      })
    }, 10000)
    return () => {
      clearInterval(timer)
    }
  })
  
  const linkUrl = () => {
    const address = account;// 拿到想要复制的值
    const copyInput = document.createElement('input');// 创建input元素
    document.body.appendChild(copyInput);// 向页面底部追加输入框
    copyInput.setAttribute('value', address);// 添加属性，将url赋值给input元素的value属性
    copyInput.select();// 选择input元素
    document.execCommand("Copy");// 执行复制命令
    message.success("Copied!");// 弹出提示信息，不同组件可能存在写法不同
    // 复制之后再删除元素，否则无法成功赋值
    copyInput.remove();// 删除动态创建的节点
  }

  const showCMenu = (e) => {
    if ([...e.current.classList].indexOf('show-c') === -1) {
      e.current.classList.add('show-c');
    } else {
      e.current.classList.remove('show-c');
    }
  }

  useEffect(()=>{
    sessionStorage.setItem('navigatorName', `${navigator.userAgent.split(' ').slice(-1)[0].indexOf("Mobile")}`)
    // sessionStorage.setItem('navigatorName', '1')
  },[])
  return (
    <div>
      <div style={{width: 0, height: 0, overflow: 'hidden'}}>
        <h1>DREAM</h1>
      </div>
      <StyledHeader
        isMobile={matches}>
        <div className='logo corsor-pointer' onClick={()=>history.push('/')}>
          <img src='https://dreamfinance.s3.ap-northeast-1.amazonaws.com/assets/images/home/head/logo.png' alt=''/>
          <div className='logo-text'>Dream Finance</div>
        </div>
          {
            matches && 
            <div className='menu-text'>
              <div className={new RegExp('/change').test(hash)?'is-action':''}>
                <a href="/#/change">
                  Change
                </a>
              </div>
              <div onClick={(e)=>{
                e.stopPropagation()
                history.push('/nft/pegasus-nft')
              }} className={new RegExp('/nft').test(hash)?'is-action corsor-pointer':'corsor-pointer'}>
                <Tooltip  placement="bottom" title={
                  <ShowMenu>
                    <div 
                    className={new RegExp('/nft/buy').test(hash)?'hover:text-text2 corsor-pointer':'hover:text-text2 text-text1 corsor-pointer'} 
                    onClick={(e)=>{
                      e.stopPropagation()
                      history.push('/nft/buy')
                    }}>Buy</div>
                    <div 
                    className={new RegExp('/nft/pegasus-nft').test(hash)?'hover:text-text2 corsor-pointer':'hover:text-text2 text-text1 corsor-pointer'} 
                    onClick={(e)=>{
                      e.stopPropagation()
                      history.push('/nft/pegasus-nft')
                      }}>Pegasus NFT</div>
                    <div 
                    className={new RegExp('/nft/mdf').test(hash)?'hover:text-text2 corsor-pointer':'hover:text-text2 text-text1 corsor-pointer'} 
                    onClick={(e)=>{
                      e.stopPropagation()
                      history.push('/nft/mdf')
                      }}>MDF</div>
                      <div 
                      className={new RegExp('/nft/pokemon').test(hash)?'hover:text-text2 corsor-pointer':'hover:text-text2 text-text1 corsor-pointer'} 
                      onClick={(e)=>{
                        e.stopPropagation()
                        history.push('/nft/pokemon')
                        }}>Game Card</div>
                  </ShowMenu>}>
                  NFT
                </Tooltip>
              </div>
              <div onClick={()=>history.push('/staking/pegasus')} className={new RegExp('/staking').test(hash)?'is-action corsor-pointer':'corsor-pointer'}>
                <Tooltip placement="bottom" title={
                  <ShowMenu>
                    <div 
                      className={new RegExp('/staking/pegasus').test(hash)?'hover:text-text2 corsor-pointer':'hover:text-text2 text-text1 corsor-pointer'} 
                      onClick={(e)=>{
                      e.stopPropagation()
                      history.push('/staking/pegasus')}}>Single Pegasus</div>
                    <div 
                      className={new RegExp('/staking/mdf').test(hash)?'hover:text-text2 corsor-pointer':'hover:text-text2 text-text1 corsor-pointer'} 
                      onClick={(e)=>{
                      e.stopPropagation()
                      history.push('/staking/mdf')}}>Single MDF</div>
                    <div 
                      className={new RegExp('/staking/mixed').test(hash)?'hover:text-text2 corsor-pointer':'hover:text-text2 text-text1 corsor-pointer'} 
                      onClick={(e)=>{
                      e.stopPropagation()
                      history.push('/staking/mixed')}}>Mixed</div>
                  </ShowMenu>}>
                  Staking
                </Tooltip>
              </div>
              <div 
                onClick={()=>history.push('/earn/farms')} 
                className={new RegExp('/earn').test(hash)?'is-action corsor-pointer':'corsor-pointer'}>
                <Tooltip placement="bottom" title={
                  <ShowMenu>
                    <div 
                      className={new RegExp('/earn/farms').test(hash)?'hover:text-text2 corsor-pointer':'hover:text-text2 text-text1 corsor-pointer'} 
                      onClick={(e)=>{
                      e.stopPropagation()
                      history.push('/earn/farms')}}>Farms</div>
                    <div className={new RegExp('/earn/pool').test(hash)?'hover:text-text2 corsor-pointer':'hover:text-text2 text-text1 corsor-pointer'}
                      onClick={(e)=>{
                      e.stopPropagation()
                      history.push('/earn/pool')}}>Pools</div>
                  </ShowMenu>}>
                  Earn
                </Tooltip>
              </div>
              <div className={isAction('/lottery')?'is-action':''}>
                <a href="/#/lottery">
                  Lottery
                </a>
              </div>
              <div className={isAction('/MarketPlace')?'is-action':''}>
                <a href="/#/MarketPlace">
                  MarketPlace
                </a>
              </div>
            </div>
          }
        <div className='menu'>
          <div className='tab'>
            <div style={{display: 'flex',justifyContent: 'space-between',alignItems: 'center',border: '1px solid #FFFFFF', width: matches ? 193 : 90}}  className="select-box mr-16 relative flex justify-start items-center cursor-pointer px-2 h-8 border border-line rounded-90" 
            onClick={(e) => {
              // selectFunc(e)
              }
            }
            >
              <img style={{width:20,height:20, marginRight: '8px'}} src="https://dreamfinance.s3.ap-northeast-1.amazonaws.com/assets/images/home/BNB@2x.png" alt="" />
              <span  style={{marginRight: '16px'}} className="text-text2 text-4xl">{selectType}</span>

              {
                showSelect && (
                  <div style={{color: '#E6E6E6'}} className="flex flex-col justify-around items-start absolute left-0 top-8 bg-black2 py-4 px-4 text-4xl rounded-8 z-10 w-full">
                  <span className="hover:text-text2 cursor-pointer" onClick={() => {setSelectType(matches ? 'BNB Smart Chain' : 'BSC')}}>
                    <img style={{width:20,height:20, marginRight: '8px'}} src="https://dreamfinance.s3.ap-northeast-1.amazonaws.com/assets/images/home/BNB@2x.png" alt="" />
                    <span>{matches ? 'BNB Smart Chain' : 'BSC'}</span>
                  </span>
                    <span className="hover:text-text2 cursor-pointer mt-2" style={{whiteSpace: 'nowrap'}} onClick={() => {setSelectType('Ethereum')}}>
                    <img style={{width:20,height:20, marginRight: '8px'}} src="https://dreamfinance.s3.ap-northeast-1.amazonaws.com/assets/images/home/ETH@2x.png" alt="" />
                      <span>Ethereum</span>
                    </span>
                    <span className="hover:text-text2 cursor-pointer mt-2" style={{whiteSpace: 'nowrap'}} onClick={() => {setSelectType('Arbitrum')}}>
                      <img style={{width:20,height:20, marginRight: '8px',}} src="https://dreamfinance.s3.ap-northeast-1.amazonaws.com/assets/images/home/USDT@2x.png" alt="" />
                      <span>Arbitrum</span>
                    </span>
                  </div>
                )
              }
            </div>
          </div>
          <div className='login'>
            {
              account ? 
              <Popover content={<LoginHover isMobile={matches}>
              <div className='corsor-pointer' onClick={()=>linkUrl()}>Copy address<img style={{width:20,height:20}} src="https://dreamfinance.s3.ap-northeast-1.amazonaws.com/assets/images/home/loginHover/copy.png" alt="" /></div>
              <div className='corsor-pointer' onClick={()=>logout()}>Disconnect<img style={{width:20,height:20}} src="https://dreamfinance.s3.ap-northeast-1.amazonaws.com/assets/images/home/loginHover/disconnect.png" alt="" /></div>
            </LoginHover>} trigger="hover" placement="bottom" className='login-popver'>
                <div className='corsor-pointer'>{interceptAdd(account)}</div>
              </Popover> : 
              <Login
                  setIsModalOpen={setIsModalOpen}
                  isModalOpen={isModalOpen}
              >
                <div className={account ? 'styledHeaderTextC corsor-pointer' : 'styledHeaderTextC-hover styledHeaderTextC corsor-pointer'}>{interceptAdd(account)}</div>
              </Login>
            }
          </div>
          {
            !matches &&
            <div onClick={()=>setOpenMenu((pre)=>!pre)} className='m-menu corsor-pointer'>
              <img src='/images/menu.png' alt=''/>
            </div>
          }
        </div>
      </StyledHeader>
      <Main isMobile={matches}>
        <Router history={history}>
          <Web3ReactManager>
            <Switch>
                {/* <Redirect exact from="/" to="/home" /> */}
                {/* <Route exact path="/termsOfUse">
                </Route> */}
                <Route exact path="/">
                  <Home useOpenMessage={useOpenMessage}/>
                </Route>
                <Route exact path="/change">
                  <Change />
                </Route>
                <Route path="/staking">
                  <Staking />
                </Route>
                <Route path="/lottery">
                  <Lottery />
                </Route>
                <Route path="/nft">
                  <NFT />
                </Route>
                <Route path="/MarketPlace">
                  <MarketPlace />
                </Route>
                
                <Route exact strict path="/find" component={PoolFinder} />
                <Route path="/earn">
                  <Farms />
                </Route>
                {/* <Route path="/pool">
                  <Pool />
                </Route> */}
                <Route exact path="/add" component={AddLiquidity} />
                <Route exact strict path="/remove/:currencyIdA/:currencyIdB" component={RemoveLiquidity} />
                <Route exact strict path="/remove/:tokens" component={RedirectOldRemoveLiquidityPathStructure} />
                <Route exact path="/add/:currencyIdA" component={RedirectOldAddLiquidityPathStructure} />
                <Route exact path="/add/:currencyIdA/:currencyIdB" component={RedirectDuplicateTokenIds} />
                <Route exact path="/swap">
                  <Swap />
                </Route>
                <Route exact path="/liquidity">
                  <Liquidity />
                </Route>
            </Switch>
          </Web3ReactManager>
        </Router>
      </Main>
      <StyledFooter 
        isMobile={matches}>
        <main>
          <div className="main">
            {
              !matches &&
              <div className='logo-g'>
                <img src='https://dreamfinance.s3.ap-northeast-1.amazonaws.com/assets/images/home/head/logo.png' alt=''/>
                <div className='logo-text'>Dream Finance</div>
              </div>
            }
            <div className='link'>
              <div className='link-box'>
                <div className='title'>Community</div>
                {/* <div className='z-item'>
                  <div className='img'>
                    <img src="https://dreamfinance.s3.ap-northeast-1.amazonaws.com/assets/images/home/footer/Discord.png" alt="" />
                  </div>
                  <div>Discord</div>
                </div> */}
                <div className='z-item'>
                  <div className='img'>
                    <img src="https://dreamfinance.s3.ap-northeast-1.amazonaws.com/assets/images/home/footer/Twitter.png" alt="" />
                  </div>
                  <div>Twitter</div>
                </div>
                <div className='z-item'>
                  <div className='img'>
                    <img src="https://dreamfinance.s3.ap-northeast-1.amazonaws.com/assets/images/home/footer/Telegram.png" alt="" />
                  </div>
                  <div>Telegram</div>
                </div>
                {/* <div className='z-item'>
                  <div className='img'>
                    <img src="https://dreamfinance.s3.ap-northeast-1.amazonaws.com/assets/images/home/footer/Medrmu.png" alt="" />
                  </div>
                  <div>Medium</div>
                </div> */}
              </div>
              {/* <div className='link-box'>
                <div className='title'>Documents</div>
                <div className='z-item'>
                  <div>Github</div>
                </div>
                <div className='z-item'>
                  <div>GItbook</div>
                </div>
              </div> */}
              <div className='link-box'>
                <div className='title'>Helps</div>
                <div className='z-item'>
                  <div>FAQ</div>
                </div>
                <div className='z-item'>
                  <div>Blog</div>
                </div>
              </div>
              <div className='link-box'>
                <div className='title'>Term of Use</div>
              </div>
              <div className='link-box'>
                <div className='title'>Privacy Policy</div>
              </div>
            </div>
            <div className='year'>
              {
                matches &&
                <div className='logo'>
                  <img src='https://dreamfinance.s3.ap-northeast-1.amazonaws.com/assets/images/home/head/logo.png' alt=''/>
                  <div className='logo-text'>Dream Finance</div>
                </div>
              }
              <div>
                © 2023 Dream Finance
              </div>
            </div>
            {/* {
              update ?
              <div key={nanoid()} className='h5-footer'>
                <div onClick={()=>{history.push('/change')}} className='img'>
                  <div style={new RegExp('/change').test(hash)?{}:{opacity: 0.5}} className='flex flex-col justify-center items-center'>
                    <img src="https://dreamfinance.s3.ap-northeast-1.amazonaws.com/assets/images/home/footer/footer2-b.png" alt="" />
                    <div style={{fontSize:'16px', lineHeight: '28px'}}>Change</div>
                  </div>
                </div>
                <div className='img'>
                  <Tooltip trigger="click" placement="top" title={
                    <ShowMenu>
                      <div style={new RegExp('/nft/pegasus-nft').test(hash)?{color:'#fff'}:{color:'#757575'}} 
                      onClick={(e)=>{
                        e.stopPropagation()
                        history.push('/nft/pegasus-nft')
                      }}>Pegasus NFT</div>
                      <div style={new RegExp('/nft/mdf').test(hash)?{color:'#fff'}:{color:'#757575'}} 
                      onClick={(e)=>{
                        e.stopPropagation()
                        history.push('/nft/mdf')
                        }}>MDF</div>
                    </ShowMenu>}>
                    <div style={new RegExp('/nft').test(hash)?{}:{opacity: 0.5}} className='flex flex-col justify-center items-center'>
                      <img src="https://dreamfinance.s3.ap-northeast-1.amazonaws.com/assets/images/home/footer/footer3-b.png" alt="" />
                      <div style={{fontSize:'16px', lineHeight: '28px'}}>NFT</div>
                    </div>
                  </Tooltip>
                </div>
                <div className='img'>
                  <Tooltip  trigger="click" placement="top" title={
                    <ShowMenu>
                      <div style={new RegExp('/staking/pegasus').test(hash)?{color:'#fff'}:{color:'#757575'}} 
                        onClick={(e)=>{
                          e.stopPropagation()
                          history.push('/staking/pegasus')}}>Single Pegasus</div>
                      <div style={new RegExp('/staking/mdf').test(hash)?{color:'#fff'}:{color:'#757575'}} 
                        onClick={(e)=>{
                          e.stopPropagation()
                          history.push('/staking/mdf')}}>Single MDF</div>
                      <div style={new RegExp('/staking/mixed').test(hash)?{color:'#fff'}:{color:'#757575'}} 
                        onClick={(e)=>{
                          e.stopPropagation()
                          history.push('/staking/mixed')}}>Mixed</div>
                    </ShowMenu>}>
                    <div style={new RegExp('/staking').test(hash)?{}:{opacity: 0.5}} className='flex flex-col justify-center items-center'>
                      <img src="https://dreamfinance.s3.ap-northeast-1.amazonaws.com/assets/images/home/footer/footer4-b.png" alt="" />
                      <div style={{fontSize:'16px', lineHeight: '28px'}}>Staking</div>
                    </div>
                  </Tooltip>
                </div>
                <div className='img'>
                  <Tooltip  trigger="click" placement="top" title={
                    <ShowMenu>
                      <div style={new RegExp('/earn/farms').test(hash)?{color:'#fff'}:{color:'#757575'}} 
                        onClick={(e)=>{
                          e.stopPropagation()
                          history.push('/earn/farms')}}>Farms</div>
                      <div style={new RegExp('/earn/pool').test(hash)?{color:'#fff'}:{color:'#757575'}} 
                        onClick={(e)=>{
                          e.stopPropagation()
                          history.push('/earn/pool')}}>Pools</div>
                    </ShowMenu>}>
                    <div style={(new RegExp('/earn').test(hash)||new RegExp('/add').test(hash)||new RegExp('/remove').test(hash))?{}:{opacity: 0.5}} className='flex flex-col justify-center items-center'>
                      <img src="https://dreamfinance.s3.ap-northeast-1.amazonaws.com/assets/images/home/footer/footer5-b.png" alt="" />
                      <div style={{fontSize:'16px', lineHeight: '28px'}}>earn</div>
                    </div>
                  </Tooltip>
                </div>
                <div style={new RegExp('/lottery').test(hash)?{}:{opacity: 0.5}} onClick={()=>{history.push('/lottery')}} className='img'>
                    <div className='flex flex-col justify-center items-center'>
                    <img src="https://dreamfinance.s3.ap-northeast-1.amazonaws.com/assets/images/home/footer/footer6-b.png" alt="" />
                      <div style={{fontSize:'16px', lineHeight: '28px'}}>Lottery</div>
                    </div>
                </div>
              </div> :
              <div key={nanoid()} className='h5-footer'>
                <div onClick={()=>{history.push('/change')}} className='img'>
                  <div style={new RegExp('/change').test(hash)?{}:{opacity: 0.5}} className='flex flex-col justify-center items-center'>
                    <img src="https://dreamfinance.s3.ap-northeast-1.amazonaws.com/assets/images/home/footer/footer2-b.png" alt="" />
                    <div style={{fontSize:'16px', lineHeight: '28px'}}>Change</div>
                  </div>
                </div>
                <div className='img'>
                  <Tooltip trigger="click" placement="top" title={
                    <ShowMenu>
                      <div style={new RegExp('/nft/pegasus-nft').test(hash)?{color:'#fff'}:{color:'#757575'}} 
                      onClick={(e)=>{
                        e.stopPropagation()
                        history.push('/nft/pegasus-nft')
                      }}>Pegasus NFT</div>
                      <div style={new RegExp('/nft/mdf').test(hash)?{color:'#fff'}:{color:'#757575'}} 
                      onClick={(e)=>{
                        e.stopPropagation()
                        history.push('/nft/mdf')
                        }}>MDF</div>
                    </ShowMenu>}>
                    <div style={new RegExp('/nft').test(hash)?{}:{opacity: 0.5}} className='flex flex-col justify-center items-center'>
                      <img src="https://dreamfinance.s3.ap-northeast-1.amazonaws.com/assets/images/home/footer/footer3-b.png" alt="" />
                      <div style={{fontSize:'16px', lineHeight: '28px'}}>NFT</div>
                    </div>
                  </Tooltip>
                </div>
                <div className='img'>
                  <Tooltip  trigger="click" placement="top" title={
                    <ShowMenu>
                      <div style={new RegExp('/staking/pegasus').test(hash)?{color:'#fff'}:{color:'#757575'}} 
                        onClick={(e)=>{
                          e.stopPropagation()
                          history.push('/staking/pegasus')}}>Single Pegasus</div>
                      <div style={new RegExp('/staking/mdf').test(hash)?{color:'#fff'}:{color:'#757575'}} 
                        onClick={(e)=>{
                          e.stopPropagation()
                          history.push('/staking/mdf')}}>Single MDF</div>
                      <div style={new RegExp('/staking/mixed').test(hash)?{color:'#fff'}:{color:'#757575'}} 
                        onClick={(e)=>{
                          e.stopPropagation()
                          history.push('/staking/mixed')}}>Mixed</div>
                    </ShowMenu>}>
                    <div style={new RegExp('/staking').test(hash)?{}:{opacity: 0.5}} className='flex flex-col justify-center items-center'>
                      <img src="https://dreamfinance.s3.ap-northeast-1.amazonaws.com/assets/images/home/footer/footer4-b.png" alt="" />
                      <div style={{fontSize:'16px', lineHeight: '28px'}}>Staking</div>
                    </div>
                  </Tooltip>
                </div>
                <div className='img'>
                  <Tooltip  trigger="click" placement="top" title={
                    <ShowMenu>
                      <div style={new RegExp('/earn/farms').test(hash)?{color:'#fff'}:{color:'#757575'}} 
                        onClick={(e)=>{
                          e.stopPropagation()
                          history.push('/earn/farms')}}>Farms</div>
                      <div style={new RegExp('/earn/pool').test(hash)?{color:'#fff'}:{color:'#757575'}} 
                        onClick={(e)=>{
                          e.stopPropagation()
                          history.push('/earn/pool')}}>Pools</div>
                    </ShowMenu>}>
                    <div style={(new RegExp('/earn').test(hash)||new RegExp('/add').test(hash)||new RegExp('/remove').test(hash))?{}:{opacity: 0.5}} className='flex flex-col justify-center items-center'>
                      <img src="https://dreamfinance.s3.ap-northeast-1.amazonaws.com/assets/images/home/footer/footer5-b.png" alt="" />
                      <div style={{fontSize:'16px', lineHeight: '28px'}}>Earn</div>
                    </div>
                  </Tooltip>
                </div>
                <div style={new RegExp('/lottery').test(hash)?{}:{opacity: 0.5}} onClick={()=>{history.push('/lottery')}} className='img'>
                    <div className='flex flex-col justify-center items-center'>
                    <img src="https://dreamfinance.s3.ap-northeast-1.amazonaws.com/assets/images/home/footer/footer6-b.png" alt="" />
                      <div style={{fontSize:'16px', lineHeight: '28px'}}>Lottery</div>
                    </div>
                </div>
              </div>
            } */}
          </div>
        </main>
      </StyledFooter>
      
      <Drawer
        title=""
        placement="right"
        width="240px"
        onClose={onClose}
        open={openMenu}
        className="h-menu"
      >
        <div>
          <div style={{marginBottom:'10px'}} onClick={()=>{history.push('/change')}} className='img'>
            <div style={new RegExp('/change').test(hash)?{color:'#fff'}:{opacity: 0.5,color:'#fff'}} className='flex justify-start items-center'>
              <img style={{width:'16px',marginRight:'32px'}} src="https://dreamfinance.s3.ap-northeast-1.amazonaws.com/assets/images/home/footer/footer2-b.png" alt="" />
              <div style={{fontSize:'16px', lineHeight: '28px'}}>Change</div>
            </div>
          </div>
          
          <div ref={nftRef} className='submenu'>
            <div 
              style={new RegExp('/nft').test(hash)?{color:'#fff'}:{opacity: 0.5,color:'#fff'}} 
              onClick={()=>{
                showCMenu(nftRef)
              }} 
              className='flex justify-between items-center'>
              <div
                className='flex justify-start items-center'>
                <img style={{width:'16px',marginRight:'32px'}} src="https://dreamfinance.s3.ap-northeast-1.amazonaws.com/assets/images/home/footer/footer3-b.png" alt="" />
                <div style={{fontSize:'16px', lineHeight: '28px'}}>NFT</div>
              </div>
              <img style={{width: '15px',transform:'rotateZ(180deg)'}} src="/images/s.png" alt="" />
            </div>
            <div className='c' style={new RegExp('/nft/buy').test(hash)?{color:'#fff'}:{color:'#757575'}} 
              onClick={(e)=>{
                e.stopPropagation()
                history.push('/nft/buy')
              }}>Buy
            </div>
            <div className='c' style={new RegExp('/nft/pegasus-nft').test(hash)?{color:'#fff'}:{color:'#757575'}} 
              onClick={(e)=>{
                e.stopPropagation()
                history.push('/nft/pegasus-nft')
              }}>Pegasus NFT
            </div>
            <div className='c' style={new RegExp('/nft/mdf').test(hash)?{color:'#fff'}:{color:'#757575'}} 
              onClick={(e)=>{
                e.stopPropagation()
                history.push('/nft/mdf')
                }}>MDF
            </div>
            <div className='c' style={new RegExp('/nft/pokemon').test(hash)?{color:'#fff'}:{color:'#757575'}} 
              onClick={(e)=>{
                e.stopPropagation()
                history.push('/nft/pokemon')
                }}>Game Card
            </div>
          </div>          
          <div ref={stakingRef}  className='submenu'>
            <div 
              style={new RegExp('/staking').test(hash)?{color:'#fff'}:{opacity: 0.5,color:'#fff'}} 
              onClick={()=>{
                showCMenu(stakingRef)
              }} 
              className='flex justify-between items-center'>
              <div
                className='flex justify-start items-center'>
                <img style={{width:'16px',marginRight:'32px'}} src="https://dreamfinance.s3.ap-northeast-1.amazonaws.com/assets/images/home/footer/footer4-b.png" alt="" />
                <div style={{fontSize:'16px', lineHeight: '28px'}}>Staking</div>
              </div>
              <img style={{width: '15px',transform:'rotateZ(180deg)'}} src="/images/s.png" alt="" />
            </div>
            <div className='c' style={new RegExp('/staking/pegasus').test(hash)?{color:'#fff'}:{color:'#757575'}} 
              onClick={(e)=>{
                e.stopPropagation()
                history.push('/staking/pegasus')}}>Single Pegasus</div>
            <div className='c' style={new RegExp('/staking/mdf').test(hash)?{color:'#fff'}:{color:'#757575'}} 
              onClick={(e)=>{
                e.stopPropagation()
                history.push('/staking/mdf')}}>Single MDF</div>
            <div className='c' style={new RegExp('/staking/mixed').test(hash)?{color:'#fff'}:{color:'#757575'}} 
              onClick={(e)=>{
                e.stopPropagation()
                history.push('/staking/mixed')}}>Mixed</div>
          </div>      
          <div ref={earnRef} className='submenu'>
            <div
             style={(new RegExp('/earn').test(hash)||new RegExp('/add').test(hash)||new RegExp('/remove').test(hash))?{color:'#fff'}:{opacity: 0.5,color:'#fff'}}
              onClick={()=>{
                showCMenu(earnRef)
              }} 
              className='flex justify-between items-center'>
              <div
                className='flex justify-start items-center'>
                <img style={{width:'16px',marginRight:'32px'}} src="https://dreamfinance.s3.ap-northeast-1.amazonaws.com/assets/images/home/footer/footer5-b.png" alt="" />
                <div style={{fontSize:'16px', lineHeight: '28px'}}>Earn</div>
              </div>
              <img style={{width: '15px',transform:'rotateZ(180deg)'}} src="/images/s.png" alt="" />
            </div>
            <div className='c' style={new RegExp('/earn/farms').test(hash)?{color:'#fff'}:{color:'#757575'}} 
              onClick={(e)=>{
                e.stopPropagation()
                history.push('/earn/farms')}}>Farms</div>
            <div className='c' style={new RegExp('/earn/pool').test(hash)?{color:'#fff'}:{color:'#757575'}} 
              onClick={(e)=>{
                e.stopPropagation()
                history.push('/earn/pool')}}>Pools</div>
          </div>
          <div style={{marginBottom:'10px'}} onClick={()=>{history.push('/lottery')}} className='img'>
              <div  style={new RegExp('/lottery').test(hash)?{color:'#fff'}:{opacity: 0.5,color:'#fff'}} className='flex justify-start items-center'>
                <img style={{width:'16px',marginRight:'32px'}} src="https://dreamfinance.s3.ap-northeast-1.amazonaws.com/assets/images/home/footer/footer6-b.png" alt="" />
                <div style={{fontSize:'16px', lineHeight: '28px'}}>Lottery</div>
              </div>
          </div>
          <div style={{marginBottom:'10px'}} onClick={()=>{history.push('/MarketPlace')}} className='img'>
              <div  style={new RegExp('/MarketPlace').test(hash)?{color:'#fff'}:{opacity: 0.5,color:'#fff'}} className='flex justify-start items-center'>
                <img style={{width:'16px',marginRight:'32px'}} src="/images/MarketPlace.png" alt="" />
                <div style={{fontSize:'16px', lineHeight: '28px'}}>MarketPlace</div>
              </div>
          </div>
        </div>
      </Drawer>
      <Modal centered title="s" className={matches?'mint-message':'h5-mint-message'} open={openMessage?.isShow} footer={null} closeIcon={<><img src='https://dreamfinance.s3.ap-northeast-1.amazonaws.com/assets/images/home/popup/header btn.png' alt=''/></>} onCancel={()=>useOpenMessage((pre)=>({...pre,isShow:false, t: false}))}>
        <MintMessage isMobile={matches}>
          {
            openMessage?.t ? <>
            <div>
              <img src='https://dreamfinance.s3.ap-northeast-1.amazonaws.com/assets/images/home/popup/fluent_error-circle-12-filled.png' alt=''/>
            </div>
            <div className='text-context14 t-cmcp'>{openMessage?.message}</div>
            <div className='vmp text-context16' onClick={mesClick}>Close</div>
            </> : <>
            <div>
              <img src={openMessage?.isMf?'https://dreamfinance.s3.ap-northeast-1.amazonaws.com/assets/images/home/popup/icon-park-solid_success.png':'https://dreamfinance.s3.ap-northeast-1.amazonaws.com/assets/images/home/popup/fluent_error-circle-12-filled.png'} alt=''/>
            </div>
            <div className='cmcp h5-text-title' style={matches?{}:{fontSize: '30px'}}>{openMessage?.isMf?'Congratulations! Mint code published.':'Mint failed'}</div>
            {
              !openMessage?.isMf &&
              <div className={matches?'eds text-context16':'eds text-context14'}>{openMessage?.message || 'Error detail: Submit request into has arror'}</div>
            }
            <div className='vmp text-context16' onClick={mesClick}>{openMessage?.isMf?'View mint page':'Close'}</div>
            </>
          }
        </MintMessage>
      </Modal>
    </div>
  )
}

export default React.memo(App)