import React from 'react'
import { useTranslation } from "react-i18next";
import { useMatchBreakpoints } from '@dreamweb/uikit';
import { StyledCard, StyledCircle, StyledContent, StyledTitle, StyledDesc } from './PlayStepsStyle';

interface PropsType {
    cardsList: { title: string, desc: string }[]
}

const PlaySteps: React.FC<PropsType> = ({ cardsList }) => {
    const { t } = useTranslation();
    const { isSm, isXs, isLg, isMd,isXl } = useMatchBreakpoints();
    const isMobile = isXs || isSm || isLg || isMd;
    return (
        <>
            {
                cardsList && cardsList.map((item, index) => {
                    // eslint-disable-next-line react/no-array-index-key
                    return <StyledCard key={index} isMobile={isMobile}>
                        <StyledCircle isMobile={isMobile}>
                            <p>STEP</p>
                            <p>{index + 1}</p>
                        </StyledCircle>
                        <StyledContent>
                            <StyledTitle isMobile={isMobile}>{t(item.title)}</StyledTitle>
                            <StyledDesc isMobile={isMobile}>{t(item.desc)}</StyledDesc>
                        </StyledContent>
                    </StyledCard>
                })
            }
        </>
    )
}
export default PlaySteps;