import styled from 'styled-components';

const StyleBase = styled.div``

const StyledContainer = styled(StyleBase)<{isMobile?: boolean }>`
    background: rgb(194 194 194 / 15%);
    .info{
        text-align: center;
        padding: ${({isMobile}) => isMobile ?  '0.8rem' : '24px 62px 0' };
        color: white;
        font-size: ${({isMobile}) => isMobile ?  '0.7rem' : '14px' };
        line-height: 24px;
    }
` 

const StyleRecordContent = styled(StyleBase)<{isMobile?: boolean }>`
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: ${({isMobile}) => isMobile ?  '0.5rem' : '24px' };
    .cell {
        margin-bottom: ${({isMobile}) => isMobile ?  '1rem' : '24px' };
        padding-left: ${({isMobile}) => isMobile ?  '0.5rem' : '36px' };
        .title{
            margin-bottom: ${({isMobile}) => isMobile ?  '0.6rem' : '16px' };
            font-size: ${({isMobile}) => isMobile ?  '0.8rem' : '18px' };
            font-weight: bold;
            line-height: 20px;
            color: #FFF;
        }
        .time{
            font-weight: bold;
            color: #FFFFFF;
            font-size: ${({isMobile}) => isMobile ?  '1.4rem' : '22px' };
        }
        .sum{
            font-size: ${({isMobile}) => isMobile ?  '1rem' : '20px' };
            font-weight: bold;
            color: #DBDBDB;
            font-family: Nirmala UI-Bold,Nirmala UI;
        }
        .price{
            font-size: 14px;
            margin: 5px 0;
            color: white;
        }
        .dollar{
            margin: 12px 0;
            color: white;
            font-size: 14px;
        }
        .winners{
            font-size: 14px;
            color: white;
        }
        .burn{
            color: red;
        }
    }
`
// eslint-disable-next-line import/prefer-default-export
export { StyleRecordContent, StyledContainer }
