/* eslint-disable import/prefer-default-export */
import { useEffect, useState } from 'react'
import BigNumber from 'bignumber.js'
import { useWeb3React } from '@web3-react/core'
import { BIG_ZERO } from 'utils/bigNumber'
import { getLotteryAddress, getDcoinAddress, getIsDcoinKyuruoAddress } from 'utils/addressHelpers'
import { useERC20 } from './useContract'
import useRefresh from './useRefresh'


// Retrieve lottery allowance
export const useLotteryAllowance = (isDcoinKyuruo,update?:any) => {
  const [allowance, setAllowance] = useState(BIG_ZERO)
  const { account } = useWeb3React()
  const isDcoinKyuruoAddress = getIsDcoinKyuruoAddress(isDcoinKyuruo)
  const isDcoinKyuruoContract = useERC20(isDcoinKyuruoAddress)
  const { fastRefresh } = useRefresh()

  useEffect(() => {
    const fetchAllowance = async () => {
      const res = await isDcoinKyuruoContract.methods.allowance(account, getLotteryAddress()).call()
      setAllowance(new BigNumber(res))
    }
    if (account) {
      fetchAllowance()
    }
  }, [account, isDcoinKyuruoContract, fastRefresh, update])
  return allowance
}