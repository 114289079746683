/* eslint-disable guard-for-in */
const EventBus = function(this: { constructor: () => void; handlers: any, on: (key: any, handler: any) => boolean; commit: (key: string, ...args: any) => boolean; off: (key: any, handler: any) => boolean; }){
  this.handlers ={}
}

EventBus.prototype ={
  constructor: EventBus,
  on (key: string, handler: any) {
      if(!(key in this.handlers)) this.handlers[key] = [];
      if (!this.handlers[key].includes(handler)) {
          this.handlers[key].push(handler);
          return true;
      }
      return false;
  },
  commit (key: string , ...args: any) {
      if(!this.handlers[key]) return false;
      this.handlers[key].forEach((handler) => handler.apply(this, args));
      return true;
  },
  off(key: string, handler: any) {
      const index = this.handlers[key]?.findIndex((item: any) => item === handler);
      if (index === -1) {
          return false;
      }
      if(this.handlers[key]?.length ===1) {
          delete this.handlers[key];
      } else {
          this.handlers[key]?.splice(index, 1)
      }
      return true; 
  },
  offAll() {
      // eslint-disable-next-line no-restricted-syntax
      for(const key in this.handlers) {
          this.handlers[key] =[]
      } 
  }
}

export default new EventBus();