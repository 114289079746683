import styled from 'styled-components';

const StyleBase = styled.div``
const StyledTitle = styled.div`
    margin: 20px 0;
    font-size: 22px;
    font-weight: normal;
    line-height: 20px;
    color: #F8E513;
    text-align: center;
`
const StyledWinNumsStyle =  styled(StyleBase)<{isMobile?: boolean }>`
    display: flex;
    justify-content: center;
    padding-bottom: ${({isMobile}) => isMobile ?  '1rem' : '32px ' };
    div{
        .square{
            display: inline-block;
            width: ${({isMobile}) => isMobile ?  '2rem' : '50px ' };
            height: ${({isMobile}) => isMobile ?  '2rem' : '50px ' };
            margin-right:12px;
            opacity: 1;
            border-radius:  ${({isMobile}) => isMobile ?  '0.4rem' : '10px ' };
            font-size: 24px;
            font-weight: bold;
            color: #000000;
            line-height:  ${({isMobile}) => isMobile ?  '2rem' : '50px ' };
            text-align: center;
        }
        span:nth-of-type(1) {
            background: #A8FF89;
        }
        span:nth-of-type(2) {
            background: #E7FF89;
        }
        span:nth-of-type(3) {
            background: #FFC189;
        }
        span:nth-of-type(4) {
            background: #89FEFF;
        }
        span:nth-of-type(5) {
            background: #898CFF;
        }
        span:nth-of-type(6) {
            background: #FF89F3;
            margin-right: 0;
        }
    }
`
const StyleRecordContent = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 24px;
    background: #232323;
    .cell {
        margin-bottom: 24px;
        padding-left: 36px;
        .title{
            margin-bottom: 16px;
            font-weight: bold;
            line-height: 20px;
            color: #FFF;
        }
        .time{
            font-weight: bold;
            color: #FFFFFF;
            font-size: 22px;
        }
        .sum{
            font-weight: bold;
            color: #DBDBDB;
            font-size: 14px;
            font-family: Nirmala UI-Bold,Nirmala UI;
        }
        .price{
            margin: 5px 0;
            color: white;
        }
        .winners{
            color: white;
        }
        
    }
`

const StyledCardContainer = styled(StyleBase)<{isMobile?: boolean }>`
    display: flex;
    flex-direction: column;
    width: ${({isMobile}) => isMobile ?  '100%' : '580px' };
    margin: ${({isMobile}) => isMobile ?  '2rem 0 1.5rem' : '60px 0 50px ' };
    border-radius: ${({isMobile}) => isMobile ?  '1rem' : '25px' };
    background: #355595
`
 
const StylePrizeContainer = styled.div`
    background: #323232;
`
const StyledMore = styled(StyleBase)<{isMobile?: boolean }>`
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
    height: 54px;
    line-height: 54px;
    font-weight: bold;
    line-height: 21px;
    color: #FFF;
    text-align: center;
    font-size: ${({isMobile}) => isMobile ?  '1rem' : '18px' };
    cursor: pointer;
    img{
        width: 30px;
    }
`
const StyledHistoryList =styled(StyleBase)<{isMobile?: boolean }>`
    padding: ${({isMobile}) => isMobile ?  '1rem' : '18px' };
    .tickets-display-table{
        max-height: 220px;
        overflow-y: scroll;
        table {
            max-height: 300px;
            overflow-y: scroll;
            thead{
                tr{
                    height: 20px;
                    border-bottom: 1px solid white;
                    th {
                        display: inline-block;
                        width: 15%;
                        text-align: center;
                        font-size: ${({isMobile}) => isMobile ?  '0.6rem' : '14px' };
                    }
                    th:nth-of-type(2) {
                        width: 40%;
                    }
                    th:nth-of-type(3) {
                        width: 30%;
                    }
                }
            }
            tbody {
                tr {
                    td {
                        display: inline-block;
                        width: 15%;
                        text-align: center;
                        font-size: ${({isMobile}) => isMobile ?  '0.7rem' : '14px' };
                    }
                    td:nth-of-type(2) {
                        width: 40%;
                    }
                    td:nth-of-type(3) {
                        width: 30%;
                    }
                       
                }
            }
        }
    }
`

const StylePrizePot = styled(StyleBase)<{isMobile?: boolean }>`
    display: flex;
    flex-direction: ${({isMobile}) => isMobile ?  'column' : 'row' };
    justify-content: flex-start;
    padding:  ${({isMobile}) => isMobile ?  '0.8rem' : '26px 0 0 32px' };
    // border-bottom: 1px solid #355595;
    color: white;
    .left {
        width: ${({isMobile}) => isMobile ?  'calc(100% - 11rem)' : 'calc(100% - 300px)' };
        padding-left: ${({isMobile}) => isMobile ?  '' : '28px' };
        .title{
            margin-bottom: 12px;
            font-size: ${({isMobile}) => isMobile ?  '1rem' : '18px' };
            font-weight: bold;
        }
        .prize{
            margin-bottom: 12px;
            white-space: nowrap;
            font-size: ${({isMobile}) => isMobile ?  '1.2rem' : '28px' };
            font-weight: bold;
            color: rgb(250,206,156);
            font-famly: Nirmala UI-Bold,Nirmala UI;
        }
        .pt{
            font-size: ${({isMobile}) => isMobile ?  '0.8rem' : '14px' };
        }
    }
    .right {
        width: ${({isMobile}) => isMobile ?  '100%' : '270px' };
        padding-left: ${({isMobile}) => isMobile ?  '0' : '46px' };
        margin-top: ${({isMobile}) => isMobile ?  '1.4rem' : '0' };
        .info{
            margin-bottom: ${({isMobile}) => isMobile ?  '1rem' : '42px' };
            font-size: 14px;
            text-align: left;
        }
    }
`

const StyleNodata = styled.div`
    height: 120px;
    line-height: 120px;
    text-align: center;
    color: white;
    font-size: 16px;
`

const StyledTickets = styled(StyleBase)<{isMobile?: boolean }>`
display: flex;
justify-content: center;
.your-tickets{
    display: flex;
    align-items: center;
    padding-bottom: 30px;
    color: white;
    .title{
        margin-right: ${({isMobile}) => isMobile ?  '1rem' : '60px' };
        font-size: ${({isMobile}) => isMobile ?  '1rem' : '18px' };
        font-weight: bold;
    }
    .desc{
        font-size: ${({isMobile}) => isMobile ?  '0.8rem' : '16px' };
        .view-tickets{
            margin-top: 10px;
            cursor: pointer;
            font-size: ${({isMobile}) => isMobile ?  '0.8rem' : '16px' };
            color: #FFF;
        }
    }
    
}
`
export { StylePrizeContainer, StyledMore, StyledTickets, StylePrizePot, StyledTitle, StyledHistoryList, StyledWinNumsStyle,StyleRecordContent, StyledCardContainer, StyleNodata }