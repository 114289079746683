/* eslint-disable no-restricted-globals */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import { Modal, useMatchBreakpoints } from '@dreamweb/uikit'
import { useLottery } from 'hooks/useContract'
import { useWeb3React } from '@web3-react/core'
import { checkLotteryDetailById } from 'utils/lotteryUtils'
import { useTranslation } from "react-i18next";
import { checkLotteryStatus } from '../../utils'
import { StyledYourTickets, StyledTicketsNumRow, StyledTicketsBox} from './LotteryDetailMoalStyle'

const LotteryDetailNextDraw = ({ onDismiss, round, winningNumbers, totalTickets, id}) => {
    const { t } = useTranslation()
    const lotteryContract = useLottery();
    const { account } = useWeb3React();
    const [boughtLotterys, setBoughtLotterys] = useState([]);
    const [winNumber, setWinNumber] = useState(0);
    const { isSm, isXs, isLg, isMd,isXl } = useMatchBreakpoints();
    const isMobile = isXs || isSm || isLg || isMd;

    const formatIndex = (num: number): string => {
        if (num < 10) {
            return `#00${num}`;
        }
        if (num < 100 && num > 9) {
            return `#0${num}`;
        }
        return `#${num}`;
    }
 
    useEffect(()=>{
        const getLotteryInfo = async() => {
            const res = await checkLotteryDetailById(lotteryContract, account, round );
            const { lotteryIds, brackets } = checkLotteryStatus(res, winningNumbers);
            if (lotteryIds) {
                setWinNumber(lotteryIds?.length);
            }
            if (res) {
                setBoughtLotterys(res[1]);
            }
        }   
        getLotteryInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])
    return (
        <Modal title={`Round ${round}`} onDismiss={onDismiss} style={{ width: isMobile ? '100%' : "450px", background: '#232323' }}>
            <StyledYourTickets isMobile={isMobile}>
                <section className='total_winning_tickets-next-draw'>
                    <div>
                        <p className='sub-title'>{t("Your Tickets")}</p>
                    </div>
                </section>
            </StyledYourTickets>
            <StyledTicketsBox>
                {
                    boughtLotterys.map((item, index) => {
                        return (
                            // eslint-disable-next-line react/no-array-index-key
                            <StyledTicketsNumRow isMobile={isMobile} key={`${index}`}>
                                <div className='index'>{formatIndex(index + 1)}</div>
                                <div className='num-arr'>
                                    {
                                        item.substring(1).split('').map((number) => {
                                          return <span>{number}</span>
                                        })
                                    }
                                </div>
                            </StyledTicketsNumRow>
                        )
                    })
                }
            </StyledTicketsBox>
        </Modal>
    )
}

export default React.memo(LotteryDetailNextDraw);
