import styled from 'styled-components';

const StyleBase = styled.div``

const StyledTicketsNumRow =  styled(StyleBase)<{isMobile?: boolean }>`
    .index {
      margin-bottom: 6px;
      font-size: 16px;
      line-height: 20px;
      color: white;
    }
    .alert{
      color: red;
      font-size: ${({isMobile}) => isMobile ?  '0.8rem' : '16px' };
    }
    .num-arr {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      height: ${({isMobile}) => isMobile ?  '2rem' : '48px' };
      margin-bottom: 20px;
      border: 1px solid white;
      border-radius: 15px;
      input{
          width: 16%;
          outline: none;
          border: none;
          background: transparent;
          text-align: center;
          color: white;
          font-size: 16px;
          font-weight: 900;
      }
    }
`
const StyledAlertWrongInput = styled.div`
    margin: 20px 0 14px;
    color: red;
    font-size: 14px;
`
const StyledTicketsBox = styled(StyleBase)<{isMobile?: boolean }>`
    max-height: ${({isMobile}) => isMobile ?  '10rem' : '280px' };
    padding-right: 12px;
    overflow-y : auto; 
`
const StyledGoBack = styled(StyleBase)<{isMobile?: boolean, isLoading?: boolean }>`
    margin-top: 20px;
    font-size:  ${({isMobile}) => isMobile ?  '1rem' : '24px' };;
    font-weight: bold;
    text-align: center;
    cursor: ${({isLoading}) => isLoading ?  'not-allowed' : 'pointer' };
    color: ${({isLoading}) => isLoading ?  '#888' : 'white' };
    
`
const StyledCostDetail =  styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 10px;
    font-size: 16px;
    font-weight: 400;
    color: white;
`
const StyleInfoText = styled.div`
    margin-top: 20px;
    margin-bottom: 12px;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    color: white;
`
const StyledTicketsNum = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin:26px 0 16px;
    .num-btn{
        width: 110px;
        height: 26px;
        background: #479DFF;
        opacity: 1;
        border-radius: 15px;
        color: white;
        font-size: 16px;
        text-align: center;
        font-weight: 900;
        line-height: 26px;
    }
 
`
const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding-top: 125px;
  font-size: 22px;
  font-weight: 300;
`

const StyleDivdeLine = styled.div`
    margin: 16px 0;
    height: 1px;
    border: 1px solid #CBCBCB;
    border-radius: 15px;
`

const StyledTitle = styled.div`
  font-size: 18px;
  color: white;
  margin-bottom: 40px;
  `
const StyledSum =  styled.div`
  margin-bottom: 20px;
  font-size: 60px;
  font-family: AXIS;
  font-weight: normal;
  color: #FFF;
`
const StyledDesc =  styled.div`
  margin-bottom: 50px;
  font-size: 20px;
  font-family: AXIS;
  font-weight: normal;
  line-height: 20px;
  color: #FFFFFF;
`

 

export {StyledTicketsNumRow,StyledTicketsBox, StyledGoBack, StyledAlertWrongInput, StyledTicketsNum, StyledCostDetail, StyleInfoText, StyleDivdeLine, StyledContainer, StyledTitle, StyledSum, StyledDesc}