/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import { Button, Modal, Flex, Input, useMatchBreakpoints } from '@dreamweb/uikit'
import { cloneDeep, debounce } from 'lodash';
import { useTranslation } from "react-i18next";
import { StyledTicketsNumInput, StyledCostDetail, StyleInfoText, StyleDivdeLine } from './BuyTicketsModalStyle'
import { StyledTicketsNumRow, StyledTicketsBox, StyledGoBack, StyledAlertWrongInput } from './SelectNumbersStyle'

type propsType = {
    ticketNumberArr: Array<Array<number | string>>,
    ticketsNum: number | string,
    setRandomTicketsNumarr: () => void,
    confirmToBuy: (arr: Array<Array<string | number>>) => void,
    goBack: any,
    totalCost: number | string
}
const SelectNumbers = (props: propsType) => {
    const { ticketNumberArr, totalCost } = props
    const [numberArr, setNumberArr] = useState(ticketNumberArr)
    const { setRandomTicketsNumarr, ticketsNum, goBack, confirmToBuy } = props;
    const { t } = useTranslation();
    const [numberOutRange, showAlertOfWrongInput] = useState(false);
    const { isSm, isXs, isLg, isMd,isXl } = useMatchBreakpoints();
    const isMobile = isXs || isSm || isLg || isMd;
    const [isLoading, setIsLoading] = useState(false);
    const [alert, setAlert] = useState([])
    const [temp, setTemp] = useState('')
    const validateInput = (number: string) => {
        return /^[0-9]$/.test(number)
    }

    const formatIndex = (num: number): string => {
        if (num < 10) {
            return `#00${num}`;
        }
        if (num < 100 && num > 9) {
            return `#0${num}`;
        }
        return `#${num}`;
    }

    const debouncedCheckInput = (e: any, index: number, index2: number) => {
        const { value } = e.target;
        if (Number(value) > -1 && value?.trim() !== '') {
            showAlertOfWrongInput(false);
            ticketNumberArr[index][index2] = e?.nativeEvent?.data;
            if(+index2 < 5) {
                setTimeout(() => {
                    nextIptFocus(document?.getElementById(`${index}${index2}`)?.nextElementSibling)
                }, 50);
            } else {
                setTimeout(() => {
                    nextIptFocus(document?.getElementById(`${index}${index2}`))
                }, 50);
            }
        }else if (!validateInput(value)) {
            showAlertOfWrongInput(true);
            ticketNumberArr[index][index2] = '';
            if(+index2 !== 0) {
                setTimeout(() => {
                nextIptFocus(document?.getElementById(`${index}${index2}`)?.previousElementSibling)
            }, 50);
            } else {
                setTimeout(() => {
                    nextIptFocus(document?.getElementById(`${index}${index2}`))
                }, 50);
            }
        } else {
            showAlertOfWrongInput(false);
            ticketNumberArr[index][index2] = value;
            // 输入数字切换焦点
            if(+index2  < 5) {
                setTimeout(() => {
                    nextIptFocus(document?.getElementById(`${index}${index2}`)?.nextElementSibling)
                }, 50);
            } else {
                setTimeout(() => {
                    nextIptFocus(document?.getElementById(`${index}${index2}`))
                }, 50);
            }
        }
        for (let i1 = 0; i1 < ticketNumberArr?.length; i1++) {
            const element1 = ticketNumberArr[i1];
            for (let i2 = 0; i2 < element1?.length; i2++) {
                const element2 = element1[i2];
                if(element2 === '') {
                    showAlertOfWrongInput(true)
                }
            }
        }
        const copyArr = cloneDeep(ticketNumberArr);
        setNumberArr(copyArr);
        checkHasDuplicateArr(ticketNumberArr);
    };

    const checkInput = async (e: any, index: number, index2: number) => {
        await debouncedCheckInput(e, index, index2);
    }

    const focusInput = (e: any, index: number, index2: number) => {
        // const { value } = e.target;
        // setTemp(value)
        // e?.preventDefault()
        // e?.stopPropagation()
        e?.target?.select()
        // return false
    }

    const nextIptFocus = (e) => { 
        // e?.preventDefault()
        e?.focus()
        e?.select()
    }
    
    const compareTwoArr = (arr1, arr2) => {
        const array1 = arr1.map(item => Number(item));
        const array2 = arr2.map(item => Number(item));
        let flag = true;
        for (let i = 0; i < array1?.length; i++) {
            if (array1[i] !== array2[i]) {
                flag = false;
                break;
            }
        }
        return flag;
    }

    const checkHasDuplicateArr = (arr) => {
        const flagArr = new Array(ticketNumberArr?.length).fill(false)
        const len = arr?.length;
        for(let i = 0; i < len; i++) {
            for(let j = i + 1; j < len; j++ ) {
                if (compareTwoArr(arr[i], arr[j])) {
                    flagArr[i] = true;
                    flagArr[j] = true;
                }
            }
        }
        setAlert(flagArr)
    }

    const checkBeforeConfirmToBuy = async () => {
        setIsLoading(true)
        try {
            if (!numberOutRange) {
                await confirmToBuy(ticketNumberArr);
                setIsLoading(false)
            }
        } catch (e) {
            setIsLoading(false)
        }
    }
   
    useEffect(() => {
        setNumberArr(ticketNumberArr);
        
    }, [ticketNumberArr])

    useEffect(() => {
        checkHasDuplicateArr(ticketNumberArr);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ticketNumberArr])

    return (
        <>
            <StyledTicketsNumInput>
                <div className='title'>
                    <span>{t("Total cost")}:</span>
                    <span>{totalCost}&nbsp;{(localStorage.getItem('isDcoinKyuruo')||'0')==='0'?'DCOIN':'KYURYO'}</span>
                </div>
            </StyledTicketsNumInput>
            <StyleInfoText>
                {t("Numbers are randomized, with no duplicates among your tickets. Tap a number to edit it. Available digits:0-9")}
            </StyleInfoText>
            <Button style={{color: '#000'}} disabled={isLoading}  onClick={() => setRandomTicketsNumarr()}>{t("Randomize")}</Button>
            <StyledAlertWrongInput>
                {
                    numberOutRange && <p>{t("Only integers from 0 to 9 is allowed!")}</p>
                }
            </StyledAlertWrongInput>
            <StyledTicketsBox isMobile={isMobile}>
                {
                    numberArr.map((item, index) => {
                        return (
                            <StyledTicketsNumRow key={index} isMobile={isMobile}>
                                <Flex style={{justifyContent:'space-between'}}>
                                    <div className='index'>{formatIndex(index + 1)}</div>
                                    {
                                        alert[index] && <span className='alert'>{t('Duplicate')}</span>
                                    }
                                </Flex>
                                {/* onFocus={($event) => inputFocus($event, index, index2)} */}
                                <div className='num-arr'>
                                    {
                                        item.map((number, index2) => {
                                            return (
                                                <input className='input'
                                                    type='tel'
                                                    key={`${index}${index2}`}
                                                    id={`${index}${index2}`}
                                                    value={number}
                                                    onFocus={($event) => {
                                                        focusInput($event, index, index2)
                                                    }}
                                                    onChange={($event) => {
                                                        checkInput($event, index, index2)
                                                    }}
                                                    // onClick={($event) => {
                                                    //     focusInput($event, index, index2)
                                                    // }}
                                                    // onKeyUp={($event) => {
                                                    //     checkInput($event, index, index2)
                                                    // }}
                                                />
                                            )
                                        })
                                    }
                                    {/* <input type="text" defaultValue={item.join('')}/> */}
                                </div>
                            </StyledTicketsNumRow>
                        )
                    })
                }
            </StyledTicketsBox>
                <Button onClick={checkBeforeConfirmToBuy} isLoading={isLoading} disabled={isLoading || numberOutRange}>
                    {
                        isLoading && <img src="https://dreamfinance.s3.ap-northeast-1.amazonaws.com/assets/images/button_loading.gif" alt=''  />
                    }
                    <span style={{marginLeft: '12px', color: '#000'}}>{t("Confirm and buy")}</span>
                </Button>
            <StyledGoBack isMobile={isMobile}  isLoading={isLoading} onClick={() => goBack()}> ← {t("Go back")}</StyledGoBack>
        </>
    )
}

export default React.memo(SelectNumbers);



