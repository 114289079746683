/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable import/order */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable import/named */
import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from "react-i18next";
import moment from 'moment';
import { groupBy } from 'lodash';
import BigNumber from 'bignumber.js'
import { DEFAULT_TOKEN_DECIMAL } from 'config'
import EventBus from 'utils/eventBus'
import { useMatchBreakpoints, useModal } from '@dreamweb/uikit';
import { useWeb3React } from '@web3-react/core'
import { getViewLottery, checkHistoryLotteryInfoByLotteryId, getCurrentLotteryId, checkTotalPlayers, checkMyLotteryHistory} from 'utils/lotteryUtils'
import { useLottery } from 'hooks/useContract'
import { formatDecimal } from 'utils/math';
import { handleRewardsData } from '../../../utils/index'
import { StyledCardContainer, StyledCardHeader } from '../HistoryPanelStyle';
import { StyledTitle, StyledWinNumsStyle, StylePrizeContainer, StyledTickets, StylePrizePot, StyledMore, StyleNodata } from './AllHistoryStyle'
import LotteryDetail from '../../LottertDetail/LotteryDetail';
import LotteryDetailNextDraw  from '../../LotteryDetailModal/LotteryDetailNextDraw'
import LotteryDetailMoal from '../../LotteryDetailModal/LotteryDetailMoal';
import { usePriceDreamBusd } from 'state/hooks'
import { Modal } from 'antd'

const AllHistory  = (props: any) => {
    const { t } = useTranslation()
    const [ flopFlag, setFlipFlag] = useState(false);
    const lotteryContract = useLottery();
    const currentLotteryId = useRef<number>(0)
    const timer = useRef(null)
    const tempCurrentId = useRef<number>(0);
    const [winningNumbers, setWinningNumbers] = useState([]);
    const [ startTimeOfLottery, setStartTimeOfLottery] = useState(0);
    const [stopPrev, setStopPrev] = useState(false);
    const [stopNext, setStopNext] = useState(false);
    const [rewards, setRewards] = useState([]);
    const [noData, setNodata] = useState(true);
    const [isDcoinKyuruoPrice, setIsDcoinKyuruoPrice] = useState(0)
    const { account } = useWeb3React();
    const currentAccount = useRef('');
    const amountDcoin = useRef(0)
    const amountKyuryo = useRef(0)
    const burnDcoin = useRef(0);
    const burnKyuryo = useRef(0);
    const [totalTicktets, setTotalTickets] = useState(0);
    const { isSm, isXs, isLg, isMd,isXl } = useMatchBreakpoints();
    const isMobile = isXs || isSm || isLg || isMd;
    const [totalPlayers, setTotalplayers] = useState(0)
    const [dataLoading, setDataLoading] = useState(true)
    const [openView,setOpenView]=useState(false)

    const DreamPriceUsd = usePriceDreamBusd();

    const toTargetedLotteryDetail = async (index: number) => {
        setDataLoading(true)
        const info = await checkHistoryLotteryInfoByLotteryId(index, lotteryContract);
        setDataLoading(false)
        if (account) {
            getCurrentLotteryTickets()
        }
        if (info) {
            setEachValue(info)
        }
    }

    const toMostPrev = () => {
        currentLotteryId.current= 1;
        setStopNext(false);
        setStopPrev(true);
        toTargetedLotteryDetail(1);
    };

    const toPrev = () => {
        setStopNext(false);
        currentLotteryId.current -= 1;
        if(currentLotteryId.current <= 1) {
            currentLotteryId.current = 1
            setStopPrev(true);
        }
        toTargetedLotteryDetail(currentLotteryId.current); 
    };

    const toNext = () => {
        if (stopNext) return;
        setStopPrev(false);
        currentLotteryId.current = Number(currentLotteryId.current) + 1;
        if (currentLotteryId.current >= tempCurrentId.current) {
            currentLotteryId.current = tempCurrentId.current;
            setStopNext(true);
        } 
        toTargetedLotteryDetail(currentLotteryId.current);
    };

    const toMostNext = () => {
        currentLotteryId.current = tempCurrentId.current;
        setStopNext(true);
        setStopPrev(false);
        toTargetedLotteryDetail(tempCurrentId.current);
    };

    const getDcoinKyuryoPrice = async () => { 
        const info = await getViewLottery(lotteryContract);
        const { priceTicketInDcoin, priceTicketInKyuryo } = info;
        setIsDcoinKyuruoPrice(Number(localStorage.getItem('isDcoinKyuruo')==='0'?priceTicketInDcoin:priceTicketInKyuryo));
     }


    const handleKeyDown = ()=>{
        // 
    }

    const setEachValue = async (info) => {
        const { rewardsBreakdown, countWinnersPerBracket, finalNumber, endTime, treasuryFee, amountCollectedInDcoin, amountCollectedInKyuryo} = info;
        const players = await checkTotalPlayers(lotteryContract, currentLotteryId.current);
        setTotalplayers(players)
        amountDcoin.current = Number(amountCollectedInDcoin) / 1e18;
        amountKyuryo.current = Number(amountCollectedInKyuryo) / 1e18;
        burnDcoin.current = new BigNumber(amountCollectedInDcoin).times(new BigNumber(treasuryFee)).div(new BigNumber(10000)).div(DEFAULT_TOKEN_DECIMAL).toNumber();
        burnKyuryo.current = new BigNumber(amountCollectedInKyuryo).times(new BigNumber(treasuryFee)).div(new BigNumber(10000)).div(DEFAULT_TOKEN_DECIMAL).toNumber();
        
        setStartTimeOfLottery(Number(endTime) * 1000);
        setWinningNumbers(finalNumber.substring(1).split(''));
        const res = handleRewardsData(rewardsBreakdown, countWinnersPerBracket, amountDcoin.current- burnDcoin.current, amountKyuryo.current- burnKyuryo.current);
        if (res) {
            setRewards(res);
        }
    }

    const createValidateRes = (res) => {
        const times = res[1]
        const arr=  times.map((item, index) => {
            return {
                lotteryId: res[0][index],
                round: index+1,
                date:  moment(Number(res[1][index]) * 1000).format('LL'),
                tickets:  res[2][index],
                detail: ''
            }
        });
        return arr;
    }

    const getCurrentLotteryTickets = async () => {
        let result = await checkMyLotteryHistory(lotteryContract, account);
        result = createValidateRes(result);
        const groups = groupBy(result, 'lotteryId');
        const keys = Object.keys(groups);
        const arr = []
        for(let i=0; i< keys?.length; i++ ) {
            let total = 0;
            groups[keys[i]].forEach((item) => {
                total += Number(item.tickets)
            })
            arr.push({
                lotteryId: keys[i],
                tickets: total
            })
        }
        const targetItem = arr.find(item => Number(item.lotteryId) === Number(currentLotteryId.current));
        if (targetItem) {
            setTotalTickets(targetItem.tickets)
        } else {
            setTotalTickets(0)
        }
    }

    useEffect(() => { 
        getDcoinKyuryoPrice()
    }, [props.update])
    const [ openModal ] = useModal(<LotteryDetailMoal 
        round={currentLotteryId.current} 
        winningNumbers={winningNumbers} 
        totalTickets={totalTicktets} 
        onDismiss />)
    
    const getLotteryInfo = async () => {
        try {
            setDataLoading(true)
            const Id = await getCurrentLotteryId(lotteryContract);
            
            // eslint-disable-next-line no-multi-assign
            let info = await checkHistoryLotteryInfoByLotteryId(Number(Id), lotteryContract);
            if (info.status === '3') {
                // eslint-disable-next-line no-multi-assign
                currentLotteryId.current = tempCurrentId.current = Id
            } else {
                // eslint-disable-next-line no-multi-assign
                currentLotteryId.current = tempCurrentId.current = Id -1 ;
                info = await checkHistoryLotteryInfoByLotteryId(Number(Id - 1), lotteryContract);
            }
            if (lotteryContract && account) {
                getCurrentLotteryTickets();
            }
            const players = await checkTotalPlayers(lotteryContract, currentLotteryId.current);
            setTotalplayers(players)
            if(Number(currentLotteryId.current) === 1 || Number(currentLotteryId.current) === 0) {
                setStopPrev(true);
                setStopNext(true);
            } else {
                setStopNext(true);
            } 
            
            setDataLoading(false)
            setEachValue(info);
        } catch (error) {
            // 
        }
    }
    useEffect(() => {
        if (lotteryContract) {
            getLotteryInfo()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lotteryContract, props.chainid, props.update])

    useEffect(() => {
        if ( (!account || (account !== currentAccount.current))) {
            if (account) {
                getLotteryInfo();
            }
            currentAccount.current = account;
        }
        // clearInterval(timer.current)
        // timer.current = setInterval(() => { 
        //     getLotteryInfo();
        // }, 6000)
        return () => { 
            // clearInterval(timer.current)
         }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [account, props.chainid, props.update])

    const searchCurrentLotteryId = (e) => {
        setStopNext(false);
        setStopPrev(false);
        currentLotteryId.current = +e?.target?.value;
        if(+currentLotteryId.current < 1) {
            currentLotteryId.current = null
            setStopPrev(true);
            setStopNext(true);
            return
        }
        if(+currentLotteryId.current === 1) {
            setStopPrev(true);
        }
        if (currentLotteryId.current >= tempCurrentId.current) {
            currentLotteryId.current = tempCurrentId.current;
            setStopPrev(false);
            setStopNext(true);
        } 
        toTargetedLotteryDetail(currentLotteryId.current); 
    };


    return (
        <StyledCardContainer isMobile={isMobile}>
            <StyledCardHeader isMobile={isMobile}>
                <section className='rounds-header'>
                    <section className='rounds'>
                        <div className='title'>Round
                        {tempCurrentId.current <= 0 ? '' : <input type="number" value={currentLotteryId.current ? currentLotteryId.current : ''} onChange={(e)=>{searchCurrentLotteryId(e)}}/>}
                            
                        </div>
                    </section>
                    {
                        +startTimeOfLottery === 0 || +tempCurrentId.current === 0 ? "" : <section className='narrow'>
                        <img onClick={() => {toMostPrev()}} onKeyDown={handleKeyDown} style={{filter: stopPrev ? 'brightness(0.4)':'brightness(1)',  pointerEvents: stopPrev  ? 'none':'all' }}  alt='next' src="https://dreamfinance.s3.ap-northeast-1.amazonaws.com/assets/images/lottery/most_next.png" />
                        <img style={{filter: stopPrev? 'brightness(0.4)':'brightness(1)',  pointerEvents: stopPrev? 'none':'all' }} onClick={() => toPrev()}   alt='next' onKeyDown={handleKeyDown}  src="https://dreamfinance.s3.ap-northeast-1.amazonaws.com/assets/images/lottery/next.png" />
                        <img style={{filter: stopNext? 'brightness(0.4)':'brightness(1)',  pointerEvents: stopNext? 'none':'all' }} onClick={() => toNext()} alt='next' onKeyDown={handleKeyDown}  src="https://dreamfinance.s3.ap-northeast-1.amazonaws.com/assets/images/lottery/next.png" />
                        <img onClick={() => {toMostNext()}} onKeyDown={handleKeyDown} style={{filter: stopNext ? 'brightness(0.4)':'brightness(1)',  pointerEvents: stopNext ? 'none':'all' }}  alt='next' src="https://dreamfinance.s3.ap-northeast-1.amazonaws.com/assets/images/lottery/most_next.png" />
                    </section>
                    }
                </section>
                {
                    // startTimeOfLottery && <div className='date'>{t("Drawn")}:{moment(startTimeOfLottery).format('MMM D, YYYY, h:mm A')}</div>
                }
                {
                    +currentLotteryId?.current !== 0 && startTimeOfLottery ? <div className='date'>{t("Drawn")} : {moment(startTimeOfLottery).format('MMM D, YYYY, h:mm A')}</div> : ""
                }
            </StyledCardHeader>
            <section>
                {
                    +startTimeOfLottery === 0 || +currentLotteryId.current === 0 ? <StyleNodata>{dataLoading?'loading':'No Data'}</StyleNodata> :
                        <div>
                            <StyledTitle>{t("Winning Number")}</StyledTitle>
                            <StyledWinNumsStyle isMobile={isMobile}>
                                <div>
                                    {
                                        winningNumbers.map(item=>{
                                            return <span key={Math.random()} className='square'>{item}</span>
                                        })
                                    }
                                </div>
                            </StyledWinNumsStyle>
                            {
                                Boolean(totalTicktets) && <StyledTickets isMobile={isMobile}>
                                <section className='your-tickets'>
                                    <div className='title'>{t("Your tickets")}</div>
                                    <div className='desc'>   
                                        <p><span>{t(`You have ${totalTicktets}  ${(totalTicktets === 1 || totalTicktets === 0) ? 'ticket' : 'tickets'} this round`)}</span></p>
                                        { totalTicktets && <p  style={{color: '#3232F8'}} className='view-tickets' onKeyDown={handleKeyDown} onClick={ ()=>{setOpenView(true)} }>{t("View your tickets")}</p> }
                                    </div>
                                </section>
                                </StyledTickets>
                            }
                            <StylePrizeContainer>
                                <StylePrizePot isMobile={isMobile}>
                                    <section className='left'>
                                        <div className='title'>{t("Prize pot")}</div>
                                        <div className='prize'>{formatDecimal(amountDcoin.current, 4)} DCOIN</div>
                                        <div className='prize'>{formatDecimal(amountKyuryo.current, 4)} KYURYO</div>
                                    </section>
                                    <section className='right'>
                                        <div className='info' style={{textAlign: "left"}}>{t("Match the winning number in the same order to share prizes.")}</div>
                                        <div className='info'>{t("Total players this round: ")} {totalPlayers}</div>
                                    </section>
                                </StylePrizePot>  
                            </StylePrizeContainer>
                            { flopFlag &&  <LotteryDetail 
                            rewards ={rewards} 
                            source="allhistory" 
                            burnDcoin={burnDcoin.current} 
                            burnKyuryo={burnKyuryo.current} 
                            /> }
                            <StyledMore isMobile={isMobile} onClick={() => { setFlipFlag( flag => !flag) }}>
                                <img style={{ transform: flopFlag? 'rotate(180deg) scale(0.5)' : 'rotate(0deg) scale(0.5)'}} alt="arrow" src='https://dreamfinance.s3.ap-northeast-1.amazonaws.com/assets/images/lottery/down_arrow.png' />  {flopFlag ? "Hide" : "Details"}
                            </StyledMore>
                        </div>
                }
            </section>
                        
            <Modal centered className='buy-tickets-modal' title={null} footer={null} open={openView} closable={false}>
                <LotteryDetailMoal 
                    round={currentLotteryId.current} 
                    winningNumbers={winningNumbers} 
                    totalTickets={totalTicktets} 
                    onDismiss={()=>setOpenView(false)} />
            </Modal>
        </StyledCardContainer>
    )
}

export default React.memo(AllHistory);